import instance from '.'
import { getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import { Client, User } from './types'

export async function getClients(): Promise<APIResult<Client[]>> {
  const clients = await instance
    .post('/api/admin/getClients')
    .then((res) => getResult<Client[]>(res))
  return clients
}

export async function getClient({
  name,
  id,
}: {
  name?: string
  id?: number
}): Promise<APIResult<Client>> {
  const client = await instance
    .post(
      '/api/admin/getClient',
      { clientName: name, clientId: id },
      { withCredentials: true },
    )
    .then((res) => getResult<Client>(res))
  return client
}

export async function addUserRole(
  clientId: number,
  userEmail: string,
  userRole: number,
): Promise<APIResult<string>> {
  const result = await instance
    .post('/api/admin/addUserRole', {
      clientId,
      userEmail,
      userRole,
    })
    .then((res) => getResult<string>(res))
  return result
}

export async function updateClient(
  clientId: number,
  displayName: string,
  description: string,
): Promise<APIResult<boolean>> {
  const result = await instance
    .post('/api/admin/updateClient', {
      clientId,
      displayName,
      description,
    })
    .then((res) => getResult<boolean>(res))
  return result
}

export async function createService(
  name: string,
  displayName: string,
  description: string,
): Promise<APIResult<boolean>> {
  const result = await instance
    .post('/api/admin/createService', {
      name,
      displayName,
      description,
    })
    .then((res) => getResult<boolean>(res))
  return result
}

export async function addService(
  clientId: number,
): Promise<APIResult<boolean>> {
  const result = await instance
    .post('/api/admin/addService', {
      clientId,
    })
    .then((res) => getResult<boolean>(res))
  return result
}

export type ClientUser = User & {
  roleId: number
  clientId: number
  status?: string
}

export async function getClientUsers(): Promise<APIResult<ClientUser[]>> {
  const result = await instance
    .get('/api/admin/clientUsers')
    .then((res) => getResult<ClientUser[]>(res))
  return result
}
