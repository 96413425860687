import React, { useEffect, useState } from 'react'
import ChannelIntegrationsComponent from '../common/ChannelIntegrationsComponent'

import {
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'

import { err } from '../../../../utils/functions'
import { Box, CircularProgress } from '@mui/joy'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import {
  CSVProductIntegration,
  CSVTemplateIntegration,
  GetCSVSpreadsheetIntegration,
} from '../../../../types/CSV.types'
import CSVIntegrationComponent from './CSVIntegrationComponent'
import {
  getCSVIntegrations,
  setCSVProductIntegrations,
  setCSVTemplateIntegrations,
} from '../../../../api/integrations/csv'
import LoadingBox from '../../../common/LoadingBox'
import MissingIntegration from '../MissingIntegration'
import UnauthenticatedIntegration from '../UnauthenticatedIntegration'

export default function CSVIntegrationsComponent({
  template,
  product,
  onUpdate,
}: {
  template: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<IntegrationName.CSV> | undefined
  >(undefined)

  const [spreadsheetIntegrations, setSpreadsheetIntegrations] = useState<
    GetCSVSpreadsheetIntegration[] | undefined
  >(undefined)

  useEffect(() => {
    getCSVIntegrations(template.template.id, product?.product.id)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          const spreadsheetIntegrations = res.data.spreadsheetIntegrations
          delete res.data.spreadsheetIntegrations // remove from results
          setSpreadsheetIntegrations(spreadsheetIntegrations)
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        setLoading(false)
        err(e)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (!integration) {
    return (
      <Box p={4}>
        <MissingIntegration />
      </Box>
    )
  }

  if (!loading && !spreadsheetIntegrations) {
    return (
      <Box p={4}>
        <MissingIntegration />
      </Box>
    )
  }

  if (integration && !integration.authenticated) {
    return (
      <Box p={4}>
        <UnauthenticatedIntegration channelName={IntegrationName.CSV} />
      </Box>
    )
  }

  return (
    <>
      <ChannelIntegrationsComponent
        channel={IntegrationName.CSV}
        template={template}
        product={product}
        integration={integration}
        onUpdate={onUpdate}
        // onTemplateDelete={async (id) => {
        //   return await deleteCSVTemplateIntegration(id)
        // }}
        onTemplateSave={setCSVTemplateIntegrations}
        onProductSave={setCSVProductIntegrations}
        Component={({
          templateIntegration,
          productIntegration,
          template,
          product,
          count,
          onTemplateChange,
          onProductChange,
          onUpdate,
        }) =>
          !spreadsheetIntegrations ? (
            <LoadingBox />
          ) : (
            <CSVIntegrationComponent
              integration={integration}
              templateIntegration={
                templateIntegration as CSVTemplateIntegration
              }
              spreadsheetIntegrations={spreadsheetIntegrations}
              productIntegration={productIntegration as CSVProductIntegration}
              template={template}
              product={product}
              onTemplateChange={(name, templateIntegration) =>
                onTemplateChange?.(
                  name,
                  templateIntegration as CSVTemplateIntegration,
                )
              }
              onProductChange={(name, productIntegration) =>
                onProductChange?.(
                  name,
                  productIntegration as CSVProductIntegration,
                )
              }
              onUpdate={onUpdate}
            />
          )
        }
      />
    </>
  )
}
