import React, { useEffect, useState } from 'react'
import { err, round } from '../../../../utils/functions'
import PaginatedTable from '../../../common/PaginatedTable'
import Button from '@mui/joy/Button'

import Checkbox from '@mui/joy/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'

import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import {
  getSquareItems,
  squareBulkImport,
} from '../../../../api/integrations/square'
import { SquareCatalogObject } from '../../../../types/Square.types'
import { CENTS_TO_DOLLAR } from '../../../../utils/constants'
import SingleSelect from '../../../common/SingleSelect'
import FloatLabelInput from '../../../common/FloatLabelInput'
import Box from '@mui/joy/Box'

interface SquareImportOffersProps {
  templates?: string[]
}

export default function SquareImportItems({
  templates,
}: SquareImportOffersProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [squareItems, setSquareItems] = useState<SquareCatalogObject[]>([])

  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [textFilter, setTextFilter] = useState<string>('')
  const [pageSize, setPageSize] = useState<number>(10)

  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>()

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])

  const [updateExistingProducts, setUpdateExistingProducts] =
    useState<boolean>(false)

  const handleImport = () => {
    const ids = selectedItemIds
    if (!ids.length) return
    squareBulkImport({
      ids,
      template: selectedTemplate,
      updateExistingProducts,
    })
      .then((res) => {
        handleAlert(setAlert, res, 'Import process started.')
      })
      .catch((e) => err(e))
  }

  const handleBulkImport = () => {
    squareBulkImport({ template: selectedTemplate, updateExistingProducts })
      .then((res) => {
        handleAlert(setAlert, res, 'Bulk import process started.')
      })
      .catch((e) => err(e))
  }

  const handleGetItems = () => {
    setLoading(true)
    const offset = page * pageSize
    const limit = pageSize
    getSquareItems({
      textFilter,
      offset,
      limit,
    })
      .then((res) => {
        if (res.success && res.data) {
          setSquareItems(res.data.items)
          setLoading(false)
        }
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    setLoading(true)
    const offset = page * pageSize
    const limit = pageSize
    getSquareItems({
      offset,
      limit,
    })
      .then((res) => {
        if (res.success && res.data) {
          setSquareItems(res.data.items)
          setLoading(false)
        }
      })
      .catch((e) => err(e))
  }, [page, pageSize])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }
  const handlePageSizeChange = (newPageSize: number) => {
    setPage(0)
    setPageSize(newPageSize)
  }

  const handleRowClick = (row: SquareCatalogObject) => {
    const id = row.id
    const newSelectedIds = selectedItemIds.slice()
    const isSelected = selectedItemIds.find((selectedId) => selectedId === id)

    if (isSelected) {
      const newSelectedIds = selectedItemIds.filter(
        (selectedId) => selectedId !== id,
      )

      setSelectedItemIds(newSelectedIds)
    } else {
      newSelectedIds.push(id)
      setSelectedItemIds(newSelectedIds)
    }

    // Set all selected
  }

  const handleSelectAll = () => {
    // Add or remove all

    const allIds = squareItems.map((squareItem) => squareItem.id)
    if (!allIds) return

    const notSelected = allIds?.filter((id) => !selectedItemIds.includes(id))

    if (notSelected?.length) {
      // select all
      const newSelectedIds = selectedItemIds.concat(notSelected)
      setSelectedItemIds(newSelectedIds)
    } else {
      // remove all
      const newSelectedIds = selectedItemIds.filter(
        (selectedSku) => !allIds.includes(selectedSku),
      )
      setSelectedItemIds(newSelectedIds)
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography level="title-lg">Square Item Import</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box px={3}>
          <FloatLabelInput
            label="Item Filter"
            fullWidth
            value={textFilter}
            onChange={setTextFilter}
            button={
              <Button variant="solid" onClick={handleGetItems}>
                Update
              </Button>
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={3} py={2}>
          <PaginatedTable
            count={undefined}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={[5, 10, 25]}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            disabled={loading}
            head={[
              <Checkbox
                key="select"
                onClick={handleSelectAll}
                size="sm"
                sx={{ top: '3px' }}
                checked={
                  squareItems.length
                    ? squareItems.length === selectedItemIds.length
                    : false
                }
              />,
              'Title',
              'SKU',
              // 'Offer',
              'Status',
              // 'Quantity',
              'Price',
              // 'Listing',
            ]}
            data={squareItems}
            onRowClick={handleRowClick}
            row={(row, i) => {
              const id = row.id || ''
              const title = row.itemData?.name || ''
              const sku =
                row.itemData?.variations?.[0]?.itemVariationData?.sku || '-'
              const status = row.itemData?.isArchived ? 'Inactive' : 'Active'
              const variantPrices =
                row?.itemData?.variations?.map((v) =>
                  round(
                    parseInt(v.itemVariationData?.priceMoney?.amount || '0') *
                      CENTS_TO_DOLLAR,
                  ),
                ) || []
              const priceMin = Math.min(...variantPrices)
              const priceMax = Math.max(...variantPrices)
              const priceRange =
                priceMin === priceMax ? priceMax : `${priceMin} - ${priceMax}`

              const isSelected = selectedItemIds.find(
                (selectedId) => selectedId === id,
              )

              const rowArray = [
                <Checkbox
                  sx={{ p: 0, top: '3px' }}
                  key={id}
                  checked={!!isSelected}
                  size="sm"
                ></Checkbox>,
                title,
                sku,
                status,
                priceRange,
              ]

              return rowArray
            }}
          ></PaginatedTable>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography level="title-lg">Import Template</Typography>
            <Typography level="body-sm">
              Items will be merged into selected template. If none is selected,
              each item Category will be mapped to a new Template Example:
              &quot;Square Category&quot;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SingleSelect
              label="Select Template"
              freeSolo={true}
              value={selectedTemplate || ''}
              onChange={(template) => setSelectedTemplate(template)}
              options={templates || []}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={updateExistingProducts}
                        onClick={() =>
                          setUpdateExistingProducts(!updateExistingProducts)
                        }
                      />
                    }
                    label={
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography level="body-md">
                            <strong>Update Existing Products</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography level="body-sm">
                            Existing data may be overwritten
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {selectedItemIds.length} Items will be imported into{' '}
              {selectedTemplate ? (
                <strong>{selectedTemplate}</strong>
              ) : (
                'new Template(s)'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button variant="solid" onClick={handleBulkImport}>
              Bulk Import All Inventory
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="solid"
              onClick={handleImport}
              disabled={!selectedItemIds.length}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
