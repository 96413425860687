import React, { useEffect, useState } from 'react'
import ChannelIntegrationsComponent from '../common/ChannelIntegrationsComponent'
import {
  deleteEtsyTemplateIntegration,
  getEtsyShopReturnPolicies,
  getEtsyShopShippingProfiles,
  getEtsyTaxonomy,
  setEtsyProductIntegrations,
  setEtsyTemplateIntegrations,
} from '../../../../api/integrations/etsy'
import {
  getChannelIntegration,
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'
import {
  EtsyProductIntegration,
  EtsyShopReturnPolicy,
  EtsyShopShippingProfile,
  EtsyTaxonomyNode,
  EtsyTemplateIntegration,
} from '../../../../types/Etsy.types'
import EtsyIntegrationComponent from './EtsyIntegrationComponent'
import { err } from '../../../../utils/functions'
import { Box, CircularProgress } from '@mui/joy'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import LoadingBox from '../../../common/LoadingBox'
import MissingIntegration from '../MissingIntegration'
import UnauthenticatedIntegration from '../UnauthenticatedIntegration'

export default function EtsyIntegrationsComponent({
  template,
  product,
  onUpdate,
}: {
  template: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<IntegrationName.ETSY> | undefined
  >(undefined)

  const [shippingProfiles, setShippingProfiles] = useState<
    EtsyShopShippingProfile[] | undefined
  >(undefined)
  const [returnPolicies, setReturnPolicies] = useState<
    EtsyShopReturnPolicy[] | undefined
  >(undefined)
  const [taxonomy, setTaxonomy] = useState<EtsyTaxonomyNode[] | undefined>(
    undefined,
  )

  useEffect(() => {
    getChannelIntegration(
      IntegrationName.ETSY,
      template.template.id,
      product?.product.id,
    )
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        setLoading(false)
        err(e)
      })

    getEtsyTaxonomy()
      .then((res) => {
        if (res.success && res.data) {
          setTaxonomy(res.data || [])
        }
      })
      .catch((e) => err(e))
    getEtsyShopShippingProfiles()
      .then((res) => {
        if (res.success && res.data) {
          setShippingProfiles(res.data || [])
        }
      })
      .catch((e) => err(e))
    getEtsyShopReturnPolicies()
      .then((res) => {
        if (res.success && res.data) {
          setReturnPolicies(res.data || [])
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (!integration) {
    return (
      <Box p={4}>
        <MissingIntegration />
      </Box>
    )
  }

  if (integration && !integration.authenticated) {
    return (
      <Box p={4}>
        <UnauthenticatedIntegration channelName={IntegrationName.ETSY} />
      </Box>
    )
  }

  return (
    <>
      <ChannelIntegrationsComponent
        channel={IntegrationName.ETSY}
        template={template}
        product={product}
        integration={integration}
        onUpdate={onUpdate}
        onTemplateDelete={async (id) => {
          return await deleteEtsyTemplateIntegration(id)
        }}
        onTemplateSave={setEtsyTemplateIntegrations}
        onProductSave={setEtsyProductIntegrations}
        Component={({
          templateIntegration,
          productIntegration,
          template,
          product,
          count,
          onTemplateChange,
          onProductChange,
          onUpdate,
        }) =>
          !shippingProfiles || !returnPolicies || !taxonomy ? (
            <LoadingBox />
          ) : (
            <EtsyIntegrationComponent
              integration={integration}
              templateIntegration={
                templateIntegration as EtsyTemplateIntegration
              }
              productIntegration={productIntegration as EtsyProductIntegration}
              template={template}
              product={product}
              shippingProfiles={shippingProfiles}
              taxonomy={taxonomy}
              returnPolicies={returnPolicies}
              onTemplateChange={(name, templateIntegration) =>
                onTemplateChange?.(
                  name,
                  templateIntegration as EtsyTemplateIntegration,
                )
              }
              onProductChange={(name, productIntegration) =>
                onProductChange?.(
                  name,
                  productIntegration as EtsyProductIntegration,
                )
              }
              onUpdate={onUpdate}
            />
          )
        }
      />
    </>
  )
}
