import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { useEffect, useRef, useState } from 'react'
import { GetProductTemplate, getProductTemplates } from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import { useHistory } from 'react-router-dom'
import Alert, { AlertInput } from '../../components/common/Alert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { err, isDefined } from '../../utils/functions'
import Tabs from '../../components/common/Tabs'
import {
  GetIntegrationsResult,
  IntegrationDisplayName,
  IntegrationName,
  getIntegrations,
} from '../../api/integration'
import Box from '@mui/material/Box'
import EbayImportOffers from '../../components/product/integrations/ebay/EbayImportOffers'
import SquareImportItems from '../../components/product/integrations/square/SquareImportItems'
import ShopifyImportProducts from '../../components/product/integrations/shopify/ShopifyImportProducts'
import BigCommerceImportProducts from '../../components/product/integrations/bigcommerce/BigCommerceImportProducts'
import CloverImportItems from '../../components/product/integrations/clover/CloverImportProducts'
import Button from '@mui/joy/Button'

import Accordion from '@mui/joy/Accordion'
import AccordionGroup from '@mui/joy/AccordionGroup'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'
import ListItemContent from '@mui/joy/ListItemContent'
import TableJoy from '@mui/joy/Table'

export default function AddProduct(): JSX.Element {
  const history = useHistory()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const isMounted = useRef(true)
  const [templates, setTemplates] = useState<GetProductTemplate[]>([])
  const [integrations, setIntegrations] = useState<
    GetIntegrationsResult | undefined
  >()
  const handleTypeSelect = (templateId: number) => {
    history.push(`/newProduct/${templateId}`)
  }

  const handleProductsClick = () => {
    history.push('/products')
  }

  const templateNames = templates.map((t) => t.template.name)

  /* Get Product Templates */
  useEffect(() => {
    if (isMounted.current) {
      getProductTemplates()
        .then((res) => setTemplates(res?.data || []))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  useEffect(() => {
    if (isMounted.current) {
      getIntegrations()
        .then((res) => setIntegrations(res?.data))
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  })

  const tabs = integrations
    ? Object.keys(integrations)
        .map(
          (name) => IntegrationDisplayName[name as keyof GetIntegrationsResult],
        )
        .filter(isDefined)
    : []

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ marginTop: 4 }}>
        <Component p={2}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Grid container alignItems="center" sx={{ position: 'relative' }}>
                <Grid item xs={12}>
                  <Typography level="h3">
                    <Button
                      variant="solid"
                      color="primary"
                      sx={{
                        position: 'absolute',
                        left: 0,
                      }}
                      onClick={handleProductsClick}
                    >
                      <ArrowBackIcon
                        fontSize="small"
                        sx={{ paddingRight: '0.25em' }}
                      />
                      Products
                    </Button>
                    Add Product
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Typography level="h4">Select Template</Typography>
                  <Typography level="body-xs">
                    Select new Product&apos;s Template
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                  <TableJoy
                    borderAxis="both"
                    size="md"
                    sx={{
                      '& tr > *': {
                        textAlign: 'left',
                        cursor: 'pointer',
                        overflow: 'clip',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },
                    }}
                    aria-label="table variants"
                    variant={'plain'}
                    color={'neutral'}
                  >
                    <thead>
                      <tr>
                        {['Templates', 'Description', 'Products'].map(
                          (header) => (
                            <th key={header} style={{ cursor: 'default' }}>
                              {header}
                            </th>
                          ),
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {templates.map((template) => (
                        <tr
                          key={template.template.id}
                          onClick={() => handleTypeSelect(template.template.id)}
                        >
                          <td>{template.template.name}</td>
                          <td title={template.template.typeDescription}>
                            {template.template.typeDescription?.slice(0, 80)}
                          </td>
                          <td>{template.template.products}</td>
                        </tr>
                      ))}
                    </tbody>
                  </TableJoy>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <Typography level="body-sm">or</Typography>
            </Grid> */}

            <Grid item xs={12} mt={3}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                  <AccordionGroup variant="outlined" size="lg">
                    <Accordion>
                      <AccordionSummary>
                        <ListItemContent sx={{ p: 2 }}>
                          <Typography>
                            <Typography level="title-lg">
                              Import From Channels
                            </Typography>
                            <Typography level="body-xs" display={'block'}>
                              Import items from integrated channels
                            </Typography>
                          </Typography>
                        </ListItemContent>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Tabs
                          tabs={tabs}
                          panels={
                            integrations
                              ? Object.entries(integrations).map(
                                  ([name, integration]) => {
                                    return (
                                      <div key={name}>
                                        <Grid
                                          container
                                          justifyContent="center"
                                          spacing={3}
                                          p={2}
                                        >
                                          <Grid item xs={12}>
                                            {name === IntegrationName.EBAY ? (
                                              <EbayImportOffers
                                                templates={templateNames}
                                              />
                                            ) : null}
                                            {name === IntegrationName.ETSY ? (
                                              <Typography level="title-md">
                                                <strong>
                                                  Etsy Import Not Supported.
                                                </strong>
                                              </Typography>
                                            ) : null}
                                            {name === IntegrationName.SQUARE ? (
                                              <SquareImportItems
                                                templates={templateNames}
                                              />
                                            ) : null}
                                            {name ===
                                            IntegrationName.SHOPIFY ? (
                                              <ShopifyImportProducts
                                                templates={templateNames}
                                              />
                                            ) : null}
                                            {name ===
                                            IntegrationName.BIG_COMMERCE ? (
                                              <BigCommerceImportProducts
                                                templates={templateNames}
                                              />
                                            ) : null}
                                            {name === IntegrationName.CLOVER ? (
                                              <CloverImportItems
                                                templates={templateNames}
                                              />
                                            ) : null}
                                            {name === IntegrationName.CSV ? (
                                              <Typography level="title-md">
                                                <strong>
                                                  CSV Import Not Supported.
                                                </strong>
                                              </Typography>
                                            ) : null}
                                          </Grid>
                                        </Grid>
                                      </div>
                                    )
                                  },
                                )
                              : []
                          }
                        ></Tabs>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box m={4}></Box>
            </Grid>
            {/* <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography level="title-lg">Import from CSV</Typography>
                  <Typography level="body-xs">
                    This feature is a work in progress. Please check back later.
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Component>
        <Box m={30}></Box>
      </Container>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
