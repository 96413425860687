import instance from '..'
import { APIResult } from '../../utils/types'
import { getResult } from '../../utils/functions'
import { Integration } from '../types'
import {
  CSVProductIntegration,
  CSVTemplateIntegration,
  GetCSVSpreadsheetIntegration,
  SetCSVSpreadsheetIntegration,
} from '../../types/CSV.types'

export interface GetCSVSpreadsheetIntegrations {
  integration?: Integration
  spreadsheetIntegrations?: GetCSVSpreadsheetIntegration[]
  templateIntegration?: CSVTemplateIntegration
  productIntegration?: CSVProductIntegration
  authenticated: boolean
}

export async function createSheet(): Promise<APIResult<string>> {
  const sheetId = await instance
    .post('/api/integration/csv/createSheet')
    .then((res) => getResult<string>(res))
  return sheetId
}

export async function getSheetHeaders(
  spreadsheetId: string,
): Promise<APIResult<GetCSVSpreadsheetIntegration>> {
  const sheetId = await instance
    .get('/api/integration/csv/headers', {
      params: { spreadsheetId },
    })
    .then((res) => getResult<GetCSVSpreadsheetIntegration>(res))
  return sheetId
}

export interface SheetValidation {
  condition?: {
    type?: string | null
    values?: {
      relativeDate?: string | null
      userEnteredValue?: string | null
    }[]
  }
  inputMessage?: string | null
  showCustomUi?: boolean | null
  strict?: boolean | null
}

export async function getSheetValidation(
  spreadsheetId: string,
): Promise<APIResult<Record<string, unknown>>> {
  const sheetId = await instance
    .get('/api/integration/csv/validation', {
      params: { spreadsheetId },
    })
    .then((res) => getResult<Record<string, SheetValidation>>(res))
  return sheetId
}

export async function getCSVIntegrations(
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetCSVSpreadsheetIntegrations>> {
  const integrations = await instance
    .get('/api/integration/csv/integrations', {
      params: {
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetCSVSpreadsheetIntegrations>(res))
  return integrations
}

export async function getCSVSpreadsheetIntegrations(
  integrationId: number,
): Promise<APIResult<GetCSVSpreadsheetIntegration[]>> {
  const integrations = await instance
    .get('/api/integration/csv/spreadsheetIntegrations', {
      params: {
        integrationId,
      },
    })
    .then((res) => getResult<GetCSVSpreadsheetIntegration[]>(res))
  return integrations
}

export async function getClientCSVSpreadsheetIntegrations(): Promise<
  APIResult<GetCSVSpreadsheetIntegration[]>
> {
  const integrations = await instance
    .get('/api/integration/csv/clientSpreadsheetIntegrations')
    .then((res) => getResult<GetCSVSpreadsheetIntegration[]>(res))
  return integrations
}

export async function getCSVTemplateIntegration(
  integrationId: number,
  templateId: number,
): Promise<APIResult<GetCSVSpreadsheetIntegrations>> {
  const integrations = await instance
    .get('/api/integration/csv/templateIntegration', {
      params: {
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<GetCSVSpreadsheetIntegrations>(res))
  return integrations
}

export async function setCSVTemplateIntegration(
  csvTemplateIntegration: Partial<CSVTemplateIntegration>,
): Promise<APIResult<GetCSVSpreadsheetIntegrations>> {
  const integrations = await instance
    .post('/api/integration/csv/templateIntegration', {
      csvTemplateIntegration,
    })
    .then((res) => getResult<GetCSVSpreadsheetIntegrations>(res))
  return integrations
}

export async function setCSVTemplateIntegrations(
  templateIntegrations: Partial<CSVTemplateIntegration>[],
): Promise<APIResult<CSVTemplateIntegration[]>> {
  const integrations = await instance
    .post('/api/integration/csv/templateIntegrations', {
      templateIntegrations,
    })
    .then((res) => getResult<CSVTemplateIntegration[]>(res))
  return integrations
}

export async function getCSVProductIntegration(
  productId: number,
): Promise<APIResult<GetCSVSpreadsheetIntegrations>> {
  const integrations = await instance
    .get('/api/integration/csv/productIntegration', {
      params: {
        productId,
      },
    })
    .then((res) => getResult<GetCSVSpreadsheetIntegrations>(res))
  return integrations
}

export async function setCSVProductIntegration(
  csvProductIntegration: Partial<CSVProductIntegration>,
): Promise<APIResult<GetCSVSpreadsheetIntegrations>> {
  const integrations = await instance
    .post('/api/integration/csv/productIntegration', {
      csvProductIntegration,
    })
    .then((res) => getResult<GetCSVSpreadsheetIntegrations>(res))
  return integrations
}

export async function setCSVProductIntegrations(
  productIntegrations: Partial<CSVProductIntegration>[],
): Promise<APIResult<CSVProductIntegration[]>> {
  const integrations = await instance
    .post('/api/integration/csv/productIntegrations', {
      productIntegrations,
    })
    .then((res) => getResult<CSVProductIntegration[]>(res))
  return integrations
}

export async function setCSVSpreadsheetIntegration(
  csvSpreadsheetIntegration: SetCSVSpreadsheetIntegration,
): Promise<APIResult<GetCSVSpreadsheetIntegration>> {
  const integrations = await instance
    .post(
      '/api/integration/csv/spreadsheetIntegration',
      csvSpreadsheetIntegration,
    )
    .then((res) => getResult<GetCSVSpreadsheetIntegration>(res))
  return integrations
}

export async function setCSVSpreadsheetIntegrationDetails(
  csvSpreadsheetIntegration: SetCSVSpreadsheetIntegration,
): Promise<APIResult<GetCSVSpreadsheetIntegration>> {
  const integrations = await instance
    .post(
      '/api/integration/csv/spreadsheetIntegrationDetails',
      csvSpreadsheetIntegration,
    )
    .then((res) => getResult<GetCSVSpreadsheetIntegration>(res))
  return integrations
}

// export async function setCSVSpreadsheetIntegrations(
//   csvSpreadsheetIntegrations: DataFields<SetCSVSpreadsheetHeaderIntegration>[],
// ): Promise<APIResult<GetCSVSpreadsheetIntegration[]>> {
//   const integrations = await instance
//     .post('/api/integration/csv/spreadsheetIntegrations', csvSpreadsheetIntegrations)
//     .then((res) => getResult<GetCSVSpreadsheetIntegration[]>(res))
//   return integrations
// }
