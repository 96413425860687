import React, { useState, useContext } from 'react'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import { useEffect } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/joy/Typography'
import { useHistory } from 'react-router-dom'
import Button from '@mui/joy/Button'
import { clientLogin, getUserClients } from '../../api/account'
import Alert, { AlertInput } from '../common/Alert'
import AppContext, { JWT } from '../../app/AppContext'
import jwt from 'jsonwebtoken'
import { Client } from '../../api/types'
import { err, isSuperAdmin } from '../../utils/functions'

export default function ClientSelect(): JSX.Element {
  const redirectTo =
    new URLSearchParams(window.location.search).get('redirectTo') || undefined

  const history = useHistory()
  const { appState, appReducer } = useContext(AppContext)
  const roleId = appState.user.roleId
  const [clients, setClients] = useState<Client[]>([])
  const [selectedClient, setSelectedClient] = useState<Client>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleChange = (clientId: number) => {
    setSelectedClient(clients.find((client) => client.id === clientId))
  }

  const handleSubmit = async (): Promise<void> => {
    const clientId = selectedClient?.id
    if (!clientId) {
      setAlert({
        open: true,
        severity: 'warning',
        message: 'You must select a client.',
      })
      return
    }

    const result = await clientLogin(clientId)

    if (result.success && result.data) {
      const jwtData = jwt.decode(result.data) as JWT
      appReducer({ type: 'jwt', payload: jwtData })
      appReducer({
        type: 'user',
        payload: {
          roleId: jwtData.roleId,
          clientId: jwtData.clientId,
        },
      })

      history.push(redirectTo || '/dashboard')
      return
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Error logging into client.',
      })
      return
    }
  }

  useEffect(() => {
    getUserClients()
      .then((res) => {
        setClients(res?.data || [])
      })
      .catch((e) => err(e))
  }, [])
  return (
    <Component title="Select Organization">
      <Grid container justifyContent="center" direction="column" spacing={4}>
        <Grid item>
          <Typography>
            {isSuperAdmin(roleId)
              ? 'This account is a super-admin. Select a client to log in to.'
              : 'This account belongs to multiple clients. Please select the one you wish to log in to.'}
          </Typography>
        </Grid>

        <Grid item>
          <FormControl fullWidth>
            <Select
              id="client-select"
              value={selectedClient?.id || 0}
              onChange={(e) => handleChange(e.target.value as number)}
              fullWidth
              size="small"
            >
              <MenuItem value={0}>
                <em>None</em>
              </MenuItem>

              {clients.map((client, i) => (
                <MenuItem key={`client-select-${i}`} value={client.id}>
                  {client.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Button
              type="submit"
              color="primary"
              variant="solid"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </Component>
  )
}
