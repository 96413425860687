import React, { useEffect, useState } from 'react'
import ChannelIntegrationsComponent from '../common/ChannelIntegrationsComponent'

import {
  getChannelIntegration,
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'

import { err } from '../../../../utils/functions'
import { Box, CircularProgress } from '@mui/joy'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import LoadingBox from '../../../common/LoadingBox'
import CloverIntegrationComponent from './CloverIntegrationComponent'
import {
  CloverCategory,
  CloverProductIntegration,
  CloverTemplateIntegration,
} from '../../../../types/Clover.types'
import MissingIntegration from '../MissingIntegration'
import UnauthenticatedIntegration from '../UnauthenticatedIntegration'

export default function CloverIntegrationsComponent({
  template,
  product,
  onUpdate,
}: {
  template: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<IntegrationName.CLOVER> | undefined
  >(undefined)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categories, setCategories] = useState<CloverCategory[] | undefined>([])

  useEffect(() => {
    getChannelIntegration(
      IntegrationName.CLOVER,
      template.template.id,
      product?.product.id,
    )
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        setLoading(false)
        err(e)
      })

    // getCloverCategories()
    //   .then((res) => {
    //     if (res.data && res.success && Array.isArray(res.data)) {
    //       setCategories(res.data)
    //     }
    //   })
    //   .catch((e) => err(e))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (!integration) {
    return (
      <Box p={4}>
        <MissingIntegration />
      </Box>
    )
  }

  if (integration && !integration.authenticated) {
    return (
      <Box p={4}>
        <UnauthenticatedIntegration channelName={IntegrationName.CLOVER} />
      </Box>
    )
  }

  return (
    <>
      <ChannelIntegrationsComponent
        channel={IntegrationName.CLOVER}
        template={template}
        product={product}
        integration={integration}
        onUpdate={onUpdate}
        // onTemplateDelete={async (id) => {
        //   return await deleteCloverTemplateIntegration(id)
        // }}
        Component={({
          templateIntegration,
          productIntegration,
          template,
          product,
          count,
          onTemplateChange,
          onProductChange,
          onUpdate,
        }) =>
          !categories ? (
            <LoadingBox />
          ) : (
            <CloverIntegrationComponent
              integration={integration}
              templateIntegration={
                templateIntegration as CloverTemplateIntegration
              }
              productIntegration={
                productIntegration as CloverProductIntegration
              }
              template={template}
              product={product}
              categories={categories}
              onTemplateChange={(name, templateIntegration) =>
                onTemplateChange?.(
                  name,
                  templateIntegration as CloverTemplateIntegration,
                )
              }
              onProductChange={(name, productIntegration) =>
                onProductChange?.(
                  name,
                  productIntegration as CloverProductIntegration,
                )
              }
              onUpdate={onUpdate}
            />
          )
        }
      />
    </>
  )
}
