import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Tab from '@mui/joy/Tab'
import TabList from '@mui/joy/TabList'
import TabPanel from '@mui/joy/TabPanel'
import Tabs from '@mui/joy/Tabs'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import { GenericValueMapping } from '../../ProductTitleDescription'
import { AttributeValueMapping } from './AttributeValueMapping'
import { GetProductTemplate } from '../../../../api/product'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { copy } from '../../../../utils/functions'
import Tooltip from '@mui/joy/Tooltip'
import Button from '@mui/joy/Button'
import Add from '@mui/icons-material/Add'

export default function ValueMappingModal({
  open,
  template,
  templateIntegration,
  applyTo,
  selectedApplyTo: defaultSelectedApplyTo,
  valueMappingRecord,
  allowedValues,
  onChange,
  onClose,
}: {
  open: boolean
  applyTo: Record<string, string>
  selectedApplyTo?: string
  template: GetProductTemplate
  templateIntegration?: { integrationId?: number; templateIntegration?: number }
  valueMappingRecord?: Record<string, GenericValueMapping[]>
  allowedValues?: Record<string, string[]>
  onChange?: (valueMappingRecord: Record<string, GenericValueMapping[]>) => void
  onClose?: () => void
}): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const [selectedApplyTo, setSelectedApplyTo] = useState<string>(
    defaultSelectedApplyTo || Object.keys(applyTo)?.[0] || '',
  )

  const attributeNames = template.attributes.map((a) => a.attribute.name)

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        layout="center"
        sx={{ overflow: 'auto', p: 2 }}
        minWidth="lg"
      >
        <ModalClose />

        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12}>
            <Typography level="h3">Value Mapping</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography level="body-sm">
              Mapping is saved to this template.
            </Typography>
            <Typography level="body-xs">
              Set conditions to customize how attribute values are displayed on
              this channel
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Tabs
              value={selectedApplyTo}
              onChange={(e, tab) => setSelectedApplyTo(tab ? `${tab}` : '')}
              size="lg"
            >
              <TabList>
                {Object.entries(applyTo)?.map(([key, name]) => (
                  <Tab key={key} value={key}>
                    {name}
                  </Tab>
                ))}
              </TabList>
              {Object.keys(applyTo).map((applyTo) => {
                return (
                  <TabPanel value={applyTo} key={applyTo}>
                    <Grid
                      container
                      justifyContent="center"
                      spacing={md ? 2 : 6}
                    >
                      {valueMappingRecord?.[applyTo]?.map((valueMapping, i) => {
                        return (
                          <Grid item xs={12} key={valueMapping.index || i}>
                            <AttributeValueMapping
                              template={template}
                              attributeNames={attributeNames}
                              valueMapping={valueMapping}
                              allowedValues={allowedValues?.[selectedApplyTo]}
                              onChange={(valueMapping) => {
                                const newAttributeValueMapping =
                                  copy(valueMappingRecord)
                                const attributeMapping =
                                  newAttributeValueMapping[applyTo]
                                if (!attributeMapping) return
                                const updateIndex = attributeMapping.findIndex(
                                  (t) => t.index === valueMapping.index,
                                )

                                if (
                                  updateIndex < 0 ||
                                  !attributeMapping[updateIndex]
                                )
                                  return

                                attributeMapping[updateIndex] = valueMapping

                                newAttributeValueMapping[applyTo] =
                                  attributeMapping
                                onChange?.(newAttributeValueMapping)
                              }}
                            />
                          </Grid>
                        )
                      })}

                      <Grid item xs={12} mt={0.66}>
                        <Tooltip title="Add Value Mapping" size="sm">
                          <Button
                            sx={{ float: 'left' }}
                            variant="soft"
                            color="neutral"
                            onClick={() => {
                              if (!templateIntegration?.integrationId) return
                              const newAttributeValueMapping =
                                copy(valueMappingRecord) || {}
                              newAttributeValueMapping[selectedApplyTo] = (
                                newAttributeValueMapping[selectedApplyTo] ||
                                ([] as GenericValueMapping[])
                              ).concat([
                                {
                                  applyTo: selectedApplyTo,
                                  integrationId:
                                    templateIntegration?.integrationId,
                                  templateIntegrationId:
                                    templateIntegration?.templateIntegration,
                                  value: '',
                                  index:
                                    newAttributeValueMapping[selectedApplyTo]
                                      ?.length || 0,
                                },
                              ])
                              onChange?.(newAttributeValueMapping)
                            }}
                          >
                            <Add color="action" sx={{ mr: 1 }} /> Add Value
                            Mapping
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TabPanel>
                )
              })}
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <Button sx={{ float: 'right' }}>Save</Button>
          </Grid>
        </Grid>
      </ModalDialog>
    </Modal>
  )
}
