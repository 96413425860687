import React, { useState } from 'react'
import Box from '@mui/material/Box'
import {
  DataGrid,
  GridColDef,
  GridColumnMenu,
  GridColumnMenuProps,
  GridRenderCellParams,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'

interface PaginatedTableProps<T> {
  data: T[]
  head: (string | JSX.Element)[]
  row: (
    data: T,
    index?: number,
  ) => (JSX.Element | string | number | boolean | undefined)[] | JSX.Element
  renderRow?: (params: GridRenderCellParams) => JSX.Element | string
  rowData?: (data: T, index?: number) => { [k: string]: string }
  onRowClick?: (id: number) => void
  handleRowSelection?: (rowIds: number[]) => void
  sortBy?: string
  orderBy?: Order
  sortableHeaders?: string[]
  sortable?: boolean
  onHeaderClick?: (header: string, ascending: boolean) => void
  size?: 'small' | 'medium'
  count: number | undefined
  page: number
  pageSize: number
  pageSizeOptions?: number[]
  disabled?: boolean
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

type Order = 'asc' | 'desc'

export default function PaginatedTable<T extends GridValidRowModel>({
  data,
  head,
  row,
  rowData,
  renderRow,
  onRowClick,
  handleRowSelection,
  sortBy,
  orderBy: defaultOrderBy,
  sortable,
  sortableHeaders,
  size = 'small',
  count,
  page,
  pageSize,
  pageSizeOptions,
  disabled,
  onHeaderClick,
  onPageChange,
  onPageSizeChange,
}: PaginatedTableProps<T>): JSX.Element {
  const [order, setOrder] = useState<Order>(defaultOrderBy || 'asc')
  const [orderBy, setOrderBy] = useState<string | undefined>(sortBy)

  const [localPage, setLocalPage] = useState<number>(page)
  const [localPageSize, setLocalPageSize] = useState<number>(pageSize)

  const handlePageChange = (newPage: number) => {
    setLocalPage(newPage)
    onPageChange(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setLocalPage(0)
    setLocalPageSize(newPageSize)
    onPageSizeChange(newPageSize)
  }

  const handleRequestSort = (by: string) => {
    const key = by
    if (!key) {
      return
    }
    const isAsc = orderBy === by && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(key)
    onHeaderClick?.(by, !isAsc)
  }

  const displayData = data

  const columns = head.map((header): GridColDef => {
    return {
      field: typeof header === 'string' ? header : '-',
      align: 'center',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string>) => {
        if (renderRow) {
          return renderRow(params)
        }
        return '-'
      },
    }
  })

  const rows = displayData.map(
    (data) =>
      rowData?.(data) || {
        id: Math.random(),
      },
  )

  return rows.length ? (
    <Box>
      <div style={{ width: '100%' }}>
        <DataGrid
          columns={columns}
          rows={rows}
          slots={{ columnMenu: CustomColumnMenu }}
          onColumnHeaderClick={(params) => {
            if (params.field === '__check__') return
            handleRequestSort(params.field)
          }}
          autosizeOnMount={true}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
          sortModel={
            orderBy && order
              ? [
                  {
                    field: orderBy,
                    sort: order,
                  },
                ]
              : undefined
          }
          onRowClick={(params: GridRowParams<T>) => {
            // onRowClick?.(params.row.id)
          }}
          onRowSelectionModelChange={(rowSelectionModel) => {
            handleRowSelection?.(rowSelectionModel as number[])
          }}
          rowCount={count}
          paginationModel={{
            page: localPage,
            pageSize: localPageSize,
          }}
          onPaginationModelChange={(model) => {
            if (model.page !== localPage) {
              handlePageChange(model.page)
            }
            if (model.pageSize !== localPageSize) {
              handlePageSizeChange(model.pageSize)
            }
          }}
          paginationMode="server"
          pageSizeOptions={pageSizeOptions || [5, 10, 25, 50, 100]}
        />
      </div>
    </Box>
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Alert severity="info">No Data</Alert>
      </Grid>
    </Grid>
  )
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: null,
      }}
      slotProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 10, // Previously `0`
        },
      }}
    />
  )
}
