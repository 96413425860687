import React, { useEffect, useRef } from 'react'
import Button from '@mui/joy/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { useState } from 'react'
import {
  createProductTemplate,
  CreateProductTemplateInput,
  GetProductTemplateAttribute,
  SetProductTemplateAttribute,
} from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import Alert, { AlertInput } from '../../components/common/Alert'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import { err, wait } from '../../utils/functions'
import { newAttribute } from './ProductTemplate'
import EbayCategories, {
  EbayCategory,
} from '../../components/product/integrations/ebay/EbayCategories'
import { ebayCreateTemplate } from '../../api/integrations/ebay'
import LabelInput from '../../components/common/LabelInput'
import LabelTextarea from '../../components/common/LabelTextarea'
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import { IntegrationName } from '../../api/integration'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/joy'

export const STOCK_ATTRIBUTES = [
  'Brand',
  // 'Category',
  // 'Condition Description',
  'Vendor',
  'Location',
  'Warehouse',
  'Bin',
]

export function getStockAttributes(
  attributes: GetProductTemplateAttribute[],
): SetProductTemplateAttribute[] {
  const existingAttributeNames = attributes.map((a) => a.attribute.name)
  const stockAttributes = STOCK_ATTRIBUTES.filter(
    (name) => !existingAttributeNames.includes(name),
  ).map((name, i) =>
    newAttribute({
      name,
      templateId: attributes[0]?.attribute.templateId,
      index: i,
    }),
  )
  return stockAttributes
}

export default function CreateTemplate(): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('md'))

  const history = useHistory()
  const isMounted = useRef(true)
  // Product Template
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [notes, setNotes] = useState<string | undefined>()
  const [tags, setTags] = useState<string>('')

  const [templateId, setProductTemplateId] = useState<number>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [category, setCategory] = useState<EbayCategory | undefined>()
  const [categoryVersion, setCategoryVersion] = useState<number>(0)

  const [createMethod, setCreateMethod] = useState<string>('create')

  const handleSetCreateMethod = (method: string) => {
    if (!method) return
    setCreateMethod(method)

    if (method === 'create' && category) {
      setCategory(undefined)
      setName('')
    }
  }

  const handleSelectCategory = (
    categoryVersion: number,
    category: EbayCategory | undefined,
    path?: string[],
  ) => {
    const name = path ? path?.join(' > ') : category?.name
    setCategoryVersion(categoryVersion)
    setCategory(category)
    if (category) {
      setName(`Ebay ${name} - ${category.id}`)
    }
  }

  useEffect(() => {
    if (templateId !== undefined) {
      history.push(`template/${templateId}`)
    }
  }, [history, templateId])

  const handleSubmit = () => {
    if (isMounted.current) {
      if (!name.trim()) {
        setNameError('Name field is required.')
        return
      }

      if (category?.id && name) {
        ebayCreateTemplate(category.id, name)
          .then(async (res) => {
            if (res?.success) {
              const templateId = res.data?.template.id
              if (templateId) {
                setAlert({
                  open: true,
                  message: 'Template Created.',
                  severity: 'success',
                })

                await wait(1000)
                setProductTemplateId(templateId)
              } else {
                setAlert({
                  open: true,
                  message: 'Unknown response from server.',
                  severity: 'error',
                })
              }
            } else {
              setAlert({
                open: true,
                message: res?.message || 'Error',
                severity: 'error',
              })
            }
          })
          .catch((e) => {
            setAlert({
              open: true,
              message: e?.message || 'Error',
              severity: 'error',
            })
          })

        return
      }

      const templateAttributes = STOCK_ATTRIBUTES.map(
        (name, i) =>
          newAttribute({ name, value: '', type: 'text', index: i }).attribute,
      )
      const template: CreateProductTemplateInput = { name, description }
      createProductTemplate(template, templateAttributes)
        .then((result) => {
          if (result?.success) {
            const templateId = result.data?.id
            if (templateId) {
              setAlert({
                open: true,
                message: 'Template Created.',
                severity: 'success',
              })
              setProductTemplateId(templateId)
            } else {
              setAlert({
                open: true,
                message: 'Unknown response from server.',
                severity: 'error',
              })
            }
          } else {
            setAlert({
              open: true,
              message: result?.message || 'Error',
              severity: 'error',
            })
          }

          return () => {
            isMounted.current = false
          }
        })
        .catch((e) => err(e))
    }
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ marginTop: 4 }}>
        <Component>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography level="h3">Create a Template</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <ToggleButtonGroup
                  orientation={md ? 'vertical' : 'horizontal'}
                  value={createMethod}
                  onChange={(e, v) => {
                    handleSetCreateMethod(v as string)
                  }}
                >
                  <Button value="create">
                    <Box p={3} sx={{ width: md ? '300px' : '350px' }}>
                      <Typography level="title-lg" component="p">
                        From Scratch
                      </Typography>
                      <Typography level="body-xs" component="p">
                        Create a template from scratch
                      </Typography>
                    </Box>
                  </Button>
                  <Button value="build">
                    <Box p={3} sx={{ width: md ? '300px' : '350px' }}>
                      <Typography level="title-lg">Channel Based</Typography>
                      <Typography level="body-xs">
                        Base on an external channel category
                      </Typography>
                    </Box>
                  </Button>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            {createMethod === 'build' ? (
              <>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2} mt={4}>
                    <Grid item xs={12}>
                      <Typography level="title-lg">
                        Select Channel and Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Tabs
                          size="lg"
                          defaultValue={IntegrationName.EBAY}
                          variant="outlined"
                        >
                          <TabList>
                            <Tab
                              variant="plain"
                              color="neutral"
                              value={IntegrationName.EBAY}
                            >
                              Ebay
                            </Tab>
                            <Tab
                              variant="plain"
                              color="neutral"
                              value={IntegrationName.ETSY}
                            >
                              Etsy
                            </Tab>
                          </TabList>
                          <TabPanel value={IntegrationName.EBAY} sx={{ p: 1 }}>
                            <Grid
                              container
                              justifyContent="center"
                              spacing={2}
                              sx={{ px: 0, py: 2 }}
                            >
                              <Grid item xs={12}>
                                <EbayCategories
                                  category={category}
                                  categoryVersion={categoryVersion}
                                  onChange={handleSelectCategory}
                                />
                              </Grid>
                              {category && (
                                <>
                                  <Grid item xs={12}>
                                    <Typography level="body-xs">
                                      Template will create Custom Attributes for
                                      all aspects in Ebay category{' '}
                                      {category.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent="center"
                                    >
                                      <Grid item xs={12}>
                                        <LabelInput
                                          label="Template Name"
                                          required
                                          value={name}
                                          fullWidth
                                          onChange={setName}
                                          errorText={nameError}
                                          onClick={() => {
                                            setNameError('')
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <LabelTextarea
                                          label="Template Description"
                                          minRows={10}
                                          value={description}
                                          onChange={setDescription}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <LabelTextarea
                                          label="Notes"
                                          minRows={5}
                                          value={notes}
                                          onChange={setNotes}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <LabelInput
                                          label="Tags"
                                          fullWidth
                                          value={tags}
                                          onChange={setTags}
                                          helperText="Comma separeted"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={IntegrationName.ETSY} sx={{ p: 1 }}>
                            <Grid
                              container
                              justifyContent="center"
                              spacing={1}
                              sx={{ px: 0, py: 2 }}
                            >
                              <Grid item xs={12}>
                                <Typography>
                                  Etsy Build a Template will be available soon.
                                </Typography>
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Tabs>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}

            {createMethod === 'create' ? (
              <Grid item xs={12} mt={4}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      label="Template Name"
                      required
                      value={name}
                      fullWidth
                      onChange={setName}
                      errorText={nameError}
                      onClick={() => {
                        setNameError('')
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LabelTextarea
                      label="Template Description"
                      minRows={10}
                      value={description}
                      onChange={setDescription}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LabelTextarea
                      label="Notes"
                      minRows={5}
                      value={notes}
                      onChange={setNotes}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      label="Tags"
                      fullWidth
                      value={tags}
                      onChange={setTags}
                      helperText="Comma separeted"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Button onClick={() => handleSubmit()} variant="solid">
                  Next
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ height: '100px' }} />
          </Grid>
        </Component>
        <Box p={30}></Box>
      </Container>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
