import { GetProduct, GetProductAttribute } from '../api/product'
import { Product, ProductTemplateAttribute } from '../api/types'
import { GenericValueMapping } from '../components/product/ProductTitleDescription'
import { PRODUCT_DISPLAY_NAMES, PRODUCT_FIELD_NAMES } from '../types'
import {
  ATTRIBUTE_TAG_END,
  ATTRIBUTE_TAG_START,
  DEFAULT_ATTRIBUTE_TYPE,
} from '../utils/constants'
import { isDefined } from '../utils/functions'

export type AttributeValue = {
  name: string
  type: ProductTemplateAttribute['type']
  value: string | undefined
}
export function replaceAttributes(
  str: string,
  getProduct: GetProduct,
  valueMapping?: GenericValueMapping[],
): string {
  const productAttributeValues = getProductAttributeValues(
    getProduct,
    valueMapping,
  )

  let parsingStr = str
  // loop through
  for (const { name, value } of productAttributeValues) {
    const pattern = `${ATTRIBUTE_TAG_START}\\s*?${name}\\s*?${ATTRIBUTE_TAG_END}`
    const reg = new RegExp(pattern, 'gim')
    parsingStr = parsingStr.replaceAll(reg, value || '')
  }

  // replace unmatched
  const pattern = `${ATTRIBUTE_TAG_START}\\s*?.*?\\s*?${ATTRIBUTE_TAG_END}`
  const reg = new RegExp(pattern, 'gim')
  parsingStr = parsingStr.replaceAll(reg, '')

  return replaceValues(parsingStr, getProduct)
}

export function replaceDefinedAttributes(
  str: string,
  getProduct: GetProduct,
  valueMapping?: GenericValueMapping[],
): string {
  const productAttributeValues = getProductAttributeValues(
    getProduct,
    valueMapping,
  )

  let parsingStr = str
  for (const { name, value } of productAttributeValues) {
    if (value === undefined || value === '') continue

    const pattern = `${ATTRIBUTE_TAG_START}\\s*?${name}\\s*?${ATTRIBUTE_TAG_END}`
    const reg = new RegExp(pattern, 'gim')
    parsingStr = parsingStr.replaceAll(reg, value)
  }

  return replaceValues(parsingStr, getProduct)
}

export function replaceValues(str: string, getProduct: GetProduct): string {
  const fields = Object.values(PRODUCT_FIELD_NAMES)
  const fieldValues = fields.map(
    (field): AttributeValue => ({
      name: field,
      type: 'text',
      value:
        getProduct.product[field] === undefined ||
        getProduct.product[field] === ''
          ? undefined
          : `${getProduct.product[field]}`,
    }),
  )

  let parsingStr = str
  // loop through
  for (const { name, value } of fieldValues) {
    if (value === undefined) continue
    const pattern = `${ATTRIBUTE_TAG_START}\\s*?${name}\\s*?${ATTRIBUTE_TAG_END}`
    const reg = new RegExp(pattern, 'gim')
    parsingStr = parsingStr.replaceAll(reg, value || '')
  }

  return parsingStr
}

export function extractAttributeNames(input: string): string[] {
  const matches = input.matchAll(/\{\{\s*(.*?)\s*\}\}/gim)
  if (!matches) return []
  return [...matches].map((match) => match[1]).filter(isDefined)
}

export function extractAttributeName(input: string | undefined): string {
  if (!input) return ''
  const matches = input.matchAll(/\{\{\s*(.*?)\s*\}\}/gim)
  if (!matches) return ''
  return [...matches].map((match) => match[1]).filter(isDefined)[0] || ''
}

export function parseDescription(
  description: string,
  getProduct: GetProduct,
  valueMapping?: GenericValueMapping[],
): string {
  // parse paragraphs
  const paragraphs = description.split(/\n{2,}/gim)
  const parsedParagraphs = paragraphs.map((paragraph) => {
    // split paragraph into lines
    const lines = paragraph.split(/\n/gim)

    const parseLines = lines
      .map((line) => {
        const parsedLine = replaceDefinedAttributes(
          line,
          getProduct,
          valueMapping,
        )
        if (
          parsedLine.includes(ATTRIBUTE_TAG_START) ||
          parsedLine.includes(ATTRIBUTE_TAG_END)
        ) {
          return undefined
        }
        return parsedLine
      })
      .filter(isDefined)

    return parseLines.join('\n')
  })

  // parse
  const parsedDescription = parsedParagraphs.join('\n\n')
  return parsedDescription.trim().replaceAll(/\n{2,}/gim, '\n\n') // replace multiple new lines
}

export function parseTitle(
  title: string,
  getProduct: GetProduct,
  valueMapping?: GenericValueMapping[],
): string {
  const parsedTitle = replaceAttributes(title, getProduct, valueMapping)
  return parsedTitle.trim().replaceAll(/\s{2,}/gim, ' ') // replace multiple spaces
}

export function getProductValueAttributesValues(
  product: Product,
): AttributeValue[] {
  const attributeValues: AttributeValue[] = []
  Object.entries(product).forEach(
    ([name, value]): AttributeValue | undefined => {
      const displayName = PRODUCT_DISPLAY_NAMES[name]
      if (!displayName) return undefined
      attributeValues.push({
        name: displayName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        value: `${value}`,
      })
    },
  )
  return attributeValues
}

export function applyValueMapping(
  attribute: GetProductAttribute | undefined,
  valueMapping: GenericValueMapping[] | undefined,
): string | undefined {
  if (!attribute) return undefined

  let attributeValue = attribute.attribute?.value
  const attributeValueMapping = valueMapping?.filter(
    (valueMapping) =>
      valueMapping.attributeId === attribute.templateAttribute.id,
  )

  if (attributeValueMapping?.length) {
    for (const valueMapping of attributeValueMapping) {
      const values = valueMapping.values?.map((v) => v.value.toLowerCase())

      if (
        valueMapping.condition === 'equals' &&
        ((attributeValue && values?.includes(attributeValue.toLowerCase())) ||
          (!attributeValue && !values?.length))
      ) {
        console.log('Contains', values)
        attributeValue = valueMapping.value
      } else if (
        valueMapping.condition === 'not equals' &&
        attributeValue &&
        !values?.includes(attributeValue.toLowerCase())
      ) {
        attributeValue = valueMapping.value
      } else if (
        valueMapping.condition === 'not contains' &&
        values &&
        attributeValue &&
        !new RegExp(`(${values.join('|')})`, 'gim').exec(attributeValue)?.[1]
      ) {
        attributeValue = valueMapping.value
      } else if (
        valueMapping.condition === 'contains' &&
        values &&
        attributeValue &&
        new RegExp(`(${values.join('|')})`, 'gim').exec(attributeValue)?.[1]
      ) {
        if (valueMapping.function === 'use value') {
          attributeValue = valueMapping.value
        } else if (valueMapping.function === 'replace with') {
          const newValue = attributeValue
            .replace(
              new RegExp(`(${values.join('|')})`, 'gim'),
              valueMapping.value || '',
            )
            .trim()
          attributeValue = newValue
        }
      }
    }
  }

  return attributeValue
}

export function getProductAttributeAttributeValues(
  product: GetProduct,
  valueMapping?: GenericValueMapping[],
): AttributeValue[] {
  const productAttributeValues = product.attributes.map(
    (attribute, i): AttributeValue => {
      const attributeValue = applyValueMapping(attribute, valueMapping)
      // let attributeValue = attribute.attribute?.value
      // const attributeValueMapping = valueMapping?.filter(
      //   (valueMapping) =>
      //     valueMapping.attributeId === attribute.templateAttribute.id,
      // )

      // if (attributeValueMapping) {
      //   for (const valueMapping of attributeValueMapping) {
      //     attributeValue = newAttributeValue[i] || attributeValue

      //     const values = valueMapping.values?.map((v) => v.value)

      //     if (
      //       valueMapping.condition === 'equals' &&
      //       ((attributeValue && values?.includes(attributeValue)) ||
      //         (!attributeValue && !values?.length))
      //     ) {
      //       attributeValue = valueMapping.value
      //     } else if (
      //       valueMapping.condition === 'not equals' &&
      //       attributeValue &&
      //       !values?.includes(attributeValue)
      //     ) {
      //       attributeValue = valueMapping.value
      //     } else if (
      //       valueMapping.condition === 'not contains' &&
      //       values &&
      //       attributeValue &&
      //       !new RegExp(`(${values.join('|')})`, 'gim').exec(
      //         attributeValue,
      //       )?.[1]
      //     ) {
      //       attributeValue = valueMapping.value
      //     } else if (
      //       valueMapping.condition === 'contains' &&
      //       values &&
      //       attributeValue &&
      //       new RegExp(`(${values.join('|')})`, 'gim').exec(attributeValue)?.[1]
      //     ) {
      //       if (valueMapping.function === 'use value') {
      //         attributeValue = valueMapping.value
      //       } else if (valueMapping.function === 'replace with') {
      //         const newValue = attributeValue
      //           .replace(
      //             new RegExp(`(${values.join('|')})`, 'gim'),
      //             valueMapping.value || '',
      //           )
      //           .trim()
      //         newAttributeValue[i] = newValue
      //         attributeValue = newValue
      //       }
      //     }
      //   }
      // }

      // Replace attribute values here
      // if (attributeValue && getAttributeNames(attributeValue).length) {
      //   attributeValue = replaceAttributes(attributeValue, product)
      // }

      return {
        name: attribute.templateAttribute.name,
        type: attribute.templateAttribute.type,
        value: attributeValue,
      }
    },
  )

  return productAttributeValues
}

export function getProductAttributeValues(
  getProduct: GetProduct,
  valueMapping?: GenericValueMapping[],
): AttributeValue[] {
  const attributeValues = getProductAttributeAttributeValues(
    getProduct,
    valueMapping,
  )

  if (attributeValues.find((v) => v.value === 'Steel')) {
    console.log(attributeValues)
  }
  const productValues = getProductValueAttributesValues(getProduct.product)
  return attributeValues.concat(productValues)
}
