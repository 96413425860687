import React, { useState } from 'react'
import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { Link, useHistory } from 'react-router-dom'
import Component from '../../components/common/Component'
import { sha256 } from '../../utils/functions'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { createAccount } from '../../api/account'
import { useContext } from 'react'
import AppContext, { JWT } from '../../app/AppContext'
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import LabelInput from '../../components/common/LabelInput'

type SnackbarSeverity = 'success' | 'warning' | 'error' | 'info'
type SnackbarInput = {
  open: boolean
  severity?: SnackbarSeverity
  message?: string | undefined
}

export default function CreateAccount(): JSX.Element {
  const history = useHistory()
  const { appReducer } = useContext(AppContext)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [snackbar, setSnackbar] = useState<SnackbarInput>({ open: false })
  const handleSnackBarClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }
  const handleReturn = () => {
    history.push('/login')
  }

  const isValidEmail = (email: string): boolean => {
    const validEmail = email
      ? email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null
      : true
    if (validEmail) {
      setEmailError('')
    } else {
      setEmailError('Please insert a valid email')
    }
    return validEmail
  }

  const isValidPassword = (pw: string): boolean => {
    const MIN_PASSWORD_LENGTH = 8
    const validPassword = pw
      ? /[A-Z]/.test(pw) &&
        /[a-z]/.test(pw) &&
        /[0-9]/.test(pw) &&
        /[^A-Za-z0-9]/.test(pw) &&
        pw.length > MIN_PASSWORD_LENGTH
      : true

    if (validPassword) {
      setPasswordError('')
    } else {
      setPasswordError(
        `Password must be 8 characters long and include an uppercase, number, and special character.`,
      )
    }
    return validPassword
  }

  const passwordsMatch = (pw: string, checkPw: string) => {
    return pw === checkPw
  }

  const handleSubmit = async () => {
    const [hashPass, hashCheckPass] = await Promise.all([
      sha256(password),
      sha256(checkPassword),
    ])

    // check email and password again
    if (!email || !isValidEmail(email)) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Invalid email.',
      })
    } else if (!password || !isValidPassword(password)) {
      setSnackbar({
        open: true,
        severity: 'error',
        message:
          'Must conatain an uppercase, lowercase, number, special character, and be at least 8 characters long.',
      })
    } else if (
      !password ||
      !checkPassword ||
      !passwordsMatch(password, checkPassword)
    ) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Passwords do not match.',
      })
    }

    const result = await createAccount(email, hashPass, hashCheckPass)
    // On success create route to login page
    if (result.success) {
      const jwtData = jwt.decode(result.data || '') as JWT
      const expires =
        jwtData.exp && jwtData.iat
          ? jwtData.exp - jwtData.iat
          : 7 * 24 * 60 * 60 // 1 week default
      // This is secure since we are not storing the entire token, which is needed for verification.
      Cookies.set('jwt', JSON.stringify(jwtData), { expires })
      appReducer({ type: 'jwt', payload: jwtData })
      history.push('/confirmEmail')
    } else {
      setSnackbar({
        open: true,
        severity: 'error',
        message: result.message,
      })
    }
  }

  return (
    <div
      style={{
        height: '100vh',
        background:
          'linear-gradient(180deg, rgba(2,173,151,1) 0%, rgba(2,173,151,1) 51%, #00796b 100%)',
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            marginTop: '6em',
          }}
        >
          <Component p={3}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Typography level="h1" component="h1" fontSize={48}>
                Sellmotto
              </Typography>
            </Link>
            <Typography level="h4" color="neutral" mt={1}>
              Create your Sellmotto Account
            </Typography>

            <form
              onSubmit={async (e) => {
                e.preventDefault()
                await handleSubmit()
              }}
            >
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <LabelInput
                    size="lg"
                    value={email}
                    label="Email"
                    required
                    onChange={setEmail}
                    onBlur={(e) => isValidEmail(e?.target?.value || '')}
                    fullWidth
                    errorText={emailError}
                    autoComplete="email"
                    autoCapitalize={false}
                    autoCorrect={false}
                  ></LabelInput>
                </Grid>

                <Grid item xs={12}>
                  <LabelInput
                    size="lg"
                    value={name}
                    label="Name"
                    required
                    fullWidth
                    onChange={setName}
                  ></LabelInput>
                </Grid>

                <Grid item xs={12}>
                  <LabelInput
                    size="lg"
                    value={password}
                    label="Password"
                    type="password"
                    required
                    fullWidth
                    onChange={setPassword}
                    onBlur={(e) => isValidPassword(e?.target?.value || '')}
                    errorText={passwordError}
                    autoCapitalize={false}
                    autoCorrect={false}
                  ></LabelInput>
                </Grid>
                <Grid item xs={12}>
                  <LabelInput
                    size="lg"
                    value={checkPassword}
                    label="Re-Enter Password"
                    type="password"
                    required
                    fullWidth
                    onChange={setCheckPassword}
                    autoCapitalize={false}
                    autoCorrect={false}
                  ></LabelInput>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ marginTop: '1em' }}
                  >
                    <Grid item>
                      <Button
                        size="lg"
                        variant="plain"
                        color="neutral"
                        onClick={handleReturn}
                      >
                        Return
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="lg"
                        variant="solid"
                        color="primary"
                        type="submit"
                        sx={{ cursor: 'pointer' }}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Component>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleSnackBarClose}
          severity={snackbar.severity || 'info'}
          elevation={6}
          variant="filled"
        >
          {snackbar.message || ''}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}
