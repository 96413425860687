import React from 'react'
export default function SplashPage({ path }: { path?: string }): JSX.Element {
  const domain =
    process.env.HOMEPAGE_URL || 'https://info290304.wixsite.com/commerce'
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <iframe
        width="100%"
        height="100%"
        style={{
          border: 0,
        }}
        title="Commerce"
        src={`${domain}${path ? '/' + path : ''}`}
      ></iframe>
    </div>
  )
}
