import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'
import Typography from '@mui/joy/Typography'
import DisconnectIntegration from '../../components/integrations/DisconnectIntegration'
import LockResetIcon from '@mui/icons-material/LockReset'
import InputContainer from '../../components/common/InputContainer'

export default function EtsyIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.ETSY>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  const handleOpenShop = () => {
    const appStoreUrl = `https://www.etsy.com/your/shops/me`
    window.open(appStoreUrl, '_blank')
  }

  return (
    <Grid container justifyContent="center" spacing={6}>
      {integration.authenticated ? (
        <>
          <Grid item xs={12}>
            {integration.integration?.shop ? (
              <Grid
                container
                justifyContent="center"
                spacing={2}
                p={2}
                sx={{
                  border: '1px solid #e8e9eb',
                  borderRadius: '6px',
                  boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
                  color: '#32383E',
                  backgroundColor: '#FBFCFE',
                }}
              >
                <Grid item xs={12}>
                  <Typography level="title-lg">Shop Details</Typography>
                  <Typography level="body-sm">
                    Shop ID:{' '}
                    {integration.integration.shop || 'Shop name not set.'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleOpenShop}>Open Shop</Button>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : null}

      {!integration.authenticated ? (
        <Grid item xs={12}>
          <InputContainer>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Typography level="title-lg">Authentication</Typography>
                <Typography level="body-sm">
                  To authenticate app access, install the Sellmotto App to your
                  store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleAppStoreClick}>
                  Open App Store
                </Button>
              </Grid>
            </Grid>
          </InputContainer>
        </Grid>
      ) : null}

      {integration.authenticated ? (
        <Grid item xs={12}>
          <InputContainer>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography level="title-lg">Authentication</Typography>
                <Typography level="body-sm">
                  To re-authenticate app access, re-install the Sellmotto App to
                  your store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="solid"
                  onClick={handleAppStoreClick}
                  endDecorator={<LockResetIcon />}
                >
                  Re-Authorize
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography level="body-xs">
                  Authentication Last Updated:{' '}
                  {integration.integration?.updatedAt
                    ? new Date(
                        integration.integration.updatedAt,
                      ).toLocaleString()
                    : 'Unknown'}
                </Typography>
              </Grid>
            </Grid>
          </InputContainer>
        </Grid>
      ) : null}

      {integration?.integration && integration?.authenticated ? (
        <Grid item xs={12}>
          <DisconnectIntegration
            integration={integration.integration}
            channel={IntegrationName.ETSY}
            setAlert={setAlert}
          />
        </Grid>
      ) : undefined}

      <Grid item xs={12}></Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
