import React, { useEffect, useState } from 'react'
import NamedMultiSelect from '../../../common/NamedMultiSelect'
import { err, isDefined } from '../../../../utils/functions'
import AutocompleteListbox from '@mui/joy/AutocompleteListbox'
import Box from '@mui/joy/Box'
import Checkbox from '@mui/joy/Checkbox'
import CircularProgress from '@mui/joy/CircularProgress'
import Typography from '@mui/joy/Typography'
import { TreeView } from '@mui/x-tree-view/TreeView'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { SquareCatalogObject } from '../../../../types/Square.types'
import { getSquareCategories } from '../../../../api/integrations/square'

type SquareCatalogObjectChildren = SquareCatalogObject & {
  children?: SquareCatalogObject[]
}

export default function SquareCategorySelect({
  value,
  onSelect,
  onBlur,
  categories: defaultCategories,
  label,
}: {
  value: string[]
  categories?: SquareCatalogObjectChildren[]
  label?: string
  onSelect?: (values: string[]) => void
  onBlur?: (values: string[]) => void
}): JSX.Element {
  const getAllCategories = (
    categories: SquareCatalogObjectChildren[] | undefined,
  ) => {
    if (!categories?.length) return []
    const newAllCategories: SquareCatalogObjectChildren[] = []
    const addAllCategories = (categories: SquareCatalogObjectChildren[]) => {
      categories.forEach((c) => {
        newAllCategories.push(c)
        if (c.children?.length) {
          addAllCategories(c.children)
        }
      })
    }
    addAllCategories(categories)
    return newAllCategories
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [categories, setCategories] = useState<
    SquareCatalogObjectChildren[] | undefined
  >(defaultCategories)
  const [allCategories] = useState<SquareCatalogObjectChildren[]>(
    getAllCategories(defaultCategories),
  )
  const [categoryIds] = useState<string[]>(allCategories.map((c) => c.id))

  const [selectedCategoryIds, setSelectedCategoryIds] =
    useState<string[]>(value)

  const handleSelectIds = (values?: string[]) => {
    setSelectedCategoryIds(values || [])

    if (!values?.length) {
      onBlur?.(values || [])
    }
    onSelect?.(values || [])
  }

  useEffect(() => {
    if (categories) return
    setLoading(true)
    getSquareCategories()
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setCategories(res.data)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!categories || loading) {
    return (
      <Box>
        <CircularProgress size="sm" />
      </Box>
    )
  }

  return (
    <NamedMultiSelect
      limitTags={1}
      name={label}
      placeholder={'Category'}
      disableInput={true}
      value={
        allCategories
          ?.filter((c) => selectedCategoryIds?.includes(c.id))
          .map((c) => c.categoryData?.name || undefined)
          .filter(isDefined) || []
      }
      freeSolo={false}
      onChange={(values) => {
        // Handle clear events
        if (!values.length) handleSelectIds([])
        const valueIds = values
          .map(
            (v) => allCategories?.find((c) => c.categoryData?.name === v)?.id,
          )
          .filter(isDefined)
        handleSelectIds(valueIds)
      }}
      onBlur={() => {
        onBlur?.(selectedCategoryIds)
      }}
      options={[]}
      slots={{
        listbox: React.forwardRef(function DisplayListBox(
          props: {
            open: boolean
          },
          ref: React.ForwardedRef<HTMLUListElement | null>,
        ) {
          return (
            <AutocompleteListbox
              ref={ref}
              {...props}
              sx={{
                position: 'absolute',
                display: props.open ? 'inherit' : 'none',
              }}
            >
              <DisplayCategories
                categories={categories || []}
                categoryIds={categoryIds || []}
                selectedCategoryIds={selectedCategoryIds || []}
                onChange={(category) => {
                  if (!category) return
                  if (!selectedCategoryIds?.includes(category.id)) {
                    handleSelectIds(selectedCategoryIds?.concat([category.id]))
                  } else {
                    const newSelectedCategoryIds = selectedCategoryIds?.slice()
                    const index = newSelectedCategoryIds.findIndex(
                      (c) => c === category.id,
                    )
                    if (index < 0) return
                    newSelectedCategoryIds.splice(index, 1)
                    handleSelectIds(newSelectedCategoryIds)
                  }
                }}
              />
            </AutocompleteListbox>
          )
        }),
      }}
    />
  )
}

function DisplayCategories({
  categories,
  categoryIds,
  selectedCategoryIds,
  onChange,
}: {
  categories: SquareCatalogObject[]
  categoryIds: string[]
  selectedCategoryIds: string[]
  onChange: (category: SquareCatalogObject | undefined) => void
}): JSX.Element {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      sx={{
        width: '100%',
      }}
      defaultExpanded={categoryIds.map((c) => `${c}`)} // selected or has child selected
    >
      {categories?.map((node) => (
        <TreeNode
          node={node}
          key={node.id}
          onChange={onChange}
          selectedCategoryIds={selectedCategoryIds}
        />
      ))}
    </TreeView>
  )
}

function TreeNode({
  node,
  selectedCategoryIds,
  onChange,
}: {
  node: SquareCatalogObject & { children?: SquareCatalogObject[] }
  selectedCategoryIds: string[]
  onChange: (category: SquareCatalogObject) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.id}
      nodeId={`${node.id}`}
      sx={{ borderRadius: '6px' }}
      label={
        <Typography
          p={0.5}
          sx={{ textAlign: 'left', borderRadius: '6px' }}
          startDecorator={
            <Checkbox
              onClick={(e) => {
                e.stopPropagation()
                onChange(node)
              }}
              sx={{ mr: 0.5 }}
              checked={selectedCategoryIds.includes(node.id)}
            />
          }
        >
          {node.categoryData?.name}
        </Typography>
      }
      onClick={() => !node.children?.length && onChange(node)}
    >
      {node?.children?.map((node) => (
        <TreeNode
          node={node}
          key={node.id}
          onChange={onChange}
          selectedCategoryIds={selectedCategoryIds}
        />
      ))}
    </TreeItem>
  )
}
