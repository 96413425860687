import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import {
  GetIntegrationResult,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { getDescriptionValue, getTitleValue } from '../../../../utils/functions'
import {
  SquareLocation,
  SquareProductIntegration,
  SquareTemplateIntegration,
} from '../../../../types/Square.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { DataFields } from '../../../../api/types'

import ListSyncComponent from '../common/ListSyncComponent'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import ListUnlistButtons from '../common/ListUnlistButtons'
import MissingIntegration from '../MissingIntegration'
import NamedSingleSelect from '../../../common/NamedSingleSelect'
import NamedInput from '../../../common/NamedInput'
import MissingTemplateIntegration from '../MissingTemplateIntegration'
import SquareCategorySelect from './SquareCategorySelect'
import Box from '@mui/joy/Box'
import CircularProgress from '@mui/joy/CircularProgress'
import { SquareCatalogObjectChildren } from './SquareIntegrationsComponent'

interface SquareIntegrationComponentProps {
  integration: GetIntegrationResult<IntegrationName.SQUARE>
  templateIntegration: SquareTemplateIntegration
  productIntegration?: SquareProductIntegration
  locations: SquareLocation[]
  categories: SquareCatalogObjectChildren[]
  template: GetProductTemplate
  product?: GetProduct
  onTemplateChange?: (
    name: string,
    templateIntegration: DataFields<SquareTemplateIntegration> | undefined,
  ) => void
  onProductChange?: (
    name: string,
    productIntegration: DataFields<SquareProductIntegration> | undefined,
  ) => void
  onUpdate?: () => void
}

export default function SquareIntegrationComponent({
  integration,
  templateIntegration,
  productIntegration,
  template,
  product,
  locations,
  categories,
  onTemplateChange,
  onProductChange,
  onUpdate,
}: SquareIntegrationComponentProps): JSX.Element {
  const [title, setTitle] = useState<string>(
    productIntegration?.title ?? templateIntegration.title ?? '',
  )
  const [description, setDescription] = useState<string>(
    productIntegration?.description ?? templateIntegration.description ?? '',
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>(
    (
      productIntegration?.categoryIds ?? templateIntegration?.categoryIds
    )?.split(',') || [],
  )
  const [selectedLocation, setSelectedLocation] = useState<
    | {
        id: string
        name: string
      }
    | undefined
  >()

  const [list, setList] = useState<boolean>(
    productIntegration?.list ?? templateIntegration.list ?? false,
  )
  const [sync, setSync] = useState<boolean>(
    productIntegration?.sync ?? templateIntegration.sync ?? false,
  )
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    productIntegration?.syncQuantity ??
      templateIntegration.syncQuantity ??
      false,
  )

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSelectLocation = (locationName: string | undefined) => {
    if (!locationName) return
    const location = locations.find((l) => l.name === locationName)
    if (!location) {
      setSelectedLocation(undefined)
      return
    }
    setSelectedLocation({ id: location.id, name: location.name })
  }

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.SQUARE, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration.integration?.id || !productId) return
    unlistProduct(IntegrationName.SQUARE, productId, integration.integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  useEffect(() => {
    const location = locations.find(
      (l) => l.id === templateIntegration?.locationId,
    )
    if (!location) return
    setSelectedLocation({ id: location?.id, name: location.name })
  }, [templateIntegration, locations])

  // Set title and description state
  useEffect(() => {
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
    setDetailUpdate(detailUpdate + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, template, productIntegration, templateIntegration])

  // const attributeNames =
  //   template?.attributes.map((a) => a.attribute.name) ||
  //   product?.attributes.map((a) => a.templateAttribute.name)

  useEffect(() => {
    if (!integration.integration?.id) return

    if (product) {
      const newProductIntegration: DataFields<SquareProductIntegration> = {
        clientId: integration.integration.clientId,
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        title,
        description,
        locationId: selectedLocation?.id,
        categoryIds: selectedCategoryIds?.length
          ? selectedCategoryIds.join(',')
          : '',
        list,
        sync,
        syncQuantity,
        selected: true,
      }
      onProductChange?.(templateIntegration.name, newProductIntegration)
      return
    }

    if (!product) {
      const newSquareTemplateIntegration: DataFields<SquareTemplateIntegration> =
        {
          clientId: integration.integration.clientId,
          templateId: template.template.id,
          integrationId: integration.integration.id,
          locationId: selectedLocation?.id,
          categoryIds: selectedCategoryIds?.length
            ? selectedCategoryIds.join(',')
            : '',
          title,
          description,
          list,
          sync,
          syncQuantity,
          name: templateIntegration.name,
          index: templateIntegration.index,
        }
      onTemplateChange?.(templateIntegration.name, newSquareTemplateIntegration)
    } else {
      // TODO: Save template on product save for needed fields
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    description,
    list,
    sync,
    syncQuantity,
    selectedLocation,
    selectedCategoryIds,
  ])

  if (!integration) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.SQUARE}
      />
    )
  }

  return (
    <Grid container justifyContent="center" spacing={6} pt={2} px={1}>
      <Grid item xs={12}>
        <ListSyncComponent
          title={true}
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <ProductTitleDescription
              key={`product-title-description-${detailUpdate}`}
              template={template}
              product={product}
              title={title}
              description={description}
              required={!!product}
              onTitleChange={setTitle}
              onDescriptionChange={setDescription}
              disableUnparsedEdit={true}
            />
          </Grid>

          {product && (
            <>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="SKU"
                  value={product.product.sku}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="Condition"
                  value={product.product.condition}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Price"
                  nameEditable={false}
                  value={product.product.price}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Quantity"
                  nameEditable={false}
                  value={product.product.quantity}
                  disabled={true}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <NamedSingleSelect
              key={`select-square-location-${selectedLocation?.id}`}
              name="Square Location"
              value={selectedLocation?.name || ''}
              onChange={(location) => handleSelectLocation(location)}
              options={locations.map((l) => l.name)}
            />
          </Grid>

          <Grid item xs={12}>
            {categories ? (
              <SquareCategorySelect
                label="Category"
                value={selectedCategoryIds}
                categories={categories}
                onSelect={(values) => {
                  console.log(values)
                  setSelectedCategoryIds(values)
                }}
              />
            ) : (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ListUnlistButtons
          title={true}
          list={list}
          productIntegration={productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
