import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'
import DisconnectIntegration from '../../components/integrations/DisconnectIntegration'
import LockResetIcon from '@mui/icons-material/LockReset'
import InputContainer from '../../components/common/InputContainer'

export default function CloverIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.CLOVER>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* Authentication */}
        <Grid container justifyContent="center" spacing={3}>
          {integration.authenticated ? (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                p={2}
                sx={{
                  border: '1px solid #e8e9eb',
                  borderRadius: '6px',
                  boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
                  color: '#32383E',
                  backgroundColor: '#FBFCFE',
                }}
              >
                <Grid item xs={12}>
                  <Typography level="title-lg">Store Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography level="body-sm">
                    Merchant ID:{' '}
                    {integration.integration?.shop || 'Store name not set.'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {integration.authenticated ? (
            <>
              <Grid item xs={12}>
                <InputContainer>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                      <Typography level="title-lg">Authentication</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        spacing={1}
                        direction="column"
                      >
                        <Grid item>
                          <Button
                            variant="solid"
                            onClick={handleAppStoreClick}
                            endDecorator={<LockResetIcon />}
                          >
                            Re-Authorize
                          </Button>
                        </Grid>
                        <Grid item>
                          <Typography level="body-xs">
                            Authentication Last Updated:{' '}
                            {integration.integration?.updatedAt
                              ? new Date(
                                  integration.integration?.updatedAt,
                                ).toLocaleString()
                              : 'Unknown'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </InputContainer>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <InputContainer>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                      <Typography level="title-lg">Authentication</Typography>
                      <Typography level="body-sm">
                        To authenticate app access, please authorize the
                        Sellmotto app on your Clover Store.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="solid" onClick={handleAppStoreClick}>
                        Open
                      </Button>
                    </Grid>
                  </Grid>
                </InputContainer>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {integration?.integration && integration?.authenticated ? (
        <Grid item xs={12}>
          <DisconnectIntegration
            integration={integration.integration}
            channel={IntegrationName.BIG_COMMERCE}
            setAlert={setAlert}
          />
        </Grid>
      ) : undefined}
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
