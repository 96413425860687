import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/joy/CircularProgress'

export default function LoadingBox(): JSX.Element {
  return (
    <Box p={4}>
      <CircularProgress></CircularProgress>
    </Box>
  )
}
