import React from 'react'
import Button from '@mui/joy/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface ModalProps {
  open: boolean
  title?: string
  text?: string
  children?: React.ReactNode
  onClose?: () => void
  onSubmit?: () => void
}

export default function Modal({
  open,
  title = '',
  text,
  children,
  onClose,
  onSubmit = () => {
    onClose?.()
  },
}: ModalProps): JSX.Element {
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = () => {
    onSubmit()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {text ? <DialogContentText>{text}</DialogContentText> : null}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
