import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { Link } from 'react-router-dom'

export default function MissingTemplateIntegration({
  templateId,
  channelName,
}: {
  templateId: number
  channelName: string
}): JSX.Element {
  return (
    <Box p={8}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography level="title-lg">Missing Template Integration</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Configure Integration:{' '}
            <Link to={`/template/${templateId}/channels/${channelName}`}>
              Template Integration
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
