import instance from '..'
import { APIResult } from '../../utils/types'
import { getResult } from '../../utils/functions'
import {
  CloverCategory,
  CloverInventoryItem,
  CloverOrder,
  CloverProductIntegration,
  CloverTemplateIntegration,
} from '../../types/Clover.types'
import { DataFields, Integration } from '../types'

export function getCloverCategories(): Promise<APIResult<CloverCategory[]>> {
  return instance
    .get('/api/integration/clover/categories')
    .then((res) => getResult(res))
}

export async function cloverBulkImport(params?: {
  ids?: string[]
  template?: string
  updateExistingProducts?: boolean
}): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/integration/clover/bulkImport', params)
    .then((res) => getResult<void>(res))
  return result
}

export interface GetCloverProducts {
  items: CloverInventoryItem[]
}

export async function getCloverItems(params: {
  offset?: number
  limit?: number
}): Promise<APIResult<GetCloverProducts>> {
  const result = await instance
    .get('/api/integration/clover/items', {
      params,
    })
    .then((res) => getResult<GetCloverProducts>(res))
  return result
}

export type GetCloverIntegrationsResult = {
  integration?: Integration
  templateIntegration?: CloverTemplateIntegration
  productIntegration?: CloverProductIntegration
}
export async function getCloverIntegrations(
  integrationId: number,
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetCloverIntegrationsResult>> {
  const ebayTemplateIntegration = await instance
    .get('/api/integration/clover/integrations', {
      params: {
        integrationId,
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetCloverIntegrationsResult>(res))
  return ebayTemplateIntegration
}

export async function setCloverTemplateIntegration(
  templateIntegration?: DataFields<Omit<CloverTemplateIntegration, 'clientId'>>,
): Promise<APIResult<CloverTemplateIntegration>> {
  const setTemplateIntegration = await instance
    .post('/api/integration/clover/templateIntegration', {
      templateIntegration,
    })
    .then((res) => getResult<CloverTemplateIntegration>(res))
  return setTemplateIntegration
}

export async function setCloverProductIntegration(
  productIntegration?: DataFields<Omit<CloverProductIntegration, 'clientId'>>,
): Promise<APIResult<CloverProductIntegration>> {
  const setProductIntegration = await instance
    .post('/api/integration/clover/productIntegration', {
      productIntegration,
    })
    .then((res) => getResult<CloverProductIntegration>(res))
  return setProductIntegration
}

export async function getCloverOrders(): Promise<APIResult<CloverOrder[]>> {
  const result = await instance
    .get('/api/integration/clover/orders')
    .then((res) => getResult<CloverOrder[]>(res))
  return result
}
