import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { Link } from 'react-router-dom'

export default function MissingIntegration(): JSX.Element {
  return (
    <Box p={8}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography level="title-lg">Missing Integration</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Configure Integration: <Link to="/integrations">Integrations</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
