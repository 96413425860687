export const isValidEmail = (email: string): boolean => {
  if (!email) return false
  const validEmail = email
    ? email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null
    : true
  return validEmail
}

export const isValidPassword = (pw: string): boolean => {
  const MIN_PASSWORD_LENGTH = 8
  const validPassword = pw
    ? /[A-Z]/.test(pw) &&
      /[a-z]/.test(pw) &&
      /[0-9]/.test(pw) &&
      /[^A-Za-z0-9]/.test(pw) &&
      pw.length > MIN_PASSWORD_LENGTH
    : true
  return validPassword
}

export const passwordsMatch = (pw: string, checkPw: string): boolean => {
  return pw === checkPw
}
