import React, { useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import NavBar from '../../components/common/NavBar'
import ClientSelect from '../../components/admin/ClientSelect'
import CreateClient from '../../components/admin/CreateClient'
import { getClient } from '../../api/admin'
import Container from '@mui/material/Container'
import { err, log } from '../../utils/functions'
import AppContext from '../../app/AppContext'
import { Client } from '../../api/types'

export default function Clients(): JSX.Element {
  const { appState } = useContext(AppContext)
  // const roleId = appState.user.roleId
  const clientId = appState.client.id
  const [client, setClient] = useState<Client>()
  log(client)
  useEffect(() => {
    if (clientId) {
      getClient({ id: clientId })
        .then((res) => {
          if (res?.data) {
            setClient(res.data)
          }
        })
        .catch((e) => err(e))
    }
  }, [clientId])

  if (!clientId) {
    return <div></div>
  }

  // TODO snackbar alert handling
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            padding: '1em',
          }}
        >
          {!clientId && (
            <>
              {/* <Grid item xs={12} md={4}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <AllClients />
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <ClientSelect />
                  </Grid>

                  <Grid item xs={12}>
                    <CreateClient />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  )
}
