import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import {
  GetIntegrationResult,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { getDescriptionValue, getTitleValue } from '../../../../utils/functions'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { DataFields } from '../../../../api/types'

import ListSyncComponent from '../common/ListSyncComponent'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import ListUnlistButtons from '../common/ListUnlistButtons'
import MissingIntegration from '../MissingIntegration'
import NamedSingleSelect from '../../../common/NamedSingleSelect'
import NamedInput from '../../../common/NamedInput'
import MissingTemplateIntegration from '../MissingTemplateIntegration'
import {
  CloverCategory,
  CloverProductIntegration,
  CloverTemplateIntegration,
} from '../../../../types/Clover.types'

interface CloverIntegrationComponentProps {
  integration: GetIntegrationResult<IntegrationName.CLOVER>
  templateIntegration: CloverTemplateIntegration
  productIntegration?: CloverProductIntegration
  categories: CloverCategory[]
  template: GetProductTemplate
  product?: GetProduct
  onTemplateChange?: (
    name: string,
    templateIntegration: DataFields<CloverTemplateIntegration> | undefined,
  ) => void
  onProductChange?: (
    name: string,
    productIntegration: DataFields<CloverProductIntegration> | undefined,
  ) => void
  onUpdate?: () => void
}

export default function CloverIntegrationComponent({
  integration,
  templateIntegration,
  productIntegration,
  template,
  product,
  categories,
  onTemplateChange,
  onProductChange,
  onUpdate,
}: CloverIntegrationComponentProps): JSX.Element {
  const [title, setTitle] = useState<string>(
    productIntegration?.title ?? templateIntegration.title ?? '',
  )
  const [description, setDescription] = useState<string>(
    productIntegration?.description ?? templateIntegration.description ?? '',
  )
  const [selectedCategory, setSelectedCategory] = useState<
    | {
        id: string
        name: string
      }
    | undefined
  >()

  const [selectedLocation] = useState<{
    name: string
    id: number
  }>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [color, setColor] = useState<string | undefined>(
    templateIntegration?.color,
  )
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [list, setList] = useState<boolean>(
    productIntegration?.list ?? templateIntegration.list ?? false,
  )
  const [sync, setSync] = useState<boolean>(
    productIntegration?.sync ?? templateIntegration.sync ?? false,
  )
  const [syncQuantity, setSyncQuantity] = useState<boolean>(
    productIntegration?.syncQuantity ??
      templateIntegration.syncQuantity ??
      false,
  )

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.CLOVER, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration.integration?.id || !productId) return
    unlistProduct(IntegrationName.CLOVER, productId, integration.integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  const handleSelectCategory = (categoryName: string | undefined) => {
    const category = categories.find((c) => c.name === categoryName)
    if (!category?.id) {
      setSelectedCategory(undefined)
      return
    }
    setSelectedCategory({ id: category.id, name: category.name })
  }

  // Set title and description state
  useEffect(() => {
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
    setDetailUpdate(detailUpdate + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, template, productIntegration, templateIntegration])

  useEffect(() => {
    if (!integration.integration?.id) return

    if (product) {
      const newCloverProductIntegration: DataFields<CloverProductIntegration> =
        {
          templateIntegrationId: templateIntegration?.id,
          productId: product.product.id,
          title,
          description,
          // categoryId: selectedCategory?.id,
          list,
          sync,
          syncQuantity,
          selected: true,
        }
      onProductChange?.(templateIntegration.name, newCloverProductIntegration)
      return
    }

    if (!product) {
      const newCloverTemplateIntegration: DataFields<CloverTemplateIntegration> =
        {
          integrationId: integration.integration.id,
          templateId: template?.template.id,
          title: title || '',
          description: description || '',
          categoryId: selectedCategory?.id,
          list,
          sync,
          syncQuantity,
          name: templateIntegration.name,
          index: templateIntegration.index,
        }
      onTemplateChange?.(templateIntegration.name, newCloverTemplateIntegration)
    } else {
      // TODO: Save template fields needed on product save
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    description,
    list,
    sync,
    syncQuantity,
    selectedLocation,
    // selectedCategoryIds,
  ])

  // const handleSave = async () => {
  //   // Add New Collections
  //   let allCollectionIds = categoryIds
  //   if (addCollectionTitles?.length) {
  //     await createCloverCollections(addCollectionTitles)
  //       .then((res) => {
  //         if (res.success && res.data) {
  //           // Add created categories
  //           setCollections((categories || []).concat(res.data)) // add created to categories
  //           setAddCollectionTitles([]) // clear add
  //           setSelectedCollections(selectedCollections.concat(res.data)) // set as selected

  //           const newCollectionIds = res.data.map((c) => `${c.id}`)
  //           allCollectionIds = allCollectionIds
  //             .split(',')
  //             .concat(newCollectionIds)
  //             .join(',')
  //         }
  //       })
  //       .catch((e) => err(e))
  //   }
  // }

  if (!integration) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.CLOVER}
      />
    )
  }

  return (
    <Grid container justifyContent="center" spacing={6} pt={2} px={1}>
      <Grid item xs={12}>
        <ListSyncComponent
          title={true}
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <ProductTitleDescription
              template={template}
              product={product}
              title={title}
              required={!!product}
              onTitleChange={setTitle}
              disableUnparsedEdit={true}
            />
          </Grid>

          {product && (
            <>
              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="SKU"
                  value={product.product.sku}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <ProductAttributeInputText
                  label="Condition"
                  value={product.product.condition}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Price"
                  nameEditable={false}
                  value={product.product.price}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <NamedInput
                  name="Quantity"
                  nameEditable={false}
                  value={product.product.quantity}
                  disabled={true}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <NamedSingleSelect
              nameContainer={true}
              key={`select-clover-category-${selectedCategory?.id}`}
              name="Category"
              value={selectedCategory?.name || ''}
              onChange={(c) => handleSelectCategory(c)}
              options={categories.map((c) => c.name)}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={1} mt={3}>
              <Grid item xs={12} md={6} sx={{ m: 'auto' }}>
                <Typography level="h4">Color</Typography>
                <Typography level="body-xs">Clover Item Color</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="center">
                  <ColorPicker value={color} onChange={setColor} />
                  <Grid item xs={12} sx={{ mt: 1 }}>
                    <Typography level="body-xs"> Color: {color}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ListUnlistButtons
          title={true}
          list={list}
          productIntegration={productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
