import instance from '.'
import { err, getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import { Client, Role, User } from './types'

export async function createAccount(
  email: string,
  hashPass: string,
  hashCheckPass: string,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/createAccount', {
      email,
      password: hashPass,
      checkPassword: hashCheckPass,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function setUser(
  user: Partial<User>,
): Promise<APIResult<Partial<User>>> {
  return instance
    .post('/api/account/user', { user })
    .catch((e) => err(e))
    .then((res) => getResult<Partial<User>>(res))
}

export async function getUser(
  userId: number,
): Promise<APIResult<Partial<User>>> {
  return instance
    .get('/api/account/user', {
      params: {
        userId,
      },
    })
    .catch((e) => err(e))
    .then((res) => getResult<Partial<User>>(res))
}

export type RoleClient = Role & Pick<Client, 'name' | 'displayName'>
export async function getUserRoles(
  userId: number,
): Promise<APIResult<RoleClient[]>> {
  return instance
    .get('/api/account/userRoles', {
      params: {
        userId,
      },
    })
    .catch((e) => err(e))
    .then((res) => getResult<RoleClient[]>(res))
}

export async function acceptRole(
  userId: number,
  clientId: number,
): Promise<APIResult<Role>> {
  return instance
    .post('/api/account/acceptRole', {
      userId,
      clientId,
    })
    .catch((e) => err(e))
    .then((res) => getResult<Role>(res))
}

export async function declineRole(
  userId: number,
  clientId: number,
): Promise<APIResult<void>> {
  return instance
    .post('/api/account/declineRole', {
      userId,
      clientId,
    })
    .catch((e) => err(e))
    .then((res) => getResult<void>(res))
}

export async function sendConfirmationEmail(): Promise<APIResult<void>> {
  return instance
    .post('/api/account/sendConfirmationEmail')
    .catch((e) => err(e))
    .then((res) => getResult<void>(res))
}

export async function confirmEmail(
  uuid: string,
  code: string,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/confirmEmail', {
      uuid,
      code,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function resetPassword(
  uuid: string,
  code: string,
  password: string,
  checkPassword: string,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/resetPassword', {
      uuid,
      code,
      password,
      checkPassword,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function sendResetPasswordEmail(
  email: string,
): Promise<APIResult<void>> {
  return instance
    .post('/api/account/sendResetPasswordEmail', {
      email,
    })
    .catch((e) => err(e))
    .then((res) => getResult<void>(res))
}

export async function login(
  email: string,
  hashPass: string,
  accessToken?: string,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/login', {
      email,
      password: hashPass,
      accessToken,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function logout(): Promise<APIResult<string>> {
  return instance
    .post('/api/account/logout')
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function clientLogin(
  clientId: number,
): Promise<APIResult<string>> {
  return instance
    .post('/api/account/clientLogin', {
      clientLoginId: clientId,
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}

export async function getUserClients(): Promise<APIResult<Client[]>> {
  return instance
    .get('/api/account/getUserClients')
    .catch((e) => err(e))
    .then((res) => getResult<Client[]>(res))
}

export async function createClient(
  name: string,
  displayName: string,
  description?: string,
): Promise<APIResult<Client>> {
  return await instance
    .post('/api/account/createClient', {
      name,
      displayName,
      description,
    })
    .then((res) => getResult<Client>(res))
}
