import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { useHistory } from 'react-router-dom'
import { resetPassword } from '../../api/account'
import { err, sha256, wait } from '../../utils/functions'
import qs from 'qs'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import LabelInput from '../../components/common/LabelInput'
import Component from '../../components/common/Component'

export default function ResetPassword(): JSX.Element {
  const { uuid: uuidParam, code: codeParam } = qs.parse(
    window.location.search,
    {
      ignoreQueryPrefix: true,
    },
  ) as { [k: string]: string }

  const history = useHistory()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [password, setPassword] = useState<string>()
  const [checkPassword, setCheckPassword] = useState<string>()

  const handleReturn = () => {
    history.push('/login')
  }

  useEffect(() => {
    if (!codeParam) {
      history.push('/forgotPassword')
    }
  }, [codeParam, history])

  const handleSendResetLink = async () => {
    if (!uuidParam || !codeParam || !password || !checkPassword) return

    const [hashPass, hashCheckPass] = await Promise.all([
      sha256(password),
      sha256(checkPassword),
    ])

    resetPassword(uuidParam, codeParam, hashPass, hashCheckPass)
      .then(async (res) => {
        handleAlert(
          setAlert,
          res,
          'Successfully reset password. Redirecting to login',
        )

        if (res.success) {
          await wait(2000)
          history.push('/login')
        }
      })
      .catch((e) => {
        err(e)
      })
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          xs={12}
          md={6}
          lg={6}
          sx={{
            marginTop: '6em',
          }}
        >
          <Component p={3}>
            <form
              onSubmit={async (e) => {
                e.preventDefault()
                await handleSendResetLink()
              }}
            >
              <Typography level="h3" component="h1">
                Reset Password
              </Typography>

              <Box style={{ paddingTop: '1em', paddingBottom: '2em' }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <LabelInput
                      label="New Password"
                      type="password"
                      fullWidth
                      required
                      value={password}
                      onChange={setPassword}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LabelInput
                      label="Confirm New Password"
                      type="password"
                      fullWidth
                      required
                      value={checkPassword}
                      onChange={setCheckPassword}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="solid"
                    color="primary"
                    onClick={handleReturn}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="solid" color="primary" type="submit">
                    Reset Password
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Component>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </>
  )
}
