import React, { useState } from 'react'
import Autocomplete from '@mui/joy/Autocomplete'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Typography from '@mui/joy/Typography'
import FormHelperText from '@mui/joy/FormHelperText'

interface Option {
  source?: string
  value: string
}

interface MultipleGroupSelectProps {
  label?: string
  placeholder?: string
  value: Option[]
  size?: 'sm' | 'md' | 'lg'
  options?: Option[]
  freeSolo?: boolean
  noSpacedInputs?: boolean
  errorText?: boolean
  helperText?: boolean
  endDecorator?: React.ReactNode
  autoHighlight?: boolean
  onChange?: (values: string[]) => void
}
export default function MultipleGroupSelect({
  label,
  placeholder,
  value,
  size,
  options,
  freeSolo,
  noSpacedInputs,
  errorText,
  helperText,
  endDecorator,
  autoHighlight,
  onChange,
}: MultipleGroupSelectProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')

  const handleInputValueChange = (inputValue: string) => {
    setInputValue(inputValue)
  }

  const uniqueValues = value?.reduce((acc, item) => {
    if (!acc.some((e) => e.value === item.value)) {
      acc.push(item)
    }
    return acc
  }, [] as Option[])

  return (
    <FormControl>
      {label ? (
        <FormLabel>
          <Typography level="title-sm">
            <strong>{label}</strong>
          </Typography>
        </FormLabel>
      ) : null}
      <Autocomplete
        value={uniqueValues}
        options={options || []}
        groupBy={(o) => o.source || 'Selected'}
        getOptionLabel={(o) => (typeof o === 'string' ? o : o.value)}
        freeSolo={freeSolo}
        multiple
        size={size}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          handleInputValueChange(value)
        }}
        autoHighlight={autoHighlight}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={(e, values) => {
          onChange?.(values.map((v) => (typeof v === 'string' ? v : v.value)))
        }}
        placeholder={placeholder || (!value.length ? label : '')}
        defaultValue={value}
        endDecorator={endDecorator}
      />
      {errorText || helperText ? (
        <FormHelperText>{errorText || helperText}</FormHelperText>
      ) : null}
    </FormControl>
  )
}
