import React, { useEffect, useMemo, useState } from 'react'
import {
  ProductTemplateAttributeInput,
  SetProductAttribute,
  updateProductTemplateAttributes,
} from '../../../api/product'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Autocomplete, { createFilterOptions } from '@mui/joy/Autocomplete'
import { RequiredIntegrationTemplateAttributes } from '../../../api/integrations/ebay'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../utils/constants'
import { ProductTemplateAttribute } from '../../../api/types'
import { copy, unique } from '../../../utils/functions'
import FormControl from '@mui/joy/FormControl'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import ListItemContent from '@mui/joy/ListItemContent'
import Tooltip from '@mui/joy/Tooltip'
import IconButton from '@mui/joy/IconButton'
import Add from '@mui/icons-material/Add'
import {
  IntegrationDisplayName,
  IntegrationName,
} from '../../../api/integration'
import FormHelperText from '@mui/joy/FormHelperText'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import MultipleGroupSelect from '../../../components/common/MultipleGroupSelect'
import SvgIcon from '@mui/joy/SvgIcon'
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt'
import FloatLabelInput from '../../../components/common/FloatLabelInput'
import ChecklistRtl from '@mui/icons-material/ChecklistRtl'
import VerticalIconMenu from '../../../components/common/VerticalIconMenu'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

interface OptionValue {
  value: string
  source: OptionGroupsEnum | string
}

enum OptionGroupsEnum {
  OPTION = 'Template Options',
  EBAY_ETSY = 'Ebay & Etsy Values',
  EBAY = 'Ebay Values',
  ETSY = 'Etsy Values',
}
interface ProductAttributeInputFieldProps {
  id?: string
  name: string
  value?: string
  displayPlaceholder?: boolean
  attributes: SetProductAttribute[]
  options?: Record<string, string[] | undefined>
  valueMapping?: Record<string, Record<string, string[] | undefined>>
  attributeMapping?: Record<string, string | undefined>
  onValueMappingChange?: (
    channel: IntegrationName,
    value: string,
    values: string[],
  ) => void
  onChange: (name: string, value: string) => void
  onSelection?: (next?: boolean) => void
  onUpdate?: () => void
  disabled?: boolean
  required?: boolean
  requiredAttributes?: RequiredIntegrationTemplateAttributes[]
}

const OPTIONS_DISPLAY_LIMIT = 250

export default function ProductAttributeInputField({
  id,
  name,
  value: defaultValue,
  displayPlaceholder,
  attributes,
  options,
  valueMapping,
  attributeMapping,
  onValueMappingChange,
  onChange,
  onSelection,
  onUpdate,
  required = false,
  disabled = false,
  requiredAttributes,
}: ProductAttributeInputFieldProps): JSX.Element | null {
  const [valueMapped, setValueMapped] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [searchValueMapping, setSearchValueMapping] = useState<string>('')
  const [focused, setFocused] = useState<boolean>(false)
  const [etsyValueMapText, setEtsyValueMapText] = useState<
    React.ReactNode | undefined
  >()
  const [ebayValueMapText, setEbayValueMapText] = useState<
    React.ReactNode | undefined
  >()

  const getAttribute = (
    attributes: SetProductAttribute[],
    name: string,
  ): SetProductAttribute | undefined => {
    const attribute = attributes.find(
      (attribute) => attribute.templateAttribute.name === name,
    )
    if (!attribute) return undefined
    return {
      attribute: attribute.attribute,
      templateAttribute: attribute.templateAttribute,
      templateAttributeOptions: attribute?.templateAttributeOptions,
    }
  }

  const getRequiredAttribute = (
    requiredAttributes: RequiredIntegrationTemplateAttributes[] | undefined,
    name: string,
  ): RequiredIntegrationTemplateAttributes | undefined => {
    return requiredAttributes?.find((reqAttribute) =>
      reqAttribute.templateAttributeNames.includes(name),
    )
  }

  const [requiredAttribute, setRequiredAttribute] = useState<
    RequiredIntegrationTemplateAttributes | undefined
  >()
  const [attribute, setAttribute] = useState<SetProductAttribute | undefined>()
  const [value, setValue] = useState<string>(defaultValue || '')
  const [displayValue, setDisplayValue] = useState<string>(defaultValue || '')
  const [requiredTip, setRequiredTip] = useState<
    string | React.ReactNode | undefined
  >(undefined)
  const [warningTip, setWarningTip] = useState<
    string | React.ReactNode | undefined
  >(undefined)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [warningText, setWarningText] = useState<
    string | React.ReactNode | undefined
  >(undefined)

  const [valueMappingWarning, setValueMappingWarning] = useState<
    string | React.ReactNode | undefined
  >(undefined)

  const [valueMappingError, setValueMappingError] = useState<
    string | React.ReactNode | undefined
  >(undefined)

  const [optionValues, setOptionValues] = useState<OptionValue[]>([])

  const [valueMapModalOpen, setValueMapModalOpen] = useState<boolean>(false)
  const [valueMapSelectedTab, setValueMapSelectedTab] = useState<string>('ebay')

  useMemo(() => {
    const attributeOptionValues = attribute?.templateAttributeOptions
      ? attribute?.templateAttributeOptions
          .map((o) => ({ value: o.value, source: OptionGroupsEnum.OPTION }))
          .sort((a, b) => a.value.localeCompare(b.value))
      : []

    const filteredEbayOptions = options?.['ebay'] || [] // ?.slice(0, 50) || []
    const filteredEtsyOptions = options?.['etsy'] || [] //?.slice(0, 50) || []

    const ebayEtsyOptions: string[] = []
    const ebayOptions: string[] = []
    const etsyOptions: string[] = []
    filteredEbayOptions &&
      filteredEtsyOptions &&
      unique(filteredEbayOptions.concat(filteredEtsyOptions)).forEach((v) => {
        const inEbay = filteredEbayOptions?.includes(v)
        const inEtsy = filteredEtsyOptions?.includes(v)

        if (attributeOptionValues.find((t) => t.value === v)) {
          return
        }
        if (inEbay && inEtsy) {
          ebayEtsyOptions.push(v)
        } else if (inEbay) {
          ebayOptions.push(v)
        } else if (inEtsy) {
          etsyOptions.push(v)
        }
      })

    const ebayEtsyGroupedOptions =
      ebayEtsyOptions.map((ebayOption) => ({
        value: ebayOption,
        source: OptionGroupsEnum.EBAY_ETSY,
      })) || []

    const ebayGroupedOptions =
      ebayOptions.map((ebayOption) => ({
        value: ebayOption,
        source: OptionGroupsEnum.EBAY,
      })) || []

    const etsyGroupedOptions =
      etsyOptions.map((ebayOption) => ({
        value: ebayOption,
        source: OptionGroupsEnum.ETSY,
      })) || []

    setOptionValues(
      attributeOptionValues
        .concat(ebayEtsyGroupedOptions)
        .concat(ebayGroupedOptions)
        .concat(etsyGroupedOptions),
    )
  }, [attribute?.templateAttributeOptions, options])

  useEffect(() => {
    const newAttr = getAttribute(attributes, name)
    setAttribute(newAttr)
    if (!defaultValue && !displayPlaceholder) {
      setValue(newAttr?.attribute?.value || '')
    }
    if (displayPlaceholder) {
      setDisplayValue(newAttr?.attribute?.value || '')
    }
  }, [name, defaultValue, attributes, displayPlaceholder])
  useEffect(() => {
    setRequiredAttribute(getRequiredAttribute(requiredAttributes, name))
  }, [requiredAttributes, name])

  useEffect(() => {
    setValueMapped(false)
    setEbayValueMapText(undefined)
    setEtsyValueMapText(undefined)
    setValueMappingError('')
    setValueMappingWarning('')
    Object.entries(options || {}).forEach(([channel, channelOptions]) => {
      if (!channelOptions?.length || !value || warningTip) return

      // value map value
      const matchValueMapping = Object.entries(
        valueMapping?.[channel] || {},
      ).find(([mapValue, values]) =>
        values
          ?.map((v) => v.toLocaleLowerCase())
          ?.includes(value.toLocaleLowerCase()),
      )

      let useValue = value
      if (matchValueMapping) {
        useValue = matchValueMapping[0]

        setValueMapped(true)
        if (channel === IntegrationName.EBAY) {
          setEbayValueMapText(
            <Typography level="body-sm" sx={{ color: 'inherit' }}>
              <strong>{IntegrationDisplayName[channel]}</strong>: Mapping from{' '}
              <strong>{value}</strong> to <strong>{useValue}</strong>
            </Typography>,
          )
        } else {
          setEtsyValueMapText(
            <Typography level="body-sm" sx={{ color: 'inherit' }}>
              <strong>{IntegrationDisplayName[channel]}</strong>: Mapping from{' '}
              <strong>{value}</strong> to <strong>{useValue}</strong>
            </Typography>,
          )
        }
      }

      const mappedToField = attributeMapping?.[channel]
      const isRequired =
        required ||
        (channel === IntegrationName.EBAY &&
          requiredAttribute?.aspect?.required)

      const valueNotInOptions = channelOptions?.includes(useValue)
      if (!valueNotInOptions && isRequired) {
        setValueMappingError(
          <Typography level="body-sm" sx={{ color: 'inherit' }}>
            <strong>{value}</strong> is not an allowed value for{' '}
            {IntegrationDisplayName[channel]}{' '}
            <span style={{ textDecoration: 'underline' }}>required</span> field
            {mappedToField ? (
              <span>
                {' '}
                <strong>{mappedToField}</strong>
              </span>
            ) : (
              ''
            )}
          </Typography>,
        )
      } else if (!valueNotInOptions && !isRequired) {
        setValueMappingWarning(
          <Typography level="body-sm" sx={{ color: 'inherit' }}>
            <strong>{value}</strong> is not an allowed value for{' '}
            {IntegrationDisplayName[channel]}
            {mappedToField ? (
              <span>
                {' '}
                <strong>{mappedToField}</strong>
              </span>
            ) : (
              ''
            )}
          </Typography>,
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value, defaultValue])

  useEffect(() => {
    if (
      !value &&
      requiredAttribute?.aspect &&
      requiredAttribute.aspect?.required
    ) {
      const requiredTipValue = (
        <Typography level="body-sm" sx={{ color: 'inherit' }}>
          <strong>Required: </strong> Used in Ebay Item Specific{' '}
          <strong>{requiredAttribute.aspect.aspectName}</strong>
        </Typography>
      )
      setRequiredTip(requiredTipValue)
    } else if (!value && required) {
      setRequiredTip(
        <Typography level="body-sm" sx={{ color: 'inherit' }}>
          <strong>Required Value</strong>
        </Typography>,
      )
    } else if (value) {
      setRequiredTip('')
    }
  }, [requiredAttribute, value, required])

  useEffect(() => {
    if (
      !value &&
      requiredAttribute?.aspect &&
      !requiredAttribute.aspect.required
    ) {
      const warningTipValue = (
        <Typography level="body-sm" sx={{ color: 'inherit' }}>
          <strong>Recommended: </strong>Used in Ebay{' '}
          <strong>{requiredAttribute.aspect.aspectName}</strong>
        </Typography>
      )
      setWarningTip(warningTipValue)
    } else if (!value && requiredAttribute) {
      const warningTipValue = (
        <Typography level="body-sm" sx={{ color: 'inherit' }}>
          <strong>Recommended: </strong> Used in Title or Description
        </Typography>
      )
      setWarningTip(warningTipValue)
    } else if (value) {
      setWarningTip('')
    }
  }, [requiredAttribute, value])

  const handleChange = (value: string) => {
    setValue(value)
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      onChange(name, value)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      onSelection?.(true)
    } else if (e.shiftKey && e.key === 'Enter') {
      onSelection?.(false)
    }
  }

  const handleAddTemplateAttributeOption = async (option: string) => {
    if (!attribute) return
    // Get template id from template attribute
    const templateId = attribute.templateAttribute.templateId
    const templateAttributeName = attribute.templateAttribute.name

    const templateAttribute = attribute.templateAttribute
    const newOptionIndex = attribute.templateAttributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with option
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: templateAttribute,
      attributeOptions: attribute.templateAttributeOptions.concat({
        value: option,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      }),
    }

    const newAttribute = copy(attribute)
    newAttribute.templateAttribute =
      addTemplateAttr.attribute as ProductTemplateAttribute
    newAttribute.templateAttributeOptions = addTemplateAttr.attributeOptions
    setAttribute(newAttribute)
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success) {
      console.log('success')
    } else {
      console.log('Error')
    }
    onUpdate?.()
  }

  // let priorityText = ''
  // if (attribute?.templateAttribute.constraint === 'required') {
  //   priorityText = ' (required)'
  // } else if (attribute?.templateAttribute.constraint === 'recommended') {
  //   priorityText = ' (recommended)'
  // }

  let helperTextColor: 'danger' | 'warning' | undefined = undefined
  if (warningText) {
    helperTextColor = 'warning'
  }

  let usePlaceholder = displayValue || name
  if (optionValues.length > OPTIONS_DISPLAY_LIMIT && !value && focused) {
    usePlaceholder = 'Type to search values...'
  }

  // setWarningTip(warningMsgs?.[0] || '')
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={5} sm={4} md={3} lg={2} sx={{ position: 'relative' }}>
        <Typography
          sx={{
            border: '1px solid #e8e9eb',
            lineHeight: '34px',
            px: '8px',
            borderRadius: '6px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textAlign: 'left',
            boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
            color: '#32383E',
            backgroundColor: '#FBFCFE',
          }}
        >
          <strong title={name}>{name}</strong>
        </Typography>

        {/* <LabelInput
          // sx={{
          //   border: '1px solid #CDD7E1',
          //   p: '4px',
          //   borderRadius: '6px',
          //   mt: '0.6px',
          //   textOverflow: 'ellipsis',
          //   whiteSpace: 'nowrap',
          //   overflow: 'hidden',
          //   textAlign: 'left',
          // }}
          disabled={true}
          value={name}
        ></LabelInput> */}
        {/* <Box sx={{ position: 'absolute', top: '1em', left: '1em', zIndex: 2 }}>
   
        </Box> */}
      </Grid>
      <Grid item xs={7} sm={8} md={9} lg={10}>
        <FormControl>
          <Autocomplete
            required={required}
            openOnFocus={true}
            selectOnFocus={true}
            autoSelect={false}
            clearOnEscape={true}
            id={id}
            value={value}
            options={optionValues}
            filterOptions={createFilterOptions({
              matchFrom: 'any',
              limit: OPTIONS_DISPLAY_LIMIT,
            })}
            groupBy={(option) => option.source}
            freeSolo
            limitTags={5}
            disabled={disabled}
            // onSelect={() => {}}
            onChange={(e, v: OptionValue | string | null) => {
              if (!v) {
                setValue('')
                return
              }
              if (v && typeof v !== 'string') {
                const optionValue = v as OptionValue
                setValue(optionValue.value)
                onChange(name, optionValue.value)
                onSelection?.()
                setRequiredTip('')
                setWarningTip('')
              }
            }}
            onInput={(e) => {
              handleChange((e.target as HTMLInputElement).value)
            }}
            onFocus={() => {
              setFocused(true)
            }}
            onBlur={() => {
              // onChange(name, value)
              setFocused(false)
            }}
            isOptionEqualToValue={(
              option: OptionValue,
              value: OptionValue | string,
            ) => {
              if (typeof value === 'string') {
                return value === option.value
              }
              if (value.value === option.value) {
                return true
              }
              return false
            }}
            // renderGroup={(params) => (
            //   <li key={params.key}>
            //     <GroupHeader>{params.group}</GroupHeader>
            //     <GroupItems>{params.children}</GroupItems>
            //   </li>
            // )}
            getOptionLabel={(option: OptionValue | string) => {
              if (typeof option !== 'string') {
                return (option as OptionValue).value
              } else {
                return option
                // (optionValues.length > 100 ? '( Type to Search...)' : '')
              }
            }}
            renderOption={(props, option: OptionValue) => (
              <AutocompleteOption {...props}>
                {/* <ListItemDecorator>
                </ListItemDecorator> */}
                <ListItemContent sx={{ fontSize: 'sm' }}>
                  {option.value}
                  {option.source !== OptionGroupsEnum.OPTION ? (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        right: '1em',
                        top: '0em',
                      }}
                      size="sm"
                      variant="plain"
                      onClick={(e) => {
                        e.stopPropagation()
                        void handleAddTemplateAttributeOption(option.value)
                      }}
                    >
                      <Add fontSize="small" color="action" />
                    </IconButton>
                  ) : null}
                </ListItemContent>
              </AutocompleteOption>
            )}
            placeholder={usePlaceholder}
            inputValue={value}
            color={helperTextColor}
            onKeyDown={(e) => handleKeyPress(e)}
            // onInputChange={(e) => {
            //   console.log('inInputChange')
            //   const value = (e.target as HTMLInputElement).value
            //   setValue(value)
            // }}
            slots={{
              clearIndicator: () => <></>,
              popupIndicator: () => <></>,
            }}
            slotProps={{
              root: {
                sx: {
                  pr:
                    valueMapping ||
                    options?.[IntegrationName.EBAY]?.length ||
                    options?.[IntegrationName.ETSY]?.length
                      ? '2px'
                      : undefined,
                },
              },
            }}
            // renderInput={(a) => (
            //   <LabelInput
            //     {...a}
            //     fullWidth
            //     value={value}
            //     // color={warningTip ? 'warning' : undefined}
            //     onKeyDown={(e) => handleKeyPress(e)}
            //     onChange={(v) => {
            //       setValue(v)
            //       onChange(name, v)
            //     }}
            //   />
            // )}
            endDecorator={
              <>
                {requiredTip ? (
                  <Tooltip
                    title={requiredTip}
                    variant="solid"
                    placement="bottom-start"
                  >
                    <SvgIcon
                      size="sm"
                      sx={{
                        fontSize: '16px',

                        cursor: 'pointer',
                        opacity: 0.7,
                        mr: 1,
                      }}
                      color="danger"
                    >
                      <InfoOutlinedIcon />
                    </SvgIcon>
                  </Tooltip>
                ) : null}
                {!requiredTip && warningTip ? (
                  <Tooltip
                    title={warningTip}
                    variant="solid"
                    placement="bottom-start"
                  >
                    <SvgIcon
                      color="warning"
                      sx={{
                        fontSize: '16px',
                        mr: 1,
                        cursor: 'pointer',
                        opacity: 0.6,
                      }}
                    >
                      <InfoOutlinedIcon />
                    </SvgIcon>

                    {/* <Typography>
                  {attribute?.templateAttribute.constraint === 'required' ? (
                    <InfoOutlinedIcon
                      sx={{ fontSize: '16px', ml: 1, top: '4px' }}
                      fontSize="small"
                      color="disabled"
                    />
                  ) : (
                    ''
                  )}
                  {attribute?.templateAttribute.constraint === 'recommended' ? (
                    <InfoOutlinedIcon
                      sx={{ fontSize: '16px', ml: 1, top: '4px' }}
                      fontSize="small"
                      color="disabled"
                    />
                  ) : (
                    ''
                  )}
                </Typography> */}
                  </Tooltip>
                ) : null}
                {valueMapped && (etsyValueMapText || ebayValueMapText) ? (
                  <Tooltip
                    title={
                      <>
                        {ebayValueMapText}
                        {etsyValueMapText}
                        {/* <Typography
                            level="body-sm"
                            sx={{ color: 'inherit' }}
                            key={i}
                          >
                            {etsyValueMapText}
                          </Typography> */}
                      </>
                    }
                  >
                    <IconButton
                      size="sm"
                      color="primary"
                      onClick={() => setValueMapModalOpen(true)}
                    >
                      <ChecklistRtl fontSize="small" sx={{ opacity: 0.7 }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {valueMappingError ? (
                  <Tooltip
                    title={
                      <>
                        {valueMappingError}
                        {valueMappingWarning}
                      </>
                    }
                  >
                    <IconButton
                      size="sm"
                      onClick={() => setValueMapModalOpen(true)}
                      color="danger"
                    >
                      <ChecklistRtl fontSize="small" sx={{ opacity: 0.7 }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {valueMappingWarning && !valueMappingError ? (
                  <Tooltip title={valueMappingWarning}>
                    <IconButton
                      size="sm"
                      onClick={() => setValueMapModalOpen(true)}
                      color="warning"
                    >
                      <ChecklistRtl fontSize="small" sx={{ opacity: 0.7 }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {valueMapping ||
                options?.[IntegrationName.EBAY]?.length ||
                options?.[IntegrationName.ETSY]?.length ? (
                  <VerticalIconMenu
                    size="small"
                    options={['Value Mapping']}
                    onClick={() => {
                      setValueMapModalOpen(true)
                    }}
                  />
                ) : null}
              </>
            }
          ></Autocomplete>
          <FormHelperText>
            <Typography
              level="body-xs"
              color={helperTextColor}
              sx={{ ml: 1, mt: '-4px' }}
            >
              {warningText}
            </Typography>
          </FormHelperText>
        </FormControl>
      </Grid>

      <Modal
        open={valueMapModalOpen}
        onClose={() => setValueMapModalOpen(false)}
      >
        <ModalDialog
          layout="center"
          sx={{ overflow: 'auto', p: 2 }}
          minWidth="md"
        >
          <ModalClose />

          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Typography level="h3">Channel Value Mapping</Typography>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={valueMapSelectedTab}
                onChange={(e, tab) => {
                  console.log(tab)
                  tab && setValueMapSelectedTab(`${tab}`)
                }}
              >
                <TabList>
                  {['ebay', 'etsy'].map((channel) => {
                    return (
                      <Tab value={channel} key={channel}>
                        {IntegrationDisplayName[channel]}
                      </Tab>
                    )
                  })}
                </TabList>
                {[IntegrationName.EBAY, IntegrationName.ETSY].map(
                  (channel: IntegrationName) => {
                    const allowedValues = options?.[channel]
                    if (!allowedValues?.length) {
                      return (
                        <TabPanel value={channel} key={channel}>
                          <Box p={4}>No Value Mapping</Box>
                        </TabPanel>
                      )
                    }

                    return (
                      <TabPanel value={channel} key={channel} sx={{ py: 2 }}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12}>
                            <Box p={3} mb={3}>
                              <Typography level="title-lg">
                                Template Value Mapping
                              </Typography>
                              <Typography level="body-xs">
                                Mapping will only apply to the selected channel
                                template.
                              </Typography>
                            </Box>
                          </Grid>

                          {allowedValues.length > OPTIONS_DISPLAY_LIMIT ? (
                            <Grid item xs={12}>
                              <Grid
                                container
                                justifyContent="center"
                                spacing={1}
                              >
                                <Grid item xs={2}>
                                  <Button
                                    color="neutral"
                                    variant="soft"
                                    sx={{ float: 'right' }}
                                    onClick={() =>
                                      setPage(Math.max(page - 1, 0))
                                    }
                                    disabled={page < 1}
                                  >
                                    <KeyboardArrowLeftOutlinedIcon />
                                  </Button>
                                </Grid>
                                <Grid item xs={8}>
                                  <FloatLabelInput
                                    label={'Search Channel Values'}
                                    fullWidth={true}
                                    value={searchValueMapping}
                                    onChange={(v) => {
                                      setPage(0)
                                      setSearchValueMapping(v)
                                    }}
                                  ></FloatLabelInput>
                                </Grid>
                                <Grid item xs={2}>
                                  <Button
                                    color="neutral"
                                    variant="soft"
                                    sx={{ float: 'left' }}
                                    onClick={() =>
                                      setPage(
                                        Math.min(
                                          page + 1,
                                          Math.ceil(
                                            allowedValues.length /
                                              OPTIONS_DISPLAY_LIMIT,
                                          ),
                                        ),
                                      )
                                    }
                                    disabled={
                                      page >=
                                      Math.floor(
                                        allowedValues.length /
                                          OPTIONS_DISPLAY_LIMIT,
                                      )
                                    }
                                  >
                                    <KeyboardArrowRightOutlinedIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}

                          <Grid item xs={12} mt={4}>
                            <Grid container justifyContent="center" spacing={1}>
                              <Grid item xs={5}>
                                <Typography level="title-md">
                                  <strong>Value</strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={5}>
                                <Typography level="title-md">
                                  <strong>Channel Value</strong>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          {allowedValues &&
                            allowedValues.length > OPTIONS_DISPLAY_LIMIT &&
                            allowedValues
                              ?.filter((v) =>
                                v
                                  .toLocaleLowerCase()
                                  .startsWith(
                                    searchValueMapping.toLocaleLowerCase(),
                                  ),
                              )
                              .slice(
                                page * OPTIONS_DISPLAY_LIMIT,
                                (page + 1) * OPTIONS_DISPLAY_LIMIT,
                              )
                              ?.map((allowedValue) => {
                                const valueMappingValues =
                                  valueMapping?.[channel]?.[allowedValue]
                                const templateAttributeOptions =
                                  attribute?.templateAttributeOptions.map(
                                    (o) => ({
                                      source: 'template',
                                      value: o.value,
                                    }),
                                  )

                                const valueMappingValueOptions =
                                  valueMappingValues?.map((v) => ({ value: v }))

                                // const channelOptionKeys = allowedValues
                                //   ? Object.keys(allowedValues)
                                //   : []

                                const allChannelOptions =
                                  templateAttributeOptions
                                // channelOptionKeys.forEach((channelKey) => {
                                //   allChannelOptions = allChannelOptions?.concat(
                                //     channelOptions?.[channelKey]?.map((o) => ({
                                //       source: channelKey,
                                //       value: o,
                                //     })) || [],
                                //   )
                                // })

                                return (
                                  <Grid item xs={12} key={allowedValue}>
                                    <Grid
                                      container
                                      spacing={1}
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Grid item xs={5}>
                                        <MultipleGroupSelect
                                          value={valueMappingValueOptions || []}
                                          freeSolo={true}
                                          options={allChannelOptions}
                                          autoHighlight={false}
                                          onChange={(values) => {
                                            onValueMappingChange?.(
                                              channel,
                                              allowedValue,
                                              values,
                                            )
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <SvgIcon
                                          size="lg"
                                          color="neutral"
                                          sx={{
                                            position: 'relative',
                                            top: '4px',
                                            right: '0px',
                                            opacity: 0.5,
                                          }}
                                        >
                                          <ArrowRightAlt />
                                        </SvgIcon>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <FloatLabelInput
                                          value={allowedValue}
                                          disabled={true}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                              })}

                          {allowedValues &&
                            allowedValues.length < OPTIONS_DISPLAY_LIMIT &&
                            allowedValues?.map((allowedValue) => {
                              const valueMappingValues =
                                valueMapping?.[channel]?.[allowedValue]
                              const templateAttributeOptions =
                                attribute?.templateAttributeOptions.map(
                                  (o) => ({
                                    source: 'template',
                                    value: o.value,
                                  }),
                                )

                              const valueMappingValueOptions =
                                valueMappingValues?.map((v) => ({ value: v }))

                              // const channelOptionKeys = allowedValues
                              //   ? Object.keys(allowedValues)
                              //   : []

                              const allChannelOptions = templateAttributeOptions
                              // channelOptionKeys.forEach((channelKey) => {
                              //   allChannelOptions = allChannelOptions?.concat(
                              //     channelOptions?.[channelKey]?.map((o) => ({
                              //       source: channelKey,
                              //       value: o,
                              //     })) || [],
                              //   )
                              // })

                              return (
                                <Grid item xs={12} key={allowedValue}>
                                  <Grid
                                    container
                                    spacing={1}
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Grid item xs={5}>
                                      <MultipleGroupSelect
                                        value={valueMappingValueOptions || []}
                                        freeSolo={true}
                                        options={allChannelOptions}
                                        autoHighlight={false}
                                        onChange={(values) => {
                                          onValueMappingChange?.(
                                            channel,
                                            allowedValue,
                                            values,
                                          )
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <SvgIcon
                                        size="lg"
                                        color="neutral"
                                        sx={{
                                          position: 'relative',
                                          top: '4px',
                                          right: '0px',
                                          opacity: 0.5,
                                        }}
                                      >
                                        <ArrowRightAlt />
                                      </SvgIcon>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <FloatLabelInput
                                        value={allowedValue}
                                        disabled={true}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            })}

                          {allowedValues.length > OPTIONS_DISPLAY_LIMIT ? (
                            <Grid item xs={12}>
                              <Grid
                                container
                                justifyContent="space-between"
                                spacing={1}
                              >
                                <Grid item xs={12}>
                                  <Button
                                    color="neutral"
                                    sx={{ float: 'left' }}
                                    variant="soft"
                                    onClick={() =>
                                      setPage(Math.max(page - 1, 0))
                                    }
                                    disabled={page < 1}
                                  >
                                    <KeyboardArrowLeftOutlinedIcon />
                                  </Button>
                                  <Button
                                    color="neutral"
                                    sx={{ float: 'right' }}
                                    variant="soft"
                                    onClick={() =>
                                      setPage(
                                        Math.min(
                                          page + 1,
                                          Math.ceil(
                                            allowedValues.length /
                                              OPTIONS_DISPLAY_LIMIT,
                                          ),
                                        ),
                                      )
                                    }
                                    disabled={
                                      page >=
                                      Math.floor(
                                        allowedValues.length /
                                          OPTIONS_DISPLAY_LIMIT,
                                      )
                                    }
                                  >
                                    <KeyboardArrowRightOutlinedIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                        </Grid>
                      </TabPanel>
                    )
                  },
                )}
              </Tabs>
            </Grid>

            <Grid item xs={12}>
              <Button sx={{ float: 'right' }}>Save</Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
    </Grid>
  )
}

// const GroupHeader = styled('div')(({ theme }) => ({
//   position: 'sticky',
//   top: '-8px',
//   padding: '4px 10px',
//   color: theme.palette.primary.main,
//   backgroundColor:
//     theme.palette.mode === 'light'
//       ? lighten(theme.palette.primary.light, 0.85)
//       : darken(theme.palette.primary.main, 0.8),
// }))

// const GroupItems = styled('ul')({
//   padding: 0,
//   backgroundColor: '#fff',
//   li: '1px solid red',
// })
