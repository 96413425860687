import React from 'react'
import { createRoot } from 'react-dom/client'
import './app/index.css'
import App from './app/App'
import '@fontsource/inter'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
