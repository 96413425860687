import React, { useContext } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Login from '../pages/account/Login'
import CreateAccount from '../pages/account/CreateAccount'
import ForgotPassword from '../pages/account/ForgotPassword'
import Admin from '../pages/admin/Admin'
import AppContext from './AppContext'
import SelectClient from '../pages/account/SelectClient'
import Products from '../pages/products/Products'
import AddProducts from '../pages/products/AddProduct'
import CreateTemplate from '../pages/template/CreateTemplate'
import Clients from '../pages/admin/Clients'
import ProductTemplates from '../pages/products/ProductTemplates'
import ProductTemplate from '../pages/template/ProductTemplate'
import Product from '../pages/products/product/Product'
import Integrations from '../pages/Integrations'
import { MS_TO_SEC } from '../utils/constants'
import OAuthHandler from '../pages/integrations/OAuthHandler'
import ProductListings from '../pages/ProductListings'
import SplashPage from '../pages/SplashPage'
import ConfirmEmail from '../pages/account/ConfirmEmail'
import ResetPassword from '../pages/account/ResetPassword'
import Account from '../pages/account/Account'
import { isSuperAdmin } from '../utils/functions'
import OrdersPage from '../pages/Orders'

export default function AppRouter(): JSX.Element {
  const whitelistPaths = [
    '/',
    '/home',
    '/about',
    '/contact',
    '/privacy',
    '/terms',
    '/pricing',
    '/createAccount',
    '/forgotPassword',
    '/resetPassword',
  ]
  const LOGIN_PATH = '/login'
  const SELECT_CLIENT_PATH = '/selectClient'
  const path = window.location.pathname

  const redirectPath: string | undefined =
    window.location.pathname + window.location.search
  // Do not add redirect path if it already exists or if already on login path
  const loginRedirect =
    window.location.pathname !== LOGIN_PATH && // not already on login page
    redirectPath && // redirect path exists
    redirectPath !== '/' && // redirect path is not root path
    !redirectPath.includes('redirectTo=') // not already redirectingTo
      ? LOGIN_PATH + `?redirectTo=` + redirectPath
      : LOGIN_PATH + window.location.search

  const selectClientRedirect =
    window.location.pathname !== SELECT_CLIENT_PATH && // not already on login page
    redirectPath && // redirect path exists
    redirectPath !== '/' && // redirect path is not root path
    !redirectPath.includes('redirectTo=') // not already redirectingTo
      ? SELECT_CLIENT_PATH + `?redirectTo=` + redirectPath
      : SELECT_CLIENT_PATH + window.location.search

  const { appState } = useContext(AppContext)
  const isAuthenticated = appState.user.id
  const needEmailConfirmation =
    path !== '/confirmEmail' &&
    appState.user.id &&
    !appState.user.emailConfirmed
  const isNotLoggedIntoClient =
    appState.user.id && // logged in
    !appState.client.id && // not assigned client yet
    (appState.user.clientIds?.length || isSuperAdmin(appState.user.roleId)) // has clientIds or is superAdmin

  const jwtExpired = (appState.jwt.exp || 0) < new Date().getTime() * MS_TO_SEC

  const whitelistedPath = whitelistPaths.includes(path)

  const relogin = jwtExpired && !whitelistedPath
  const loginToClient = isNotLoggedIntoClient && !whitelistedPath
  const hasNoClient =
    appState.user.id &&
    !appState.client.id &&
    !appState.user.clientIds &&
    !isSuperAdmin(appState.user.roleId)

  return (
    <Router>
      {relogin ? (
        <Redirect to={{ pathname: loginRedirect, state: {} }}></Redirect>
      ) : null}

      {loginToClient ? (
        <Redirect
          to={{
            pathname: selectClientRedirect,
            state: {},
          }}
        ></Redirect>
      ) : null}

      {needEmailConfirmation ? (
        <Redirect
          to={{
            pathname: '/confirmEmail',
            state: {},
          }}
        ></Redirect>
      ) : null}

      {hasNoClient ? (
        <Redirect
          to={{
            pathname: '/admin',
            state: {},
          }}
        ></Redirect>
      ) : null}

      <div className="App">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <Switch>
          <Route path="/about">
            <SplashPage path="about" />
          </Route>
          <Route path="/contact">
            <SplashPage path="contact" />
          </Route>
          <Route path="/privacy">
            <SplashPage path="privacy" />
          </Route>
          <Route path="/terms">
            <SplashPage path="terms" />
          </Route>
          <Route path="/pricing">
            <SplashPage path="pricing" />
          </Route>
          <Route path="/oauth/:channel/:dev?">
            <OAuthHandler />
          </Route>
          <Route path="/login(:?\?redirectTo=.*)?(:?\?email=.*)?(:?\?token=.*)?">
            <Login />
          </Route>
          <Route path="/selectClient(:?\?redirectTo=.*)?">
            <SelectClient />
          </Route>
          <Route path="/createAccount">
            <CreateAccount />
          </Route>
          <Route path="/confirmEmail">
            <ConfirmEmail />
          </Route>
          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route path="/resetPassword">
            <ResetPassword />
          </Route>
          <Route path="/dashboard">
            <Products />
          </Route>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/orders">
            <OrdersPage />
          </Route>
          <Route path="/listings">
            <ProductListings />
          </Route>
          <Route path="/integrations/:channel?">
            <Integrations />
          </Route>
          <Route path="/createTemplate">
            <CreateTemplate />
          </Route>
          <Route path="/addProduct">
            <AddProducts />
          </Route>
          <Route path="/newProduct/:templateId">
            <Product newProduct={true} />
          </Route>
          <Route path="/product/:productId/:tab?/:channel?">
            <Product />
          </Route>
          <Route path="/templates">
            <ProductTemplates />
          </Route>
          <Route path="/template/:templateId/:tab?/:channel?">
            <ProductTemplate />
          </Route>
          <Route path="/admin/:clientId?">
            <Admin />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/clients/">
            <Clients />
          </Route>
          <Route path="/home">
            <SplashPage />
          </Route>
          <Route path="/">
            {!isAuthenticated ? <SplashPage /> : <Products />}
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
