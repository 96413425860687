import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'
import Box from '@mui/material/Box'
import IconButton from '@mui/joy/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/joy/Tooltip'

export const ItemTypes = {
  IMAGE: 'img',
}

interface ImageDragProps {
  name?: string
  value: string | File
  selected?: boolean
  index: number
  onChange: (item: ImageDragItem, newIndex: number) => void
  onClick?: (image: string | File) => void
  onDelete: (index: number) => void
}

export type ImageDragItem = {
  value: string | File
  index: number
  name?: string
}

export default function ImageDrag({
  name,
  value,
  index,
  selected,
  onChange,
  onDelete,
  onClick,
}: ImageDragProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<
    ImageDragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.IMAGE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: ImageDragItem, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()

      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left
      if (dragIndex <= hoverIndex && hoverClientX <= hoverMiddleX) return
      if (dragIndex >= hoverIndex && hoverClientX >= hoverMiddleX) return

      onChange(item, hoverIndex)
      item.index = hoverIndex // update item
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: () => {
      return { name, value, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const opacity = isDragging ? 0.33 : 1
  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{ height: '250px', cursor: 'move', opacity }}
    >
      <Tooltip title={name}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              sx={{
                position: 'absolute',
                top: '0.5rem',
                right: '0.5rem',
                backgroundColor: '#fff',
                opacity: '0.3',
              }}
            >
              <CloseIcon onClick={() => onDelete(index)} />
            </IconButton>
            <img
              src={
                typeof value === 'string'
                  ? value
                  : URL.createObjectURL(value as Blob)
              }
              style={{
                height: '250px',
                borderRadius: 3,
              }}
              loading="lazy"
              alt=""
              onClick={() => onClick?.(value)}
            />
          </Box>
        </Box>
      </Tooltip>
    </div>
  )
}
