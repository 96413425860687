import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { useHistory } from 'react-router-dom'
import Component from '../../components/common/Component'
import { confirmEmail, logout, sendConfirmationEmail } from '../../api/account'
import { useContext } from 'react'
import AppContext from '../../app/AppContext'
import Cookies from 'js-cookie'
import Alert, { AlertInput } from '../../components/common/Alert'
import NavBar from '../../components/common/NavBar'
import qs from 'qs'
import { err, wait } from '../../utils/functions'

export default function ConfirmEmail(): JSX.Element {
  const history = useHistory()
  const { appReducer } = useContext(AppContext)
  const { appState } = useContext(AppContext)
  const emailConfirmed = appState.user.emailConfirmed

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const { code: codeParam, uuid: uuidParam } = qs.parse(
    window.location.search,
    {
      ignoreQueryPrefix: true,
    },
  ) as { [k: string]: string }

  useEffect(() => {
    if (emailConfirmed) {
      history.push('/dashboard')
    }
  }, [emailConfirmed, history])

  useEffect(() => {
    if (!uuidParam || !codeParam) return
    confirmEmail(uuidParam, codeParam)
      .then((res) => {
        if (res.success) {
          setAlert({
            open: true,
            severity: 'success',
            message: 'Email confirmed. Please log in again. (redirecting...)',
          })
          wait(2000)
            .then(() => {
              logout()
                .then((logoutRes) => {
                  if (logoutRes.success) {
                    appReducer({ type: 'jwt', payload: undefined })
                    Cookies.remove('jwt', { path: '' })
                    history.push('/login')
                  }
                })
                .catch((e) => err(e))
            })
            .catch((e) => err(e))
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: res.message || 'Unknown Error. Try again later.',
          })
        }
      })
      .catch((e) => {
        console.log('error:', e.response.data)
        setAlert({ open: true, severity: 'error', message: e.res.message })
      })
  }, [appReducer, codeParam, history, uuidParam])

  const handleReturn = () => {
    history.push('/login')
  }

  const handleNewEmail = async () => {
    await sendConfirmationEmail()
  }

  return (
    <>
      <NavBar />
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            mt: '2em',
          }}
        >
          <Component p={3}>
            <Box p={1}>
              <Typography level="h4" component="h1">
                Confirm Email
              </Typography>
            </Box>

            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  Before continue you must confirm your email, check your inbox
                  for a confirmation link. <br /> Email links are valid for up
                  to 30 minutes.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleNewEmail}>
                  Send New Email
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ marginTop: '1em' }}
                >
                  <Grid item>
                    <Button
                      variant="solid"
                      color="primary"
                      onClick={handleReturn}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Component>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </>
  )
}
