import React, { useEffect, useState } from 'react'
import Component from '../components/common/Component'
import NavBar from '../components/common/NavBar'
import Container from '@mui/material/Container'
import {
  createIntegration,
  getIntegrations,
  GetIntegrationsResult,
  IntegrationDisplayName,
  IntegrationName,
} from '../api/integration'
import { err, log } from '../utils/functions'
import Grid from '@mui/material/Grid'
import Tabs from '../components/common/Tabs'
import Typography from '@mui/joy/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import EbayIntegration from './integrations/EbayIntegration'
import EtsyIntegration from './integrations/EtsyIntegration'
import ShopifyIntegration from './integrations/ShopifyIntegration'
import BigCommerceIntegration from './integrations/BigCommerceIntegration'
import { INTEGRATION_NAMES } from '../types'
import SquareIntegration from './integrations/SquareIntegration'
import CloverIntegration from './integrations/CloverIntegration'
import CSVIntegration from './integrations/CSVIntegration'
import Alert, { AlertInput, handleAlert } from '../components/common/Alert'
import { useHistory, useParams } from 'react-router-dom'
import CircularProgress from '@mui/joy/CircularProgress'

export default function Integrations(): JSX.Element {
  const [integrations, setIntegrations] = useState<GetIntegrationsResult>({})
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [loading, setLoading] = useState<boolean>(true)

  const { channel }: { channel?: string } = useParams()
  const selectedChannel = channel

  const history = useHistory()
  const setSelectedTab = (channel: string) => {
    history.push(`/integrations/${channel}`, { shallow: true })
  }

  const handleCreateIntegration = <T extends IntegrationName>(name: T) => {
    setLoading(true)
    createIntegration(name)
      .then((res) => {
        setLoading(false)
        handleAlert(setAlert, res, 'Created Integration')
        if (res.success && res.data) {
          window.location.reload()
        } else if (!res.success) {
          log(res.data)
        }
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    getIntegrations({})
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setIntegrations(res.data)
        } else {
          log(res.message)
        }
      })
      .catch((e) => err(e))
  }, [])

  return (
    <>
      <NavBar />
      <div
        style={{
          width: '100%',
        }}
      >
        <Container sx={{ mt: 4 }}>
          <Component title="Integrations">
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  tabs={INTEGRATION_NAMES}
                  selected={selectedChannel}
                  onSelect={setSelectedTab}
                  panels={[
                    <>
                      {integrations.ebay ? (
                        <Box p={3}>
                          <EbayIntegration integration={integrations.ebay} />
                        </Box>
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.EBAY}
                          onCreate={handleCreateIntegration}
                          loading={loading}
                        />
                      )}
                    </>,
                    <>
                      {integrations.etsy ? (
                        <Box p={3}>
                          <EtsyIntegration integration={integrations.etsy} />
                        </Box>
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.ETSY}
                          onCreate={handleCreateIntegration}
                          loading={loading}
                        />
                      )}
                    </>,
                    <>
                      {integrations.shopify ? (
                        <Box p={3}>
                          <ShopifyIntegration
                            integration={integrations.shopify}
                          />
                        </Box>
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.SHOPIFY}
                          onCreate={handleCreateIntegration}
                          loading={loading}
                        />
                      )}
                    </>,
                    <>
                      {integrations.bigcommerce ? (
                        <Box p={3}>
                          <BigCommerceIntegration
                            integration={integrations.bigcommerce}
                          />
                        </Box>
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.BIG_COMMERCE}
                          onCreate={handleCreateIntegration}
                          loading={loading}
                        />
                      )}
                    </>,
                    <>
                      {integrations.square ? (
                        <Box p={3}>
                          <SquareIntegration
                            integration={integrations.square}
                          />
                        </Box>
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.SQUARE}
                          onCreate={handleCreateIntegration}
                          loading={loading}
                        />
                      )}
                    </>,
                    <>
                      {integrations.clover ? (
                        <Box p={3}>
                          <CloverIntegration
                            integration={integrations.clover}
                          />
                        </Box>
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.CLOVER}
                          onCreate={handleCreateIntegration}
                          loading={loading}
                        />
                      )}
                    </>,
                    <>
                      {integrations.csv ? (
                        <Box p={3}>
                          <CSVIntegration integration={integrations.csv} />
                        </Box>
                      ) : (
                        <CreateIntegration
                          name={IntegrationName.CSV}
                          onCreate={handleCreateIntegration}
                          loading={loading}
                        />
                      )}
                    </>,
                  ]}
                ></Tabs>
              </Grid>
            </Grid>
            {/* Show available integrations, with create buttons */}
            <Box p={3}></Box>
          </Component>
        </Container>
      </div>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
      <Box p={30}></Box>
    </>
  )
}

function CreateIntegration({
  name,
  loading,
  onCreate,
}: {
  name: IntegrationName
  loading: boolean
  onCreate: (name: IntegrationName) => void
}): JSX.Element {
  if (loading) {
    return (
      <Box p={12}>
        <CircularProgress size="lg" />
      </Box>
    )
  }

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Typography level="title-lg">
          No Integration Configured for{' '}
          {IntegrationDisplayName[name as IntegrationName] || name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography></Typography>
      </Grid>

      <Grid item xs={12}>
        <Button variant="solid" onClick={() => onCreate(name)}>
          Create
        </Button>
      </Grid>
    </Grid>
  )
}
