import React, { useEffect, useState } from 'react'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import { Integration } from '../../../../api/types'
import Grid from '@mui/material/Grid'
import {
  getEbayInventoryLocations,
  getEbayListingPolicies,
  setEbayListingPolicies,
} from '../../../../api/integrations/ebay'
import { err, isDefined, log } from '../../../../utils/functions'
import {
  InventoryLocation,
  ListingPolicies,
} from '../../../../types/Ebay.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import SingleSelect from '../../../common/SingleSelect'
import Box from '@mui/joy/Box'
import CircularProgress from '@mui/joy/CircularProgress'
import EbayPoliciesModal from './EbayPoliciesModal'
import EbayLocationsModal from './EbayLocationsModal'

interface EbayPoliciesProps {
  integration: Integration | undefined
}
export default function EbayPolicies({
  integration,
}: EbayPoliciesProps): JSX.Element | null {
  // const [location, setLocation] = useState<InventoryLocation>()

  const [listingPoliciesLoading, setListingPoliciesLoading] =
    useState<boolean>(true)
  const [listingPoliciesState, setListingPoliciesState] =
    useState<ListingPolicies>()

  const [locations, setLocations] = useState<InventoryLocation[]>()
  const [locationsModalOpen, setLocationsModalOpen] = useState<boolean>(false)
  const [policiesModalOpen, setPoliciesModalOpen] = useState<boolean>(false)
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSetDefaultPolicies = () => {
    setEbayListingPolicies()
      .then((res) => {
        handleAlert(setAlert, res, 'Listing policies set.')
      })

      .catch((e) => {
        err(e)
      })
  }

  useEffect(() => {
    getEbayListingPolicies()
      .then((res) => {
        if (res.success && res.data) {
          setListingPoliciesLoading(false)
          setListingPoliciesState(res.data)
        }
      })
      .catch((e) => {
        setListingPoliciesLoading(false)
        err(e)
      })
    return
  }, [])

  useEffect(() => {
    getEbayInventoryLocations()
      .then((res) => {
        if (res.success && res.data) {
          setLocations(res.data)
          // setCountry(res.data.location.address.country as CountryCodeEnum)
          // setPostalCode(res.data.location.address.postalCode)
        } else {
          log(res.message)
        }
      })
      .catch((e) => err(e))
    return
  }, [])

  const getLocationDescription = (location: InventoryLocation): string => {
    if (location.name) return location.name
    return `${location.merchantLocationKey}`
  }

  return (
    <Grid container justifyContent="center" spacing={8}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg">Listing Policies</Typography>
            <Typography level="body-xs">
              Set store payment, return, and fulfillment policies.{' '}
              <a
                href="https://www.bizpolicy.ebay.com/businesspolicy/manage"
                target="_blank"
                rel="noreferrer"
              >
                Edit on Ebay
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {listingPoliciesState && !listingPoliciesLoading ? (
              <>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <SingleSelect
                      defaultOption={false}
                      floatLabel="Payment Policy"
                      value={
                        listingPoliciesState.paymentPolicies?.[0]?.name || ''
                      }
                      options={
                        listingPoliciesState.paymentPolicies
                          ?.map((policy) => policy.name)
                          .filter(isDefined) || []
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SingleSelect
                      defaultOption={false}
                      floatLabel="Return Policy"
                      value={
                        listingPoliciesState.returnPolicies?.[0]?.name || ''
                      }
                      options={
                        listingPoliciesState.returnPolicies
                          ?.map((policy) => policy.name)
                          .filter(isDefined) || []
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SingleSelect
                      defaultOption={false}
                      floatLabel="Fulfillment Policy"
                      value={
                        listingPoliciesState.fulfillmentPolicies?.[0]?.name ||
                        ''
                      }
                      options={
                        listingPoliciesState.fulfillmentPolicies
                          ?.map((policy) => policy.name)
                          .filter(isDefined) || []
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="soft"
                      color="neutral"
                      sx={{ float: 'right' }}
                      onClick={() => setPoliciesModalOpen(true)}
                    >
                      Manage
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}

            {!listingPoliciesState && !listingPoliciesLoading ? (
              <Button
                variant="solid"
                onClick={() => {
                  handleSetDefaultPolicies()
                }}
              >
                Set Default Policies
              </Button>
            ) : null}
            {listingPoliciesLoading ? (
              <Box p={4}>
                <CircularProgress />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg">Inventory Locations</Typography>
            <Typography level="body-xs">Manage inventory locations </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <SingleSelect
                  floatLabel="Locations"
                  defaultOption={false}
                  value={''}
                  disableClearIndicator={true}
                  // onChange={(v) => setCountry(v as CountryCodeEnum)}
                  // options={Object.keys(CountryCodeEnum)}
                  options={
                    locations?.map((l, i) => getLocationDescription(l)) || []
                  }
                  button={
                    <Button
                      variant="soft"
                      color="neutral"
                      fullWidth={true}
                      onClick={() => setLocationsModalOpen(true)}
                    >
                      Manage
                    </Button>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {locations ? (
        <EbayLocationsModal
          locations={locations}
          open={locationsModalOpen}
          onClose={() => setLocationsModalOpen(false)}
          onChange={(locations) => setLocations(locations)}
        />
      ) : null}

      {listingPoliciesState ? (
        <EbayPoliciesModal
          policies={listingPoliciesState}
          open={policiesModalOpen}
          onClose={() => {
            setPoliciesModalOpen(false)
          }}
          onChange={(policies) => setListingPoliciesState(policies)}
        />
      ) : null}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
