import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import { formatNameValue } from '../../utils/functions'
import Alert, { AlertInput } from '../common/Alert'
import { clientLogin, createClient } from '../../api/account'
import AppContext, { JWT } from '../../app/AppContext'
import jwt from 'jsonwebtoken'
import LabelInput from '../common/LabelInput'
import LabelTextarea from '../common/LabelTextarea'

export default function CreateClient(): JSX.Element {
  const { appReducer } = useContext(AppContext)
  const history = useHistory()
  const [name, setName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [description, setDescription] = useState('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleNameChange = (name: string): void => {
    setName(formatNameValue(name))
    setDisplayName(name)
  }

  const clearForm = () => {
    setName('')
    setDisplayName('')
    setDescription('')
  }

  const handleSubmit = async (): Promise<void> => {
    if (name && displayName) {
      const result = await createClient(name, displayName, description)

      if (result.success) {
        clearForm()
        const clientId = result.data?.id
        if (!clientId) {
          setAlert({
            open: true,
            severity: 'error',
            message: 'Could not find client. Try again.',
          })
          return
        }
        const res = await clientLogin(clientId)
        if (result.success) {
          const jwtData = jwt.decode(res.data || '') as JWT
          appReducer({ type: 'jwt', payload: jwtData })
          history.push('/dashboard')
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: res.message,
          })
        }

        // re log user into client
      } else {
        setAlert({ open: true, severity: 'error', message: result.message })
      }
    }
  }
  return (
    <>
      <Component title="Create Organization">
        <Grid container spacing={2} justifyContent="center" direction="column">
          <Grid item xs={12}>
            <LabelInput
              label="Organization Name"
              required
              value={displayName}
              onChange={(v) => handleNameChange(v)}
              helperText={
                name
                  ? `Organization will be saved as "${name}" with display name "${displayName}" `
                  : ''
              }
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelTextarea
              label="Description"
              minRows={4}
              value={description}
              onChange={(v) => setDescription(v)}
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Component>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </>
  )
}
