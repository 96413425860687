import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  arrayToNamedRecord,
  arrayToRecord,
  copy,
  dataFields,
  log,
} from '../../../../utils/functions'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import {
  getIntegration,
  GetIntegrationResult,
  IntegrationName,
  ProductIntegrationType,
  setChannelProductIntegrations,
  setChannelTemplateIntegrations,
  TemplateIntegrationType,
} from '../../../../api/integration'
import Button from '@mui/joy/Button'
import Stack from '@mui/material/Stack'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import MissingIntegration from '../MissingIntegration'
import MissingTemplateIntegration from '../MissingTemplateIntegration'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import Add from '@mui/icons-material/Add'
import FloatLabelInput from '../../../common/FloatLabelInput'
import { APIResult, DataFields } from '../../../../utils/types'
import Tooltip from '@mui/joy/Tooltip'
import IconButton from '@mui/joy/IconButton'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useHistory } from 'react-router-dom'
import OpenInNew from '@mui/icons-material/OpenInNew'
import EditNote from '@mui/icons-material/EditNote'

interface ChannelIntegrationsComponentProps<T extends IntegrationName> {
  channel: T
  integration?: GetIntegrationResult<T>
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
  Component?: (props: {
    templateIntegration: TemplateIntegrationType<T>
    productIntegration?: ProductIntegrationType<T>
    template: GetProductTemplate
    product?: GetProduct
    count?: number
    onTemplateChange?: (
      name: string,
      templateIntegration: TemplateIntegrationType<T> | undefined,
    ) => void
    onProductChange?: (
      name: string,
      productIntegration: ProductIntegrationType<T> | undefined,
    ) => void
    onUpdate?: () => void
  }) => JSX.Element
  getIntegrations?: (
    integrationId: number,
    templateId: number | undefined,
    productId: number | undefined,
  ) => Promise<APIResult<GetIntegrationResult<T>>>
  disableIntegrationFetch?: boolean
  onTemplateDelete?: (id: number) => Promise<APIResult<void>>
  onTemplateSave?: (
    templateIntegrations: DataFields<TemplateIntegrationType<T>>[],
  ) => Promise<APIResult<TemplateIntegrationType<T>[]>>
  onProductSave?: (
    productIntegrations: DataFields<ProductIntegrationType<T>>[],
  ) => Promise<APIResult<ProductIntegrationType<T>[]>>
}
export default function ChannelIntegrationsComponent<
  T extends IntegrationName,
>({
  channel,
  integration: defaultIntegration,
  template,
  product,
  onUpdate,
  Component,
  getIntegrations,
  disableIntegrationFetch,
  onTemplateDelete,
  onTemplateSave,
  onProductSave,
}: ChannelIntegrationsComponentProps<T>): JSX.Element {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(!defaultIntegration)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<T> | undefined
  >(defaultIntegration)

  const [templateIntegrations, setTemplateIntegrations] = useState<
    DataFields<TemplateIntegrationType<T>>[]
  >(integration?.templateIntegrations || [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [productIntegrations, setProductIntegrations] = useState<
    ProductIntegrationType<T>[]
  >(integration?.productIntegrations || [])

  const [templateIntegrationsRecord, setTemplateIntegrationsRecord] = useState<
    Record<string, TemplateIntegrationType<T>>
  >(arrayToNamedRecord(integration?.templateIntegrations))

  const [productIntegrationsRecord, setProductIntegrationsRecord] = useState<
    Record<string, ProductIntegrationType<T>> | undefined
  >(arrayToRecord(integration?.productIntegrations, (e) => e.name || ''))

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [detailUpdate, setDetailUpdate] = useState<number>(0)
  const [selectedTab, setSelectedTab] = useState<string>(
    templateIntegrations?.[0]?.name || '',
  )

  const [addChannelTemplateName, setAddChannelTemplateName] =
    useState<string>('')

  const templateId = template?.template.id

  const handleTemplateIntegrationsChange = async (
    name: string,
    templateIntegration: TemplateIntegrationType<T> | undefined,
  ) => {
    const newTemplateIntegrations = templateIntegrationsRecord
    const currentTemplateIntegration = newTemplateIntegrations[name]
    if (!currentTemplateIntegration) return

    if (!templateIntegration) {
      // Don't allow to delete all
      if (templateIntegrations.length < 2) {
        setAlert({
          message: `Cannot delete only template integration.`,
          open: true,
          severity: 'warning',
        })
        return
      }

      const deleteIntegration = newTemplateIntegrations[name]
      if (deleteIntegration?.id) {
        await onTemplateDelete?.(deleteIntegration.id)
      }
      delete newTemplateIntegrations[name]
      setTemplateIntegrationsRecord(newTemplateIntegrations)
      setSelectedTab('')
      return
    }

    // Index Change
    if (currentTemplateIntegration.index !== templateIntegration.index) {
      const name = currentTemplateIntegration.name
      const currentIndex = currentTemplateIntegration.index
      const newIndex = templateIntegration.index
      // update indices
      const arr = Object.values(newTemplateIntegrations)
      const temp = arr[newIndex]
      if (!temp) return
      arr[newIndex] = templateIntegration

      temp.index = currentIndex // update index
      arr[currentIndex] = temp
      setTemplateIntegrations(arr)
      updateTemplateIntegrationsRecord(arr)
      setSelectedTab(name)
      return
    }

    newTemplateIntegrations[name] = templateIntegration

    setTemplateIntegrationsRecord(newTemplateIntegrations)
    return
  }

  const handleProductIntegrationsChange = (
    name: string,
    productIntegration: ProductIntegrationType<T> | undefined,
  ) => {
    const newProductIntegrations = productIntegrationsRecord || {}

    // No delete
    if (!productIntegration) {
      return
    }

    newProductIntegrations[name] = productIntegration
    setProductIntegrationsRecord(newProductIntegrations)
    return
  }

  const handleAddTemplateIntegration = () => {
    if (!addChannelTemplateName) return
    const name = addChannelTemplateName

    // check duplicates
    const nameExists = !!templateIntegrationsRecord[name]
    if (nameExists) {
      setAlert({
        message: `Configuration with name "${name}" already exists.`,
        open: true,
        severity: 'warning',
      })
      return
    }

    const baseIntegration =
      templateIntegrations.find((t) => t.index === 0) ||
      templateIntegrations?.[0]
    const index = Object.keys(templateIntegrationsRecord).length
    const newTemplateIntegrations = copy(templateIntegrationsRecord)
    newTemplateIntegrations[name] = {
      integrationId: integration?.integration?.id || 0,
      templateId: template?.template.id,
      title: baseIntegration?.title,
      description: baseIntegration?.description,
      id: undefined,
      index,
      name,
    } as TemplateIntegrationType<T>

    console.log('Adding new', newTemplateIntegrations)

    setTemplateIntegrationsRecord(newTemplateIntegrations)
    // setTemplateIntegrations(Object.values(newTemplateIntegrations))
    setAddChannelTemplateName('')
    setSelectedTab(name)
  }

  const updateTemplateIntegrationsRecord = (
    templateIntegrations: TemplateIntegrationType<T>[] | undefined,
  ) => {
    if (!templateIntegrations?.length) {
      setTemplateIntegrationsRecord({})
      return
    }
    const newTemplateIntegrations: Record<
      string,
      TemplateIntegrationType<T>
    > = {}
    templateIntegrations.forEach((templateIntegration, i) => {
      newTemplateIntegrations[templateIntegration.name] = templateIntegration
    })
    setTemplateIntegrationsRecord(newTemplateIntegrations)
  }

  const updateProductIntegrationsRecord = (
    productIntegrations: ProductIntegrationType<T>[] | undefined,
  ) => {
    if (!productIntegrations?.length) {
      setProductIntegrationsRecord({})
      return
    }

    const newProductIntegrations: Record<string, ProductIntegrationType<T>> = {}
    productIntegrations.forEach((productIntegration) => {
      if (!productIntegration.name) return
      newProductIntegrations[productIntegration.name] = productIntegration
    })

    setProductIntegrationsRecord(newProductIntegrations)
  }

  // const handleUpdateTemplateIntegrationName = (
  //   templateIntegration: TemplateIntegrationType<T>,
  //   newName: string,
  // ) => {
  //   console.log(templateIntegration, newName)
  //   if (!newName) {
  //     setSelectedTab('')
  //     return
  //   }

  //   // No action, name not updated
  //   if (templateIntegration.name === newName) {
  //     return
  //   }

  //   // Check name already exists
  //   const existing = templateIntegrationsRecord[newName]
  //   if (existing) {
  //     setSelectedTab('')
  //     return
  //   }

  //   const index = templateIntegration.index
  //   const newTemplateIntegrations = Object.values(templateIntegrationsRecord)

  //   const updateIndex = newTemplateIntegrations.findIndex(
  //     (t) => t.index === index,
  //   )
  //   const updateTemplateIntegration = newTemplateIntegrations[updateIndex]
  //   if (!updateTemplateIntegration) return
  //   updateTemplateIntegration.name = newName

  //   newTemplateIntegrations[updateIndex] = updateTemplateIntegration
  //   setTemplateIntegrations(newTemplateIntegrations)
  //   setTemplateIntegrationsRecord(arrayToNamedRecord(newTemplateIntegrations))
  //   setSelectedTab(newName)
  // }

  const handleSave = async () => {
    if (product && templateIntegrations?.[0]?.id && productIntegrationsRecord) {
      let productResult: APIResult<ProductIntegrationType<T>[]> | undefined =
        undefined
      if (onProductSave) {
        productResult = await onProductSave(
          Object.values(productIntegrationsRecord),
        )
      } else {
        productResult = (await setChannelProductIntegrations?.(
          channel as IntegrationName,
          Object.values(productIntegrationsRecord),
        )) as APIResult<ProductIntegrationType<T>[]>
      }

      handleAlert(setAlert, productResult, 'Saved Product Integration')
    }

    if (!integration || !templateId) return

    let templateResult: APIResult<TemplateIntegrationType<T>[]> | undefined =
      undefined
    if (onTemplateSave) {
      templateResult = await onTemplateSave(
        Object.values(templateIntegrationsRecord),
      )
    } else {
      templateResult = (await setChannelTemplateIntegrations(
        channel as IntegrationName,
        Object.values(templateIntegrationsRecord).map((t) => dataFields(t)),
      )) as APIResult<TemplateIntegrationType<T>[]>
    }

    handleAlert(setAlert, templateResult, 'Saved Template Integration')
  }

  /* Get Integration and Auth */
  useEffect(() => {
    if (integration) return
    getIntegration(channel)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => log(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Get Template, Product Integrations */
  useEffect(() => {
    if (disableIntegrationFetch) return
    if (!getIntegrations || !integration?.integration?.id) return
    const tid = templateId || product?.template.id
    const productId = product?.product.id

    setLoading(true)
    getIntegrations(integration.integration.id, tid, productId)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          handleSetState({
            templateIntegrations: res.data.templateIntegrations,
            productIntegrations: res.data.productIntegrations,
          })
        }
      })
      .catch((e) => log(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, templateId, product])

  const handleSetState = ({
    templateIntegrations,
    productIntegrations,
  }: {
    templateIntegrations?: TemplateIntegrationType<T>[]
    productIntegrations?: ProductIntegrationType<T>[]
  }) => {
    if (templateIntegrations) {
      setTemplateIntegrations(templateIntegrations || [])
      updateTemplateIntegrationsRecord(templateIntegrations)
    }

    if (productIntegrations) {
      setProductIntegrations(productIntegrations || [])
      updateProductIntegrationsRecord(productIntegrations)
    }

    setDetailUpdate(detailUpdate + 1)
  }

  const handleIndexChange = (
    templateIntegration: TemplateIntegrationType<T>,
    newIndex: number,
  ) => {
    const currentIndex = templateIntegration.index
    const newTemplateIntegrations = Object.values(templateIntegrationsRecord)

    templateIntegration.index = newIndex
    const temp = newTemplateIntegrations[newIndex]
    if (!temp) return
    newTemplateIntegrations[newIndex] = templateIntegration
    newTemplateIntegrations[currentIndex] = temp

    setTemplateIntegrations(newTemplateIntegrations)
    setTemplateIntegrationsRecord(arrayToNamedRecord(newTemplateIntegrations))
  }

  if (!integration?.integration || integration.authenticated === false) {
    return <MissingIntegration />
  }

  if (!loading && product && !templateIntegrations?.length) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.EBAY}
      />
    )
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={3} pt={2} px={0}>
        {/* <Grid item xs={12}>
          <Typography level="h3">Channel Templates</Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Tabs
            size="lg"
            variant="outlined"
            sx={{ backgroundColor: '#fff' }}
            value={selectedTab || templateIntegrations?.[0]?.name || ''}
            onChange={(e, tab) => {
              if (tab === '__OPEN_TEMPLATE') {
                history.push(`/template/${templateId}/channels/${channel}`)
                return
              }
              setSelectedTab(`${tab}`)
            }}
          >
            <TabList
              sx={{
                overflow: 'auto',
                scrollSnapType: 'x mandatory',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {Object.values(templateIntegrationsRecord)
                .sort((a, b) => a.index - b.index)
                .map((templateIntegration) => {
                  if (!templateIntegration) return null
                  // const isSelected =
                  //   !product && templateIntegration.name === selectedTab

                  return (
                    <Tab
                      sx={{
                        // p: isSelected ? 0.5 : undefined,
                        flex: 'none',
                        scrollSnapAlign: 'start',
                      }}
                      disableIndicator={true}
                      value={templateIntegration.name}
                      key={templateIntegration.name}
                    >
                      {/* {isSelected ? (
                        <Box
                          sx={{
                            overflow: 'hidden',
                          }}
                        >
                          <FloatLabelInput
                            defaultValue={templateIntegration.name}
                            fullWidth={true}
                            onBlur={(e) =>
                              handleUpdateTemplateIntegrationName(
                                templateIntegration,
                                e?.target.value || '',
                              )
                            }
                          ></FloatLabelInput>
                        </Box>
                      ) : (
                        <Typography level="title-md">
                          {templateIntegration.name}
                        </Typography>
                      )} */}
                      <Typography level="title-md">
                        {templateIntegration.name}
                      </Typography>
                    </Tab>
                  )
                })}
              {!product && (
                <Tab
                  variant="plain"
                  disableIndicator
                  value={'__ADD_CHANNEL_TEMPLATE'}
                >
                  <Tooltip title="Add Channel Template">
                    <Add fontSize="small" color="action" />
                  </Tooltip>
                </Tab>
              )}
              {product && (
                <Tab variant="plain" disableIndicator value={'__OPEN_TEMPLATE'}>
                  <Tooltip title="Edit on Template">
                    <EditNote color="action" />
                  </Tooltip>
                  <OpenInNew
                    fontSize="small"
                    color="disabled"
                    sx={{
                      fontSize: 14,
                      position: 'absolute',
                      top: '4px',
                      right: '4px',
                    }}
                  />
                </Tab>
              )}
            </TabList>

            {templateId &&
              productIntegrationsRecord &&
              Object.values(templateIntegrationsRecord).map(
                (templateIntegration, i) => {
                  if (!templateIntegration) return null

                  const productIntegration =
                    productIntegrationsRecord[templateIntegration.name]
                  return (
                    <TabPanel
                      key={templateIntegration.name}
                      value={templateIntegration.name}
                    >
                      <Grid container>
                        {!product && (
                          <Grid item xs={12}>
                            <Tooltip
                              title="Re-order left"
                              size="sm"
                              variant="soft"
                              placement="right"
                            >
                              <IconButton
                                variant="plain"
                                size="sm"
                                sx={{ float: 'left' }}
                                disabled={!templateIntegration.index}
                                onClick={() =>
                                  handleIndexChange(templateIntegration, i - 1)
                                }
                              >
                                <ArrowBack fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Re-order right"
                              size="sm"
                              variant="soft"
                              placement="left"
                            >
                              <IconButton
                                variant="plain"
                                size="sm"
                                sx={{ float: 'right' }}
                                disabled={
                                  templateIntegration.index >=
                                  templateIntegrations.length - 1
                                }
                                onClick={() =>
                                  handleIndexChange(templateIntegration, i + 1)
                                }
                              >
                                <ArrowBack
                                  fontSize="small"
                                  sx={{ rotate: '180deg' }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                      {Component && (
                        <Component
                          templateIntegration={templateIntegration}
                          productIntegration={productIntegration}
                          template={template}
                          product={product}
                          count={templateIntegrations.length}
                          onTemplateChange={handleTemplateIntegrationsChange}
                          onProductChange={handleProductIntegrationsChange}
                          onUpdate={onUpdate}
                        />
                      )}
                    </TabPanel>
                  )
                },
              )}

            <TabPanel value={'__ADD_CHANNEL_TEMPLATE'}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography level="title-lg">New Channel Template</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FloatLabelInput
                    label="Channel Template Name"
                    placeholder="Channel Template Name"
                    value={addChannelTemplateName}
                    onChange={setAddChannelTemplateName}
                    button={
                      <Button
                        sx={{ float: 'right' }}
                        variant="soft"
                        onClick={() => handleAddTemplateIntegration()}
                        disabled={!addChannelTemplateName}
                      >
                        Add
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="solid" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Grid>
        <Alert
          alert={alert}
          onClose={() => setAlert({ ...alert, open: false })}
        ></Alert>
      </Grid>
    </>
  )
}
