import React from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/joy/Tooltip'
import Button from '@mui/joy/Button'
import ButtonGroup from '@mui/joy/ButtonGroup'
import Switch from '@mui/joy/Switch'
import Typography from '@mui/joy/Typography'
interface ListSyncComponentProps {
  title?: boolean
  size?: 'sm' | 'md' | 'lg'
  list: boolean
  sync: boolean
  syncQuantity: boolean
  onListChange?: (change: boolean) => void
  onSyncChange?: (change: boolean) => void
  onSyncQuantityChange?: (change: boolean) => void
  onChange?: (config: {
    list: boolean
    sync: boolean
    syncQuantity: boolean
  }) => void
}

export default function ListSyncComponent({
  size = 'md',
  title,
  list,
  sync,
  syncQuantity,
  onListChange,
  onSyncChange,
  onSyncQuantityChange,
  onChange,
}: ListSyncComponentProps): JSX.Element {
  const handleListChange = () => {
    if (list) {
      onChange?.({ list: false, sync: false, syncQuantity: false })
      onListChange?.(false)
      onSyncChange?.(false)
      onSyncQuantityChange?.(false)
    } else {
      onChange?.({ list: true, sync: false, syncQuantity: false })
      onListChange?.(true)
    }
  }

  const handleSyncChange = () => {
    if (sync) {
      onChange?.({ list: list, sync: false, syncQuantity: false })
      onSyncChange?.(false)
    } else {
      onChange?.({ list: list, sync: true, syncQuantity: false })
      onListChange?.(true)
      onSyncChange?.(true)
      onSyncQuantityChange?.(false)
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      {title && (
        <Grid item xs={12}>
          <Typography level="h4">List and Sync Settings</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <ButtonGroup
              buttonFlex={1}
              sx={{
                maxWidth: '100%',
                overflow: 'auto',
              }}
              variant="outlined"
              size={size}
            >
              {/* <Tooltip
                title="Unlist product from channel"
                enterDelay={700}
                enterNextDelay={300}
                size="sm"
                variant="solid"
                arrow
              >
                <Button
                  value="unlist"
                  disabled={true}
                  // onClick={() => handleListChange()}
                  endDecorator={
                    <RemoveCircleOutlineOutlinedIcon fontSize="small" />
                  }
                >
                  Unlist
                </Button>
              </Tooltip> */}
              <Tooltip
                title="List when a product is manually listed"
                enterDelay={700}
                enterNextDelay={300}
                size="sm"
                variant="solid"
                arrow
              >
                <Button value="list" onClick={() => handleListChange()}>
                  List
                  <Switch checked={list} sx={{ ml: 1 }} />
                  {/* <CheckCircleOutlineIcon
                    sx={{ ml: 1 }}
                    fontSize="small"
                    color="primary"
                  /> */}
                </Button>
              </Tooltip>

              <Tooltip
                title="Sync quantity changes from cross-channel sales"
                enterDelay={700}
                enterNextDelay={300}
                size="sm"
                variant="solid"
                arrow
              >
                <Button
                  sx={{ width: '100%' }}
                  value="sync"
                  onClick={() => handleSyncChange()}
                  disabled={!list}
                >
                  Sync
                  <Switch disabled={!list} sx={{ ml: 1 }} checked={sync} />
                  {/* <SyncIcon
                    sx={{ ml: 1 }}
                    fontSize="small"
                    color={!list ? 'disabled' : 'primary'}
                  /> */}
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
