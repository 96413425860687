import React, { useState } from 'react'

import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableMUI from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import { ComponentDense } from './Component'
import Box from '@mui/material/Box'
import TableSortLabel from '@mui/material/TableSortLabel'
import {
  DataGrid,
  GridColumnMenu,
  GridColumnMenuProps,
  GridRenderCellParams,
} from '@mui/x-data-grid'

interface PaginatedTableProps<T> {
  dataGrid?: boolean
  data: T[]
  head: (string | JSX.Element)[]
  row: (
    data: T,
    index?: number,
  ) => (JSX.Element | string | number | boolean | undefined)[] | JSX.Element
  renderRow?: (column: string, value: string) => JSX.Element | string
  rowData?: (data: T, index?: number) => { [k: string]: string }
  onRowClick?: (row: T) => void
  handleRowSelection?: (rowIds: string[]) => void
  sortBy?: string
  orderBy?: Order
  sortableHeaders?: string[]
  sortable?: boolean
  onHeaderClick?: (header: string, ascending: boolean) => void
  size?: 'small' | 'medium'
  count: number | undefined
  page: number
  pageSize: number
  pageSizeOptions?: number[]
  disabled?: boolean
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

type Order = 'asc' | 'desc'

const ITEM_HEIGHT = 48

export default function PaginatedTable<T>({
  dataGrid,
  data,
  head,
  row,
  rowData,
  renderRow,
  onRowClick,
  handleRowSelection,
  sortBy,
  orderBy: defaultOrderBy,
  sortable,
  sortableHeaders,
  size = 'small',
  count,
  page,
  pageSize,
  pageSizeOptions,
  disabled,
  onHeaderClick,
  onPageChange,
  onPageSizeChange,
}: PaginatedTableProps<T>): JSX.Element {
  const [order, setOrder] = useState<Order>(defaultOrderBy || 'asc')
  const [orderBy, setOrderBy] = useState<string | undefined>(sortBy)

  const [localPage, setLocalPage] = useState<number>(page)
  const [localPageSize, setLocalPageSize] = useState<number>(pageSize)

  const handlePageChange = (newPage: number) => {
    setLocalPage(newPage)
    onPageChange(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setLocalPage(0)
    setLocalPageSize(newPageSize)
    onPageSizeChange(newPageSize)
  }

  const handleRequestSort = (by: string) => {
    const key = by
    if (!key) {
      return
    }
    const isAsc = orderBy === by && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(key)
    onHeaderClick?.(by, !isAsc)
  }

  const displayData = data
  const emptyRows = localPageSize - data.length

  if (dataGrid) {
    const columns = head.map((header) => {
      return {
        field: typeof header === 'string' ? header : '-',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderCell: (params: GridRenderCellParams<any, string>) => {
          if (params.value && renderRow) {
            return renderRow(params.field, params.value)
          }
          return '-'
        },
      }
    })

    const rows = displayData.map(
      (data) =>
        rowData?.(data) || {
          id: Math.random(),
        },
    )

    return (
      <Box>
        <div style={{ width: '100%' }}>
          <DataGrid
            columns={columns}
            rows={rows}
            slots={{ columnMenu: CustomColumnMenu }}
            checkboxSelection
            disableRowSelectionOnClick={true}
            onRowClick={(params) => console.log(params.id)}
            onRowSelectionModelChange={(rowSelectionModel) => {
              handleRowSelection?.(rowSelectionModel as string[])
            }}
          />
        </div>
      </Box>
    )
  }

  return (
    <Box>
      <div style={{ width: '100%' }}>
        <TableContainer
          component={(props: { [k: string]: unknown }) => (
            <ComponentDense
              {...props}
              width={'100%'}
              height="100"
              overflow={'auto'}
            />
          )}
        >
          <TableMUI size={size}>
            {head && (
              <TableHead>
                <TableRow>
                  {head.map((h, i) => (
                    <TableCell
                      key={`table-head-${i}`}
                      sx={{
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                      }}
                    >
                      {sortable &&
                      typeof h === 'string' &&
                      sortableHeaders?.includes(h) ? (
                        <TableSortLabel
                          active={orderBy === h}
                          direction={orderBy === h ? order : 'asc'}
                          onClick={() =>
                            typeof h === 'string' && handleRequestSort(h)
                          }
                        >
                          {h}
                          {orderBy === h ? (
                            <span style={{ display: 'none' }}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        h
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody sx={{ minWidth: 1000 }}>
              {displayData?.map((d, i) => {
                const rowResult = row(d)
                return Array.isArray(rowResult) ? (
                  <TableRow
                    key={`table-row-${i}`}
                    onClick={() => onRowClick?.(d)}
                    style={{ cursor: onRowClick ? 'pointer' : 'inherit' }}
                  >
                    {rowResult.map((r, j) => (
                      <TableCell key={`table-cell-${i}-${j}`}>{r}</TableCell>
                    ))}
                  </TableRow>
                ) : (
                  rowResult
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: ITEM_HEIGHT * emptyRows }}>
                  <TableCell colSpan={head?.length || 0} />
                </TableRow>
              )}
            </TableBody>
          </TableMUI>
        </TableContainer>
      </div>

      <TablePagination
        rowsPerPageOptions={pageSizeOptions || [5, 10, 25, 50, 100]}
        component="div"
        count={count ?? data.length * 2}
        rowsPerPage={localPageSize}
        page={localPage}
        onPageChange={(e, pg) => handlePageChange(pg)}
        onRowsPerPageChange={(e) => {
          handlePageSizeChange(parseInt(e.target.value))
        }}
        disabled={disabled}
      />
    </Box>
  )
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuSortItem: null,
      }}
      slotProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 10, // Previously `0`
        },
      }}
    />
  )
}
