import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavBar from '../components/common/NavBar'
import Container from '@mui/joy/Container'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Component from '../components/common/Component'
import Typography from '@mui/joy/Typography'
import { getEbayOrders } from '../api/integrations/ebay'
import { err, formatDate, isDefined } from '../utils/functions'
import { EbayOrder } from '../types/Ebay.types'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import TabPanel from '@mui/joy/TabPanel'
import Tab from '@mui/joy/Tab'
import { IntegrationDisplayName, IntegrationName } from '../api/integration'
import { getEtsyShopReceipts } from '../api/integrations/etsy'
import { EtsyReceipt } from '../types/Etsy.types'
import CircularProgress from '@mui/joy/CircularProgress'
import Table from '@mui/joy/Table'
import { getShopifyOrders } from '../api/integrations/shopify'
import { ShopifyOrder } from '../types/Shopify.types'
import { getSquareOrders } from '../api/integrations/square'
import { SquareOrder } from '../types/Square.types'
import { getBigCommerceOrders } from '../api/integrations/bigcommerce'
import { BigCommerceOrder } from '../types/BigCommerce.types'
import { CloverOrder } from '../types/Clover.types'
import { getCloverOrders } from '../api/integrations/clover'

export default function OrdersPage(): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  const [selectedTab, setSelectedTab] = useState<string>('ebay')
  const [ebayOrders, setEbayOrders] = useState<EbayOrder[] | undefined>()
  const [etsyOrders, setEtsyOrders] = useState<EtsyReceipt[] | undefined>()
  const [shopifyOrders, setShopifyOrders] = useState<
    ShopifyOrder[] | undefined
  >()

  const [squareOrders, setSquareOrders] = useState<SquareOrder[] | undefined>()
  const [squareProductIds, setSquareProductIds] = useState<
    Record<string, number | undefined> | undefined
  >()
  const [bigcommerceOrders, setBigCommerceOrders] = useState<
    BigCommerceOrder[] | undefined
  >()
  const [cloverOrders, setCloverOrders] = useState<CloverOrder[] | undefined>()

  const channels = Object.values(IntegrationName)

  useEffect(() => {
    getEbayOrders()
      .then((res) => {
        if (res.success && res.data) {
          setEbayOrders(res.data)
        }
      })
      .catch((e) => err(e))

    getEtsyShopReceipts()
      .then((res) => {
        if (res.success && res.data) {
          setEtsyOrders(res.data)
        }
      })
      .catch((e) => err(e))

    getShopifyOrders()
      .then((res) => {
        if (res.success && res.data) {
          setShopifyOrders(res.data)
        }
      })
      .catch((e) => err(e))

    getSquareOrders()
      .then((res) => {
        if (res.success && res.data) {
          if (res.data?.orders) {
            setSquareOrders(res.data.orders)
            setSquareProductIds(res.data.productIds)
          }
        }
      })
      .catch((e) => err(e))

    getBigCommerceOrders()
      .then((res) => {
        if (res.success && res.data) {
          setBigCommerceOrders(res.data)
        }
      })
      .catch((e) => err(e))

    getCloverOrders()
      .then((res) => {
        if (res.success && res.data) {
          setCloverOrders(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  return (
    <div
      id="bg"
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'lg'}>
        <Grid container sx={{ mt: 1 }} spacing={3}>
          <Grid item xs={12}>
            <Component>
              <Grid
                container
                spacing={1}
                sx={{
                  px: md ? 2 : 0,
                }}
              >
                <Grid item xs={12} sx={{ position: 'relative', mb: 2 }}>
                  <Typography level="h3">
                    <strong>Orders</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Tabs
                    size="lg"
                    value={selectedTab}
                    onChange={(_e, tab) => {
                      tab && setSelectedTab(`${tab}`)
                    }}
                  >
                    <TabList>
                      {channels.map((channel) => (
                        <Tab
                          disableIndicator={true}
                          key={channel}
                          value={channel}
                          variant="plain"
                          color="neutral"
                        >
                          {IntegrationDisplayName[channel]}
                        </Tab>
                      ))}
                    </TabList>
                    <TabPanel value="ebay" sx={{ py: 4 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        {ebayOrders?.length ? (
                          ebayOrders.map((order) => {
                            return (
                              <Grid key={order.orderId}>
                                <Typography>{order.orderId}</Typography>
                              </Grid>
                            )
                          })
                        ) : ebayOrders ? (
                          <Typography p={3}>No Orders</Typography>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel value="etsy" sx={{ py: 4 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        {etsyOrders?.length ? (
                          etsyOrders.map((order) => {
                            return (
                              <Grid key={order.receipt_id}>
                                <Typography>{order.receipt_id}</Typography>
                              </Grid>
                            )
                          })
                        ) : etsyOrders ? (
                          <Typography p={3}>No Orders</Typography>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel value="shopify" sx={{ py: 4 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        {shopifyOrders?.length ? (
                          shopifyOrders.map((order) => {
                            return (
                              <Grid key={order.id}>
                                <Typography>{order.id}</Typography>
                              </Grid>
                            )
                          })
                        ) : shopifyOrders ? (
                          <Typography p={3}>No Orders</Typography>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                    </TabPanel>

                    <TabPanel value="square" sx={{ py: 4 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12}>
                          {squareOrders?.length ? (
                            <Table>
                              <thead>
                                <th>Customer</th>
                                <th>Source</th>
                                <th>Items</th>
                                {/* <th>Location</th> */}
                                <th style={{ width: '10%' }}>Price</th>
                                <th style={{ width: '10%' }}>Quantity</th>
                                <th>State</th>
                                <th style={{ width: '10%' }}>Link</th>
                                <th>Date</th>
                              </thead>
                              <tbody>
                                {squareOrders.map((order) => {
                                  return (
                                    <tr key={order.id}>
                                      <td>
                                        {
                                          order.fulfillments?.[0]
                                            ?.shipmentDetails?.recipient
                                            ?.emailAddress
                                        }
                                      </td>
                                      <td>{order.source?.name}</td>
                                      <td>
                                        {order.lineItems
                                          ?.map(
                                            (l) =>
                                              l.catalogObjectId &&
                                              squareProductIds?.[
                                                l.catalogObjectId
                                              ],
                                          )
                                          .filter(isDefined)
                                          .map((productId, i) => (
                                            <a
                                              key={`${productId}-${i}`}
                                              href={`/product${productId}`}
                                            >
                                              Product {productId}
                                            </a>
                                          ))}
                                      </td>
                                      {/* <td>{order.locationId}</td> */}
                                      <td>{order.totalMoney?.amount}</td>
                                      <td>
                                        {order.lineItems
                                          ?.map((l) => parseInt(l.quantity))
                                          .join(', ')}
                                      </td>
                                      <td>{order.state}</td>
                                      <td>
                                        <a
                                          href={`https://app.squareup.com/dashboard/orders/overview/${order.id}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Open
                                        </a>
                                      </td>
                                      <td>{formatDate(order.createdAt)}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          ) : null}

                          {!squareOrders?.length ? (
                            <Typography p={3}>No Orders</Typography>
                          ) : !squareOrders ? (
                            <CircularProgress />
                          ) : null}
                        </Grid>
                      </Grid>
                    </TabPanel>

                    <TabPanel value="bigcommerce" sx={{ py: 4 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        {bigcommerceOrders?.length ? (
                          bigcommerceOrders.map((order) => {
                            return (
                              <Grid key={order.id}>
                                <Typography>{order.id}</Typography>
                              </Grid>
                            )
                          })
                        ) : bigcommerceOrders ? (
                          <Typography p={3}>No Orders</Typography>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel value="clover" sx={{ py: 4 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        {cloverOrders?.length ? (
                          cloverOrders.map((order) => {
                            return (
                              <Grid key={order.items?.[0]?.id}>
                                <Typography>{order.items?.[0]?.id}</Typography>
                              </Grid>
                            )
                          })
                        ) : cloverOrders ? (
                          <Typography p={3}>No Orders</Typography>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel value="csv" sx={{ p: 6 }}>
                      No Order data for CSV Integration
                    </TabPanel>
                  </Tabs>
                </Grid>
              </Grid>
            </Component>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
