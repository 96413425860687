import React from 'react'
import Grid from '@mui/material/Grid'
import {
  Aspect,
  EbayTemplateAspectIntegration,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import { GetProductTemplateAttribute } from '../../../../api/product'

import AspectGroup from './AspectGroup'
import { AspectIntegrationInput } from './EbayIntegrationsComponent'
import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'

export default function AspectSection({
  label,
  aspects,
  aspectIntegrations,
  templateIntegration,
  templateAttributes,
  open,
  onChange,
  onUpdate,
}: {
  label: string
  aspects: Aspect[]
  templateIntegration: EbayTemplateIntegration
  aspectIntegrations: EbayTemplateAspectIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  open?: boolean
  onChange: (aspect: AspectIntegrationInput) => void
  onUpdate?: () => void
}): JSX.Element {
  const getIntegrationAspect = (
    getAspect: Aspect,
  ): AspectIntegrationInput | undefined =>
    aspectIntegrations.find(
      (a) => a.aspectName === getAspect.localizedAspectName,
    )

  const handleChange = onChange

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Typography level="title-md" pt={4}>
          <strong>{label}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Grid container spacing={1} justifyContent="center">
            {aspects.map((aspect) => (
              <Grid item key={aspect.localizedAspectName} xs={12}>
                <AspectGroup
                  aspect={aspect}
                  templateAttributes={templateAttributes}
                  templateIntegration={templateIntegration}
                  aspectIntegration={getIntegrationAspect(aspect)}
                  onChange={handleChange}
                  onUpdate={onUpdate}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
