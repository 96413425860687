import * as React from 'react'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/joy/Typography'
import Textarea from '@mui/joy/Textarea'

export default function LabelTextarea({
  value,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onKeyDown,
  label,
  placeholder,
  size,
  minRows,
  maxRows,
  required,
  disabled,
  errorText,
  helperText,
  autoComplete,
}: {
  value?: string | number
  onChange?: (value: string) => void
  onBlur?: (e?: React.FocusEvent<HTMLTextAreaElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  label?: string
  placeholder?: string
  minRows?: number
  maxRows?: number
  size?: 'sm' | 'md' | 'lg'
  required?: boolean
  disabled?: boolean
  errorText?: string
  helperText?: string
  autoComplete?: boolean
}): JSX.Element {
  return (
    <FormControl>
      {label ? (
        <FormLabel>
          <Typography level="title-sm">
            <strong>{label}</strong>
          </Typography>
        </FormLabel>
      ) : null}
      <Textarea
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onClick={onClick}
        minRows={minRows}
        maxRows={maxRows}
        autoComplete={autoComplete ? undefined : 'new-password'}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        size={size}
        error={!!errorText}
      />
      <FormHelperText>{errorText || helperText}</FormHelperText>
    </FormControl>
  )
}
