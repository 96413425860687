import React from 'react'
import Grid from '@mui/material/Grid'
import AttributeSelect from '../../../common/AttributeSelect'
import { getAttributeName, getAttributeTag } from '../../../../utils/functions'
import { GetProductTemplate } from '../../../../api/product'
import SingleSelect from '../../../common/SingleSelect'
import MultipleSelect from '../../../common/MultipleSelect'
import { ProductDescriptionEditor } from '../../../../pages/products/ProductDescriptionEditor'
import Tooltip from '@mui/joy/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import SvgIcon from '@mui/material/SvgIcon'
import { GenericValueMapping } from '../../ProductTitleDescription'

export function AttributeValueMapping({
  template,
  valueMapping,
  attributeNames,
  allowedValues,
  onChange,
}: {
  template: GetProductTemplate
  attributeNames: string[]
  allowedValues?: string[]
  valueMapping: GenericValueMapping
  onChange: (valueMapping: GenericValueMapping) => void
}): JSX.Element {
  const attribute = template.attributes.find(
    (a) => a.attribute.id === valueMapping.attributeId,
  )

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item md={3} sm={12} xs={12}>
        <AttributeSelect
          size="sm"
          placeholder="Select Attribute"
          value={
            attribute ? getAttributeTag(attribute.attribute.name) : undefined
          }
          attributeNames={attributeNames}
          onChange={(attributeTag) => {
            const attributeName = getAttributeName(attributeTag)

            if (!attributeName) return
            const attribute = template?.attributes.find(
              (a) => a.attribute.name === attributeName,
            )

            onChange({
              ...valueMapping,
              attributeId: attribute?.attribute?.id,
            })
          }}
          freeSolo={true}
          multiple={false}
        />
      </Grid>
      <Grid item md={2} sm={2} xs={6}>
        <SingleSelect
          value={valueMapping.condition || ''}
          options={['contains', "doesn't contain", 'equals', "doesn't equal"]}
          disabled={!valueMapping.attributeId}
          defaultOption={false}
          disableClearIndicator={true}
          onChange={(value) => {
            onChange({
              ...valueMapping,
              condition: value,
            })
          }}
        />
      </Grid>
      <Grid item md={3} sm={4} xs={6}>
        <MultipleSelect
          value={valueMapping.values?.map((v) => v.value) || []}
          disabled={!valueMapping.condition}
          freeSolo={true}
          onChange={(values) => {
            onChange({
              ...valueMapping,
              values: values.map((v) => ({ value: v })),
            })
          }}
        />
      </Grid>
      <Grid item md={2} sm={2} xs={6}>
        <SingleSelect
          value={valueMapping.function || ''}
          options={
            valueMapping.condition === 'contains'
              ? ['replace with', 'use value']
              : ['use value']
          }
          disableClearIndicator={true}
          endDecorator={
            <Tooltip
              title={
                valueMapping.function === 'use value'
                  ? 'Replaces the full attribute value.'
                  : 'Replaces the "contains" values only.'
              }
            >
              <SvgIcon
                fontSize="small"
                color="primary"
                sx={{ opacity: 0.5, height: '18px' }}
              >
                <HelpOutlineIcon fontSize="inherit" />
              </SvgIcon>
            </Tooltip>
          }
          defaultOption={false}
          onChange={(value) => {
            onChange({
              ...valueMapping,
              function: value,
            })
          }}
        />
      </Grid>

      <Grid item md={2} sm={4} xs={6}>
        {allowedValues?.length ? (
          <SingleSelect
            value={valueMapping.value || ''}
            options={allowedValues}
            onChange={(value) => {
              onChange({
                ...valueMapping,
                value: value || '',
              })
            }}
          />
        ) : (
          <ProductDescriptionEditor
            key={valueMapping.index}
            richText={true}
            addAttributes={false}
            value={valueMapping.value}
            attributeNames={attributeNames}
            oneLine={true}
            onChange={(value) => {
              onChange({
                ...valueMapping,
                value: value || '',
              })
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}
