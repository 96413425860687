import * as React from 'react'
import { ThemeOptions } from '@mui/material/styles'
import { orange } from '@mui/material/colors'
import AppContext, { appStateReducer, defaultAppState } from './AppContext'
import { useReducer } from 'react'
import AppRouter from './AppRouter'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { teal, purple } from '@mui/material/colors'
import './index.css'
import GlobalStyles from '@mui/joy/GlobalStyles'
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { extendTheme } from '@mui/joy/styles'

const joyTheme = extendTheme({
  components: {
    JoyButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          zIndex: 100,
        },
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#e0f2f1',
          '100': '#b2dfdb',
          '200': '#80cbc4',
          '300': '#4db6ac',
          '400': '#26a69a',
          '500': '#02ad97',
          '600': '#00897b',
          '700': '#00796b',
          '800': '#00695c',
          '900': '#004d40',
        },
      },
    },
    dark: {
      palette: {
        primary: teal,
      },
    },
  },
})

const materialTheme = materialExtendTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: teal,
    secondary: purple,
  },
} as ThemeOptions)

function App(): JSX.Element {
  const [appState, appReducer] = useReducer(appStateReducer, defaultAppState)

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <AppContext.Provider value={{ appState, appReducer }}>
          <MaterialCssVarsProvider
            theme={{ [MATERIAL_THEME_ID]: materialTheme }}
          >
            <JoyCssVarsProvider theme={joyTheme}>
              <CssBaseline enableColorScheme />
              <GlobalStyles
                styles={{
                  // CSS object styles
                  html: {
                    // ...
                  },
                  body: {
                    backgroundColor: '#F8F9FA',
                  },
                }}
              />
              <AppRouter></AppRouter>
            </JoyCssVarsProvider>
          </MaterialCssVarsProvider>
        </AppContext.Provider>
      </DndProvider>
    </>
  )
}

export default App
