import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import React, { useEffect, useState } from 'react'
import ProductIntegrationSetting from './ProductIntegrationSetting'
import {
  ChannelsProductIntegrations,
  IntegrationName,
  ProductIntegrationType,
} from '../../../api/integration'
import { EbayProductIntegration } from '../../../types/Ebay.types'
import CircularProgress from '@mui/joy/CircularProgress'
import SquareCategorySelect from '../../../components/product/integrations/square/SquareCategorySelect'
import ShopifyCollectionSelect from '../../../components/product/integrations/shopify/ShopifyCollectionSelect'
import BigCommerceCategorySelect from '../../../components/product/integrations/bigcommerce/BigCommerceCategorySelect'
import {
  BigCommerceCategory,
  BigCommerceProductIntegration,
} from '../../../types/BigCommerce.types'
import {
  ShopifyCustomCollection,
  ShopifyProductIntegration,
} from '../../../types/Shopify.types'
import { EtsyProductIntegration } from '../../../types/Etsy.types'
import { SquareProductIntegration } from '../../../types/Square.types'
import { CloverProductIntegration } from '../../../types/Clover.types'
import { CSVProductIntegration } from '../../../types/CSV.types'
import {
  copy,
  err,
  getSelectedProductIntegration,
} from '../../../utils/functions'
import LoadingBox from '../../../components/common/LoadingBox'
import { getBigCommerceCategories } from '../../../api/integrations/bigcommerce'
import { getSquareCategories } from '../../../api/integrations/square'
import { SquareCatalogObjectChildren } from '../../../components/product/integrations/square/SquareIntegrationsComponent'
import { getShopifyCollections } from '../../../api/integrations/shopify'
import SingleSelect from '../../../components/common/SingleSelect'

export default function ProductListingSettings({
  productId,
  channelsProductIntegrations: defaultProductIntegrations,
  onChange,
}: {
  productId: number
  channelsProductIntegrations: ChannelsProductIntegrations
  onChange: (channelsProductIntegrations: ChannelsProductIntegrations) => void
}): JSX.Element {
  // Fetch Categories
  const [bigcommerceCategories, setBigCommerceCategories] = useState<
    BigCommerceCategory[] | undefined
  >(undefined)
  const [squareCategories, setSquareCategories] = useState<
    SquareCatalogObjectChildren[] | undefined
  >(undefined)
  const [shopifyCollections, setShopifyCollections] = useState<
    ShopifyCustomCollection[] | undefined
  >(undefined)

  const [channelsProductIntegrations, setChannelsProductIntegrations] =
    useState<ChannelsProductIntegrations | undefined>(
      defaultProductIntegrations,
    )

  const [update, setUpdate] = useState<number>(0)

  useEffect(() => {
    getBigCommerceCategories()
      .then((res) => {
        if (res.success && res.data) {
          setBigCommerceCategories(res.data)
        }
      })
      .catch((e) => {
        setBigCommerceCategories([])
        err(e)
      })

    getSquareCategories()
      .then((res) => {
        if (res.success && res.data) {
          setSquareCategories(res.data)
        }
      })
      .catch((e) => {
        err(e)
        setSquareCategories([])
      })

    getShopifyCollections()
      .then((res) => {
        if (res.success && res.data) {
          setShopifyCollections(res.data)
        }
      })
      .catch((e) => {
        setShopifyCollections([])
        err(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleProductIntegrationUpdate = (
  //   channel: IntegrationName,
  //   productIntegration: unknown,
  // ) => {

  //   channel, productIntegration
  //   // updateProductIntegration(channel, productIntegration)
  //   //   .then((res) => {
  //   //     if (res.success && res.data) {
  //   //       handleChannelProductIntegrationUpdate(channel, res.data)
  //   //     }
  //   //   })
  //   //   .catch((e) => err(e))
  // }

  if (!channelsProductIntegrations) {
    return <LoadingBox />
  }

  const handleChannelProductIntegrationUpdate = <T extends IntegrationName>(
    channel: T,
    channelProductIntegrations: ProductIntegrationType<T>[] | undefined,
  ) => {
    if (!channelsProductIntegrations) return
    const newChannelsProductIntegrations = copy(channelsProductIntegrations)

    console.log(channel, channelProductIntegrations)

    if (channel === IntegrationName.EBAY) {
      newChannelsProductIntegrations.ebay =
        channelProductIntegrations as EbayProductIntegration[]
    } else if (channel === IntegrationName.ETSY) {
      newChannelsProductIntegrations.etsy =
        channelProductIntegrations as EtsyProductIntegration[]
    } else if (channel === IntegrationName.SQUARE) {
      newChannelsProductIntegrations.square =
        channelProductIntegrations as SquareProductIntegration[]
    } else if (channel === IntegrationName.BIG_COMMERCE) {
      newChannelsProductIntegrations.bigcommerce =
        channelProductIntegrations as BigCommerceProductIntegration[]
    } else if (channel === IntegrationName.SHOPIFY) {
      newChannelsProductIntegrations.shopify =
        channelProductIntegrations as ShopifyProductIntegration[]
    } else if (channel === IntegrationName.CLOVER) {
      newChannelsProductIntegrations.clover =
        channelProductIntegrations as CloverProductIntegration[]
    } else if (channel === IntegrationName.CSV) {
      newChannelsProductIntegrations.csv =
        channelProductIntegrations as CSVProductIntegration[]
    }

    handleChange(newChannelsProductIntegrations)
  }

  const handleChange = (
    newChannelsProductIntegrations: ChannelsProductIntegrations,
  ) => {
    setChannelsProductIntegrations(newChannelsProductIntegrations)
    setUpdate(update + 1)
    onChange(newChannelsProductIntegrations)
  }

  const handleSelectProductIntegrationCategory = <T extends IntegrationName>(
    channel: T,
    productIntegrations: ProductIntegrationType<T>[],
    values: (string | number)[],
  ) => {
    if (!productIntegrations?.length) return
    const newChannelsProductIntegration = copy(channelsProductIntegrations)
    const newProductIntegrations = productIntegrations.slice()

    const selectedProductIntegration =
      getSelectedProductIntegration(productIntegrations)
    if (!selectedProductIntegration) return

    const selectedIndex = productIntegrations.findIndex(
      (p) => p.name === selectedProductIntegration?.name,
    )

    if (channel === IntegrationName.SQUARE) {
      newProductIntegrations[selectedIndex] = {
        ...selectedProductIntegration,
        categoryIds: values?.length ? values.join(',') : '',
      }
      newChannelsProductIntegration[IntegrationName.SQUARE] =
        newProductIntegrations as SquareProductIntegration[]
    } else if (channel === IntegrationName.SHOPIFY) {
      const collections = shopifyCollections?.filter((c) =>
        values.includes(c.title),
      )

      newProductIntegrations[selectedIndex] = {
        ...selectedProductIntegration,
        collectionIds: values?.length
          ? collections?.map((c) => c.id).join(',')
          : '',
      }
      newChannelsProductIntegration[IntegrationName.SHOPIFY] =
        newProductIntegrations as ShopifyProductIntegration[]
    } else if (channel === IntegrationName.BIG_COMMERCE) {
      newProductIntegrations[selectedIndex] = {
        ...selectedProductIntegration,
        categoryIds: values.length ? values.join(',') : '',
      }

      newChannelsProductIntegration[IntegrationName.BIG_COMMERCE] =
        newProductIntegrations as BigCommerceProductIntegration[]
    }

    // console.log('New', newChannelsProductIntegration)
    setChannelsProductIntegrations(newChannelsProductIntegration)
    handleChange(newChannelsProductIntegration)
  }

  // handle select, handle update, handle categories

  if (!channelsProductIntegrations) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography level="h4">Listing Settings</Typography>
        <Typography level="body-xs">
          Configure Channel Template and list/sync settings per channel.
        </Typography>
      </Grid>

      {!Object.values(channelsProductIntegrations).flat()?.length ? (
        <Grid item xs={12}>
          <Typography level="title-md">
            <strong>No Channel Templates</strong>
          </Typography>
          <Typography level="body-sm">
            Configure under the{' '}
            <a href={`/product/${productId}/channels`}>Channels</a> tab
          </Typography>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <ProductIntegrationSetting
          channel={IntegrationName.EBAY}
          name="Ebay"
          productIntegrations={channelsProductIntegrations.ebay}
          onChange={(p) =>
            handleChannelProductIntegrationUpdate(IntegrationName.EBAY, p)
          }
          disableCategorySelect={true}
          getCategoryName={(p) => p?.ebayCategoryName || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductIntegrationSetting
          channel={IntegrationName.ETSY}
          name="Etsy"
          productIntegrations={channelsProductIntegrations.etsy}
          onChange={(p) =>
            handleChannelProductIntegrationUpdate(IntegrationName.ETSY, p)
          }
          getCategoryName={(p) => p?.taxonomyName || ''}
          disableCategorySelect={true}
        />
      </Grid>

      {channelsProductIntegrations.square?.length ? (
        <Grid item xs={12}>
          <ProductIntegrationSetting
            channel={IntegrationName.SQUARE}
            name="Square"
            productIntegrations={channelsProductIntegrations.square}
            onChange={(p) =>
              handleChannelProductIntegrationUpdate(IntegrationName.SQUARE, p)
            }
            CategorySelect={(_props) =>
              squareCategories ? (
                <SquareCategorySelect
                  categories={squareCategories}
                  onBlur={(values) =>
                    handleSelectProductIntegrationCategory(
                      IntegrationName.SQUARE,
                      channelsProductIntegrations.square,
                      values,
                    )
                  }
                  value={
                    getSelectedProductIntegration<IntegrationName.SQUARE>(
                      channelsProductIntegrations.square,
                    )
                      ?.categoryIds?.split(',')
                      .map((c: string) => c) || []
                  }
                />
              ) : (
                <CircularProgress size="sm" />
              )
            }
          />
        </Grid>
      ) : undefined}

      <Grid item xs={12}>
        <ProductIntegrationSetting
          channel={IntegrationName.SHOPIFY}
          name="Shopify"
          productIntegrations={channelsProductIntegrations.shopify}
          onChange={(p) =>
            handleChannelProductIntegrationUpdate(IntegrationName.SHOPIFY, p)
          }
          CategorySelect={(_props) =>
            shopifyCollections ? (
              <ShopifyCollectionSelect
                collections={shopifyCollections}
                value={
                  getSelectedProductIntegration<IntegrationName.SHOPIFY>(
                    channelsProductIntegrations.shopify,
                  )
                    ?.collectionIds?.split(',')
                    ?.map((v) => parseInt(v)) || []
                }
                onBlur={(values) =>
                  handleSelectProductIntegrationCategory(
                    IntegrationName.SHOPIFY,
                    channelsProductIntegrations.shopify,
                    values,
                  )
                }
              />
            ) : (
              <CircularProgress size="sm" />
            )
          }
        />
      </Grid>

      <Grid item xs={12}>
        <ProductIntegrationSetting
          channel={IntegrationName.BIG_COMMERCE}
          name="Big Commerce"
          productIntegrations={channelsProductIntegrations.bigcommerce}
          onChange={(p) =>
            handleChannelProductIntegrationUpdate(
              IntegrationName.BIG_COMMERCE,
              p,
            )
          }
          CategorySelect={(_props) =>
            bigcommerceCategories ? (
              <BigCommerceCategorySelect
                categories={bigcommerceCategories}
                // value={values?.map((v) => parseInt(v)) || []}
                value={
                  getSelectedProductIntegration<IntegrationName.BIG_COMMERCE>(
                    channelsProductIntegrations.bigcommerce,
                  )
                    ?.categoryIds?.split(',')
                    ?.map((v) => parseInt(v)) || []
                }
                onBlur={(values) =>
                  handleSelectProductIntegrationCategory(
                    IntegrationName.BIG_COMMERCE,
                    channelsProductIntegrations.bigcommerce,
                    values,
                  )
                }
              />
            ) : (
              <CircularProgress size="sm" />
            )
          }
        />
      </Grid>

      <Grid item xs={12}>
        <ProductIntegrationSetting
          channel={IntegrationName.CSV}
          name="CSV"
          productIntegrations={channelsProductIntegrations.csv}
          onChange={(p) =>
            handleChannelProductIntegrationUpdate(IntegrationName.CSV, p)
          }
          CategorySelect={(_props) => (
            <SingleSelect value="" options={[]} disabled={true} />
          )}
        />
      </Grid>
    </Grid>
  )
}
