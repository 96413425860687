import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../common/Alert'
import { SEC_TO_MS } from '../../utils/constants'
import { GetProduct, GetProductTemplate } from '../../api/product'
import Tabs from '../common/Tabs'
import EbayIntegrationsComponent from './integrations/ebay/EbayIntegrationsComponent'
import SquareIntegrationsComponent from './integrations/square/SquareIntegrationsComponent'
import { useHistory, useParams } from 'react-router-dom'
import {
  IntegrationDisplayName,
  IntegrationDisplayNameToName,
  IntegrationName,
} from '../../api/integration'
import EtsyIntegrationsComponent from './integrations/etsy/EtsyIntegrationsComponent'
import ShopifyIntegrationsComponent from './integrations/shopify/ShopifyIntegrationsComponent'
import BigCommerceIntegrationsComponent from './integrations/bigcommerce/BigCommerceIntegrationsComponent'
import CloverIntegrationsComponent from './integrations/clover/CloverIntegrationsComponent'
import CSVIntegrationsComponent from './integrations/csv/CSVIntegrationsComponent'

interface ProductIntegrationsProps {
  product: GetProduct
  template: GetProductTemplate
}
export default function ProductIntegrations({
  product,
  template,
}: ProductIntegrationsProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [errorMessage, setErrorMessage] = useState('')

  const { channel } = useParams<{
    productId?: string
    tab?: string
    channel?: string
  }>()
  const history = useHistory()

  const selectedTab =
    IntegrationDisplayName[channel as IntegrationName] || 'Ebay'
  const setSelectedChannel = (channel: string) => {
    const channelName = IntegrationDisplayNameToName[channel] || 'ebay'
    history.push(`/product/${product.product.id}/channels/${channelName}`, {
      shallow: true,
    })
  }
  return (
    <>
      {/* <Box p={3}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-md">
              <strong>Bulk Actions</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined">
              List
            </Button>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined">
              Sync
            </Button>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined">
              Sync Quantity
            </Button>
          </Grid>
        </Grid>
      </Box> */}

      <Tabs
        tabs={[
          'Ebay',
          'Etsy',
          'Square',
          'Shopify',
          'Big Commerce',
          'Clover',
          'CSV',
          // 'Bulk View',
        ]}
        selected={selectedTab}
        onSelect={setSelectedChannel}
        panels={[
          <EbayIntegrationsComponent
            key={IntegrationName.EBAY}
            template={template}
            product={product}
          />,
          <EtsyIntegrationsComponent
            key={IntegrationName.ETSY}
            template={template}
            product={product}
          />,
          <SquareIntegrationsComponent
            key={IntegrationName.SQUARE}
            template={template}
            product={product}
          />,
          <ShopifyIntegrationsComponent
            key={IntegrationName.SHOPIFY}
            product={product}
            template={template}
          />,
          <BigCommerceIntegrationsComponent
            key={IntegrationName.BIG_COMMERCE}
            template={template}
            product={product}
          />,
          <CloverIntegrationsComponent
            key={IntegrationName.CLOVER}
            template={template}
            product={product}
          />,
          // <BulkProductIntegrations key="bulk" product={product} />,
          <CSVIntegrationsComponent
            key={IntegrationName.CSV}
            template={template}
            product={product}
          />,
        ]}
      />

      {errorMessage ? (
        <Box p={3}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              color="danger"
              onClick={() => setErrorMessage('')}
            >
              Close
            </Button>
          </Grid>
          <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
        </Box>
      ) : null}
      <Alert
        alert={alert}
        duration={10 * SEC_TO_MS}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </>
  )
}
