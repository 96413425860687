import React, { useEffect, useState } from 'react'
import qs from 'qs'
import {
  IntegrationDisplayName,
  IntegrationName,
  authenticateOAuth,
  getOAuthUrl,
} from '../../api/integration'
import { err } from '../../utils/functions'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/joy/Typography'
import { AxiosError } from 'axios'
import { APIResult } from '../../utils/types'
import { useParams } from 'react-router-dom'
import Alert, { AlertInput } from '../../components/common/Alert'
import CircularProgress from '@mui/joy/CircularProgress'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Grid from '@mui/material/Grid'

// Get query params outside of component to avoid re-renders
const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

export default function OAuthHandler(): JSX.Element | null {
  const { channel, dev } = useParams<{ channel: string; dev?: string }>()
  const integrationName = channel as IntegrationName

  const [loading, setLoading] = useState<boolean>(true)
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [error, setError] = useState<string>('')

  // redirect to localhost when in development
  if (dev) {
    const path = `/oauth/${channel}`
    const search = window.location.search
    const localhost = 'http://localhost:3001'
    window.location.href = localhost + path + search
  }

  useEffect(() => {
    // oauth
    setLoading(true)
    if (channel === IntegrationName.BIG_COMMERCE && params) {
      authenticateOAuth(integrationName, params)
        .then((res) => {
          setLoading(false)
          if (res.success) {
            setAlert({
              open: true,
              message: `Integration successful! Closing window...`,
              severity: 'success',
            })
            setTimeout(() => {
              window.close()
            }, 2000)
          } else {
            setError(res.message || '')
          }
        })
        .catch((e: unknown) => {
          setLoading(false)
          const errorMessage = (e as AxiosError<APIResult>)?.response?.data
            ?.message
          if (errorMessage) {
            setError(errorMessage)
          }
        })
      return
    }

    // All other channels

    if (!params.code) {
      // No code, authenticate the request and redirect to authUrl
      getOAuthUrl(integrationName, params)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            const url = res.data
            try {
              new URL(url)
            } catch {
              setError('Did not receive valid redirect URL from server.')
            }
            window.location.href = url
          } else {
            setError(res.message || '')
          }
        })

        .catch((e) => {
          setLoading(false)
          err(e)
        })
    } else if (params) {
      authenticateOAuth(integrationName, params)
        .then((res) => {
          setLoading(false)
          if (res.success) {
            setAlert({
              open: true,
              message: `Integration successful! Closing window...`,
              severity: 'success',
            })
            setTimeout(() => {
              window.close()
            }, 2000)
          } else {
            setError(res.message || '')
          }
        })
        .catch((e: unknown) => {
          setLoading(false)
          const errorMessage = (e as AxiosError<APIResult>)?.response?.data
            ?.message
          if (errorMessage) {
            setError(errorMessage)
          }
        })
    }
  }, [channel, integrationName])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container>
        <div
          style={{
            padding: '1em',
            paddingTop: '4em',
          }}
        >
          <Component p={6}>
            <Typography>
              <Typography
                level="h4"
                endDecorator={
                  loading ? (
                    <CircularProgress color="neutral" size="sm" />
                  ) : undefined
                }
              >
                Authorizing with {IntegrationDisplayName[integrationName] || ''}
              </Typography>
            </Typography>
            <Box>
              {error ? (
                <>
                  <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    sx={{ mt: 4 }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        textAlign="center"
                        justifyContent="center"
                        level="body-md"
                        color="danger"
                        variant="soft"
                        component="div"
                        sx={{ p: 1 }}
                        startDecorator={<InfoOutlined />}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: error,
                          }}
                        />{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Box>
          </Component>
        </div>
      </Container>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
