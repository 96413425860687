import React, { useState } from 'react'
import {
  EbayDomesticShippingServicesEnum,
  EbayDomesticShippingServicesMap,
  EbayHandlingTimeEnum,
  FulfillmentPolicy,
  ListingPolicies,
  MarketplaceIdEnum,
  PaymentPolicy,
  ReturnPolicy,
} from '../../../../types/Ebay.types'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import Box from '@mui/joy/Box'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/joy/Tabs'
import {
  Button,
  Checkbox,
  ListItemContent,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Typography,
} from '@mui/joy'
import FloatLabelInput from '../../../common/FloatLabelInput'
import FloatLabelTextarea from '../../../common/FloatLabelTextarea'
import SingleSelect from '../../../common/SingleSelect'
import MultipleSelect from '../../../common/MultipleSelect'

export default function EbayPoliciesModal({
  policies,
  open,
  onClose,
  onChange,
}: {
  policies: ListingPolicies | undefined
  open: boolean
  onClose: () => void
  onChange: (policies: ListingPolicies) => void
}): JSX.Element {
  const [paymentPolicies, setPaymentPolicies] = useState<PaymentPolicy[]>(
    policies?.paymentPolicies || [],
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [returnPolicies, setReturnPolicies] = useState<ReturnPolicy[]>(
    policies?.returnPolicies || [],
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fulfillmentPolicies, setFulfillmentPolicies] = useState<
    FulfillmentPolicy[]
  >(policies?.fulfillmentPolicies || [])

  const handlePaymentPolicyUpdate = (
    newPolicy: PaymentPolicy,
    index: number | undefined,
  ) => {
    const newPaymentPolicies = paymentPolicies.slice()
    if (index === undefined) {
      newPaymentPolicies.push(newPolicy)
    } else if (index >= 0 && index < newPaymentPolicies.length) {
      newPaymentPolicies[index] = newPolicy
    }
    setPaymentPolicies(newPaymentPolicies)
    handleChange()
  }

  // const handleReturnPolicyUpdate = (
  //   newPolicy: ReturnPolicy,
  //   index: number | undefined,
  // ) => {
  //   const newReturnPolicies = returnPolicies.slice()
  //   if (index === undefined) {
  //     newReturnPolicies.push(newPolicy)
  //   } else if (index >= 0 && index < newReturnPolicies.length) {
  //     newReturnPolicies[index] = newPolicy
  //   }
  //   setReturnPolicies(newReturnPolicies)
  //   handleChange()
  // }

  // const handleFulfillmentPolicyUpdate = (
  //   newPolicy: FulfillmentPolicy,
  //   index: number | undefined,
  // ) => {
  //   const newFulfillment = fulfillmentPolicies.slice()
  //   if (index === undefined) {
  //     newFulfillment.push(newPolicy)
  //   } else if (index >= 0 && index < newFulfillment.length) {
  //     newFulfillment[index] = newPolicy
  //   }
  //   setFulfillmentPolicies(newFulfillment)
  //   handleChange()
  // }

  const handleChange = () => {
    onChange({
      paymentPolicies,
      returnPolicies,
      fulfillmentPolicies,
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog minWidth="md" sx={{ overflow: 'auto' }}>
        <ModalClose />
        <Typography level="h4">Listing Policies</Typography>

        <Box pt={3} pb={16}>
          <Tabs defaultValue="payment">
            <TabList>
              <Tab value="payment">
                <ListItemContent>Payment</ListItemContent>
              </Tab>
              <Tab value="refund">
                <ListItemContent>Refund</ListItemContent>
              </Tab>
              <Tab value="shipping">
                <ListItemContent>Shipping</ListItemContent>
              </Tab>
            </TabList>
            <TabPanel value="payment">
              <Grid container justifyContent="center" spacing={8}>
                {paymentPolicies?.map((payment, i) => {
                  // const cashOnPickUp =
                  //   payment.paymentMethods?.findIndex(
                  //     (m) => m.paymentMethodType === 'CASH_ON_PICKUP',
                  //   ) || -1
                  // const check =
                  //   payment.paymentMethods?.findIndex(
                  //     (m) => m.paymentMethodType === 'PERSONAL_CHECK',
                  //   ) || -1
                  // const moneyOrder =
                  //   payment.paymentMethods?.findIndex(
                  //     (m) => m.paymentMethodType === 'MONEY_ORDER',
                  //   ) || -1

                  return (
                    <Grid item xs={12} key={payment.name}>
                      <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={12}>
                          <FloatLabelInput
                            label="Name"
                            value={payment.name}
                            onChange={(v) => {
                              handlePaymentPolicyUpdate(
                                { ...payment, name: v },
                                i,
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FloatLabelTextarea
                            label="Description"
                            value={payment.description}
                            onChange={(v) => {
                              handlePaymentPolicyUpdate(
                                { ...payment, description: v },
                                i,
                              )
                            }}
                            minRows={3}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography level="title-lg" p={2}>
                            Managed By Ebay
                          </Typography>

                          <Grid
                            container
                            justifyContent="flex-start"
                            spacing={1}
                          >
                            <Grid item xs={12}>
                              <Typography
                                level="title-sm"
                                startDecorator={
                                  <Checkbox
                                    checked={payment.immediatePay}
                                    onChange={() => {
                                      handlePaymentPolicyUpdate(
                                        {
                                          ...payment,
                                          immediatePay: !payment.immediatePay,
                                        },
                                        i,
                                      )
                                    }}
                                  />
                                }
                              >
                                <strong>Require immediate payment</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* <Grid item xs={12}>
                          <Typography level="title-lg" pt={2}>
                            Allow offline payment
                          </Typography>
                          <Typography level="body-xs" pb={2}>
                            Payment methods not managed by eBay
                          </Typography>
                          <Grid
                            container
                            justifyContent="flex-start"
                            spacing={1}
                          >
                            <Grid item xs={12}>
                              <Typography
                                level="title-sm"
                                startDecorator={
                                  <Checkbox
                                    disabled={payment.immediatePay}
                                    checked={cashOnPickUp > -1}
                                    onChange={() => {
                                      const currentMethods =
                                        payment.paymentMethods?.slice()
                                      if(cashOnPickUp < -1){
                                        currentMethods?.push({

                                        })
                                      }
                                      currentMethods?.findIndex(
                                        (m) => m.paymentMethodType === '',
                                      )
                                      handlePaymentPolicyUpdate(
                                        {
                                          ...payment,
                                          paymentMethods: !payment.immediatePay,
                                        },
                                        i,
                                      )
                                    }}
                                  />
                                }
                              >
                                <strong>Cash on Pickup</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                level="title-sm"
                                startDecorator={<Checkbox />}
                              >
                                <strong>Check</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                level="title-sm"
                                startDecorator={<Checkbox />}
                              >
                                <strong>Money Order</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid> */}
                      </Grid>
                    </Grid>
                  )
                })}
                <Grid item xs={12}>
                  <Button
                    variant="soft"
                    color="neutral"
                    sx={{ float: 'right' }}
                    onClick={() => {
                      handlePaymentPolicyUpdate(
                        {
                          categoryTypes: [],
                          description: '',
                          immediatePay: false,
                          marketplaceId: MarketplaceIdEnum.EBAY_US,
                          name: '',
                        },
                        undefined,
                      )
                    }}
                  >
                    Add Policy
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="refund">
              <Grid container justifyContent="center" spacing={8}>
                {returnPolicies?.map((returnPolicy) => {
                  return (
                    <Grid item xs={12} key={returnPolicy.name}>
                      <Typography level="title-lg" p={2}>
                        {returnPolicy.name}
                      </Typography>
                      <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={12}>
                          <FloatLabelInput
                            label="Name"
                            value={returnPolicy.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FloatLabelTextarea
                            label="Description"
                            value={returnPolicy.description}
                            minRows={3}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography level="title-lg" p={2}>
                            Domestic Returns
                          </Typography>

                          <Grid
                            container
                            justifyContent="flex-start"
                            spacing={1}
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                justifyContent="space-between"
                                spacing={1}
                              >
                                <Grid item>
                                  <Typography level="title-md">
                                    <strong>Accept returns</strong>
                                  </Typography>
                                  <Typography level="body-xs">
                                    Accept domestic returns
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Switch />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <SingleSelect
                                label="Allowed within"
                                value=""
                                options={['14 days', '30 days', '60 days']}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <SingleSelect
                                label="Return shipping paid by"
                                value=""
                                options={['Buyer', 'Free for buyer, you pay']}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <SingleSelect
                                label="Refund method"
                                value=""
                                options={[
                                  'Money back',
                                  'Money back or replacement',
                                ]}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography level="title-lg" pt={2}>
                            International returns
                          </Typography>
                          <Typography level="body-xs" pb={2}>
                            Payment methods not managed by eBay
                          </Typography>
                          <Grid
                            container
                            justifyContent="space-between"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography level="title-md">
                                <strong>Accept returns</strong>
                              </Typography>
                              <Typography level="body-xs">
                                Accept international returns
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Switch />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </TabPanel>
            <TabPanel value="shipping">
              <Grid container justifyContent="center" spacing={8}>
                {fulfillmentPolicies?.map((fulfillmentPolicy) => {
                  return (
                    <Grid item xs={12} key={fulfillmentPolicy.name}>
                      <Typography level="title-lg" p={2}>
                        {fulfillmentPolicy.name}
                      </Typography>
                      <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={12}>
                          <FloatLabelInput
                            label="Name"
                            value={fulfillmentPolicy.name}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FloatLabelTextarea
                            label="Description"
                            value={fulfillmentPolicy.description}
                            minRows={3}
                          />
                          <Typography
                            level="title-sm"
                            startDecorator={<Checkbox />}
                          >
                            <strong>Set as default shipping policy</strong>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography level="title-lg" p={2}>
                            US Shipping
                          </Typography>

                          <Grid
                            container
                            justifyContent="flex-start"
                            spacing={1}
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                justifyContent="space-between"
                                spacing={1}
                              >
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    spacing={1}
                                  >
                                    {fulfillmentPolicy.shippingOptions
                                      .filter(
                                        (s) => s.optionType === 'DOMESTIC',
                                      )
                                      .map((shippingOption) => {
                                        return (
                                          <Grid
                                            item
                                            xs={12}
                                            key={
                                              fulfillmentPolicy.name +
                                              shippingOption
                                            }
                                          >
                                            <SingleSelect
                                              value={shippingOption.costType}
                                              floatLabel="Shipping Option"
                                              options={[
                                                'Flat: Same cost to all buyers',
                                                'Calculated: Cost varies by location',
                                                'Freight: Large items over 150lbs',
                                                'No Shipping: Local pickup only',
                                              ]}
                                            />

                                            <Grid
                                              container
                                              justifyContent="center"
                                              alignItems="center"
                                              spacing={2}
                                              mt={1}
                                            >
                                              <Grid item xs={12}>
                                                {shippingOption.shippingServices?.map(
                                                  (service) => {
                                                    return (
                                                      <Grid
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        spacing={1}
                                                        key={
                                                          service.shippingServiceCode
                                                        }
                                                      >
                                                        <Grid item xs={6}>
                                                          <SingleSelect
                                                            floatLabel="Services"
                                                            value={
                                                              EbayDomesticShippingServicesMap[
                                                                service
                                                                  .shippingServiceCode
                                                              ] || ''
                                                            }
                                                            options={Object.values(
                                                              EbayDomesticShippingServicesEnum,
                                                            )}
                                                          />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                          <FloatLabelInput
                                                            label="Cost"
                                                            type="number"
                                                            value={
                                                              service
                                                                .shippingCost
                                                                .value
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                          <FloatLabelInput
                                                            label="Each Additional"
                                                            type="number"
                                                            value={
                                                              service
                                                                .additionalShippingCost
                                                                .value
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                          <Typography
                                                            level="title-sm"
                                                            startDecorator={
                                                              <Checkbox />
                                                            }
                                                          >
                                                            <strong>
                                                              Free Shipping
                                                            </strong>
                                                          </Typography>
                                                        </Grid>
                                                      </Grid>
                                                    )
                                                  },
                                                )}
                                              </Grid>
                                            </Grid>

                                            <Button
                                              sx={{ float: 'right', mt: 1 }}
                                              variant="soft"
                                              color="neutral"
                                            >
                                              Add Service{' '}
                                            </Button>
                                          </Grid>
                                        )
                                      })}
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <SingleSelect
                                    floatLabel="Handling Time"
                                    value={
                                      fulfillmentPolicy.handlingTime?.value ||
                                      ''
                                    }
                                    options={Object.values(
                                      EbayHandlingTimeEnum,
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography level="title-lg" p={2}>
                            International shipping
                          </Typography>
                          <Grid
                            container
                            justifyContent="flex-start"
                            spacing={1}
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                justifyContent="space-between"
                                spacing={1}
                              >
                                <Grid item xs={12}>
                                  <SingleSelect
                                    value=""
                                    floatLabel="Shipping Option"
                                    options={[
                                      'Flat: Same cost to all buyers',
                                      'Calculated: Cost varies by location',
                                      'No international shipping',
                                    ]}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <SingleSelect
                                    floatLabel="Ship To"
                                    value=""
                                    options={['Worldwide', 'Custom', 'Canada']}
                                  />
                                </Grid>

                                <Grid item xs={8}>
                                  <SingleSelect
                                    floatLabel="Services"
                                    value=""
                                    options={Object.values(
                                      EbayDomesticShippingServicesEnum,
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MultipleSelect
                                    floatLabel="Ship To"
                                    value={[]}
                                    options={[
                                      'N. and S. America',
                                      'Europe',
                                      'Asia',
                                      'Canada',
                                      'United Kingdom',
                                      'China',
                                      'Mexico',
                                      'Germany',
                                      'Japan',
                                      'Brazil',
                                      'France',
                                      'Australia',
                                      'Russian Federation',
                                    ]}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography level="title-lg" p={2}>
                            Exclude Locations
                          </Typography>
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs={12}>
                              <MultipleSelect
                                floatLabel="Exclude"
                                value={[]}
                                options={[
                                  'Alaska/HawaiiUS',
                                  'ProtectoratesAPO/FPO',
                                  'Africa',
                                  'Asia',
                                  'Central America and Caribbean',
                                  'Europe',
                                  'Middle East',
                                  'North America',
                                  'Oceania',
                                  'South America',
                                  'Southeast Asia',
                                  'PO Box',
                                ]}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </TabPanel>
          </Tabs>

          <Grid container justifyContent="center" spacing={2}></Grid>
        </Box>
      </ModalDialog>
    </Modal>
  )
}
