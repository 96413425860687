import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { Link } from 'react-router-dom'
import { IntegrationDisplayName } from '../../../api/integration'

export default function UnauthenticatedIntegration({
  channelName,
}: {
  channelName: string
}): JSX.Element {
  return (
    <Box p={8}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography level="title-lg">Integration Unauthenticated</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Reauthenticate in{' '}
            <Link to={`/integrations/${channelName}`}>
              {IntegrationDisplayName[channelName]} Integration
            </Link>{' '}
            settings
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
