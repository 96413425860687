import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'
import DisconnectIntegration from '../../components/integrations/DisconnectIntegration'
import LockResetIcon from '@mui/icons-material/LockReset'
import InputContainer from '../../components/common/InputContainer'

export default function SquareIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.SQUARE>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  return (
    <Grid container spacing={6}>
      {integration.integration?.shop ? (
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            p={2}
            sx={{
              border: '1px solid #e8e9eb',
              borderRadius: '6px',
              boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
              color: '#32383E',
              backgroundColor: '#FBFCFE',
            }}
          >
            <Grid item xs={12}>
              <Typography level="title-lg">Store Details</Typography>
              <Typography level="body-sm">
                Store ID:{' '}
                {integration.integration?.shop || 'Store name not set.'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="solid" onClick={handleAppStoreClick}>
                Open Store
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <InputContainer>
          <Grid container justifyContent="center" spacing={2}>
            {integration.authenticated ? (
              <>
                <Grid item xs={12}>
                  <Typography level="title-lg">Authentication</Typography>
                  <Typography level="body-xs">
                    Re-authorize by re-installing the the Sellmotto app from the
                    Square App Marketplace
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="solid"
                    onClick={handleAppStoreClick}
                    endDecorator={<LockResetIcon />}
                  >
                    Re-Authorize
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography level="body-xs">
                    Authentication Last Updated:{' '}
                    {integration.integration?.updatedAt
                      ? new Date(
                          integration.integration?.updatedAt,
                        ).toLocaleString()
                      : 'Unknown'}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography level="title-lg">Authentication</Typography>
                  <Typography level="body-sm">
                    To authenticate app access, add the Sellmotto Square app to
                    your store.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="solid" onClick={handleAppStoreClick}>
                    Open
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </InputContainer>
      </Grid>

      {integration?.integration && integration?.authenticated ? (
        <Grid item xs={12}>
          <DisconnectIntegration
            integration={integration.integration}
            channel={IntegrationName.SQUARE}
            setAlert={setAlert}
          />
        </Grid>
      ) : undefined}
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
