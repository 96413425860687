import React from 'react'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Dropdown from '@mui/joy/Dropdown'
import MenuButton from '@mui/joy/MenuButton'
import Typography from '@mui/joy/Typography'

interface VerticalIconMenuProps {
  options: string[]
  disabledOptions?: string[]
  onClick?: (
    option: string,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void
  p?: number | string
  size?: 'small' | 'medium' | 'large'
}

export default function VerticalIconMenu({
  options,
  disabledOptions,
  onClick,
  p,
  size,
}: VerticalIconMenuProps): JSX.Element {
  return (
    <Dropdown>
      <MenuButton sx={{ p }} slots={{ root: IconButton }}>
        <MoreVertIcon fontSize={size} color="action" />
      </MenuButton>
      <Menu sx={{ p: 0 }}>
        {options.map((option) => (
          <MenuItem
            key={option}
            data-option={option}
            disabled={disabledOptions?.includes(option)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e) => onClick?.((e.target as any).innerText, e)}
          >
            <Typography level="body-sm">{option}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  )
}
