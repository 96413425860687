import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  CategoryTree,
  EbayTemplateAspectIntegration,
  EbayProductIntegration,
  EbayTemplateIntegration,
  GetEbayIntegrationsResult,
  FulfillmentPolicy,
  InventoryLocation,
  PaymentPolicy,
  ReturnPolicy,
} from '../../../../types/Ebay.types'
import { err, log } from '../../../../utils/functions'
import {
  getEbayCategories,
  GetProduct,
  GetProductTemplate,
} from '../../../../api/product'
import { DataFields } from '../../../../api/types'
import {
  getIntegration,
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'
import {
  getEbayIntegrations,
  getEbayListingPolicies,
  getEbayInventoryLocations,
  setEbayProductIntegrations,
  setEbayTemplateIntegrations,
} from '../../../../api/integrations/ebay'
import Button from '@mui/joy/Button'
import Stack from '@mui/material/Stack'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import LoadingBox from '../../../common/LoadingBox'
import MissingIntegration from '../MissingIntegration'
import MissingTemplateIntegration from '../MissingTemplateIntegration'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import Box from '@mui/joy/Box'
import Add from '@mui/icons-material/Add'
import FloatLabelInput from '../../../common/FloatLabelInput'
import EbayIntegrationConfigurationComponent from './EbayIntegrationConfigurationComponent'
import CircularProgress from '@mui/joy/CircularProgress'

export type AspectIntegrationInput = Omit<
  DataFields<EbayTemplateAspectIntegration>,
  'ebayTemplateIntegrationId' | 'type'
>

interface EbayIntegrationsComponentProps {
  integration?: GetIntegrationResult<IntegrationName.EBAY>
  template: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}
export default function EbayIntegrationsComponent({
  integration: defaultIntegration,
  template,
  product,
  onUpdate,
}: EbayIntegrationsComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(!defaultIntegration)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<IntegrationName.EBAY> | undefined
  >(defaultIntegration)
  const [templateIntegration, setTemplateIntegration] = useState<
    EbayTemplateIntegration | undefined
  >()
  const [templateIntegrations, setTemplateIntegrations] = useState<
    EbayTemplateIntegration[]
  >([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [productIntegrations, setProductIntegrations] = useState<
    EbayProductIntegration[]
  >([])

  const [templateIntegrationsRecord, setTemplateIntegrationsRecord] = useState<
    Record<string, DataFields<EbayTemplateIntegration>>
  >({})

  const [productIntegrationsRecord, setProductIntegrationsRecord] = useState<
    Record<string, DataFields<EbayProductIntegration>> | undefined
  >()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_productIntegration, setProductIntegration] = useState<
    EbayProductIntegration | undefined
  >()

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [detailUpdate, setDetailUpdate] = useState<number>(0)
  const [selectedTab, setSelectedTab] = useState<string>('0')

  const templateId = template?.template.id

  const [addListingConfigurationName, setAddListingConfigurationName] =
    useState<string>('')

  const [categories, setCategories] = useState<CategoryTree | undefined>()
  const [locations, setLocations] = useState<InventoryLocation[] | undefined>()
  const [paymentPolicies, setPaymentPolicies] = useState<
    PaymentPolicy[] | undefined
  >()
  const [returnPolicies, setReturnPolicies] = useState<
    ReturnPolicy[] | undefined
  >()
  const [fulfillmentPolicies, setFulfillmentPolicies] = useState<
    FulfillmentPolicy[] | undefined
  >([])

  useEffect(() => {
    getEbayCategories()
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setCategories(res.data)
        }
      })
      .catch((e) => {
        err(e)
      })

    getEbayListingPolicies()
      .then((res) => {
        if (res.success && res.data) {
          setPaymentPolicies(res.data.paymentPolicies || [])
          setReturnPolicies(res.data.returnPolicies || [])
          setFulfillmentPolicies(res.data.fulfillmentPolicies || [])
        }
      })
      .catch((e) => {
        setPaymentPolicies([])
        setReturnPolicies([])
        setFulfillmentPolicies([])
        err(e)
      })

    getEbayInventoryLocations()
      .then((res) => {
        if (res.success && res.data) {
          setLocations(res.data || [])
        }
      })
      .catch((e) => {
        setLocations([])
        err(e)
      })
  }, [])

  const handleTemplateIntegrationsChange = (
    name: string,
    templateIntegration: EbayTemplateIntegration | undefined,
  ) => {
    const newEbayTemplateIntegrations = templateIntegrationsRecord
    const currentTemplateIntegration = newEbayTemplateIntegrations[name]
    if (!currentTemplateIntegration) return

    if (!templateIntegration) {
      delete newEbayTemplateIntegrations[name]
      setTemplateIntegrationsRecord(newEbayTemplateIntegrations)
      setSelectedTab('0')
      return
    }

    // Index Change
    if (currentTemplateIntegration.index !== templateIntegration.index) {
      const currentIndex = currentTemplateIntegration.index
      const newIndex = templateIntegration.index
      // update indices
      const arr = Object.values(newEbayTemplateIntegrations)
      const temp = arr[newIndex]
      if (!temp) return
      arr[newIndex] = templateIntegration

      temp.index = currentIndex // update index
      arr[currentIndex] = temp
      setTemplateIntegrations(arr)
      updateTemplateIntegrationsRecord(arr)
      setSelectedTab(`${newIndex}`)
      return
    }

    newEbayTemplateIntegrations[name] = templateIntegration

    setTemplateIntegrationsRecord(newEbayTemplateIntegrations)
    return
  }

  const handleProductIntegrationsChange = (
    name: string,
    productIntegration: EbayProductIntegration | undefined,
  ) => {
    if (!productIntegration) return
    const newEbayProductIntegrations = productIntegrationsRecord || {}
    newEbayProductIntegrations[name] = productIntegration
    setProductIntegrationsRecord(newEbayProductIntegrations)
    return
  }

  const handleAddListingConfiguration = () => {
    if (
      !addListingConfigurationName ||
      !integration?.integration?.id ||
      !templateId
    )
      return

    const name = addListingConfigurationName

    // check duplicates
    const nameExists = !!templateIntegrationsRecord[name]
    if (nameExists) {
      setAlert({
        message: `Configuration with name "${name}" already exists.`,
        open: true,
        severity: 'warning',
      })
      return
    }

    console.log('Adding new Template Integration')
    const index = Object.keys(templateIntegrationsRecord).length
    const newEbayTemplateIntegrations = templateIntegrationsRecord
    newEbayTemplateIntegrations[name] = {
      integrationId: integration.integration.id,
      templateId: templateId,
      ebayCategoryId: 0,
      ebayCategoryName: '',
      ebayCategoryVersion: 0,
      title: '',
      subtitle: '',
      description: '',
      condition: '',
      conditionDescription: '',
      brand: '',
      index,
      name,
    }

    console.log(newEbayTemplateIntegrations)

    setTemplateIntegrationsRecord(newEbayTemplateIntegrations)
    setAddListingConfigurationName('')
    setSelectedTab(`${index}`)
  }

  const updateTemplateIntegrationsRecord = (
    templateIntegrations: EbayTemplateIntegration[] | undefined,
  ) => {
    if (!templateIntegrations?.length) {
      setTemplateIntegrationsRecord({})
      return
    }
    const newEbayTemplateIntegrations: Record<string, EbayTemplateIntegration> =
      {}
    templateIntegrations.forEach((templateIntegration, i) => {
      newEbayTemplateIntegrations[templateIntegration.name] =
        templateIntegration
    })
    setTemplateIntegrationsRecord(newEbayTemplateIntegrations)
  }

  const updateProductIntegrationsRecord = (
    productIntegrations: EbayProductIntegration[] | undefined,
  ) => {
    if (!productIntegrations?.length) {
      setProductIntegrationsRecord({})
      return
    }
    const newEbayProductIntegrations: Record<string, EbayProductIntegration> =
      {}
    productIntegrations.forEach((productIntegration) => {
      if (!productIntegration.name) return
      newEbayProductIntegrations[productIntegration.name] = productIntegration
    })

    setProductIntegrationsRecord(newEbayProductIntegrations)
  }

  const handleUpdateTemplateIntegrationName = (
    ebayTemplateIntegration: EbayTemplateIntegration,
    newName: string,
  ) => {
    const name = ebayTemplateIntegration.name
    const newEbayTemplateIntegrations = templateIntegrationsRecord

    const existing = templateIntegrationsRecord[name]
    if (existing) {
      delete newEbayTemplateIntegrations[name]
    }
    newEbayTemplateIntegrations[newName] = {
      ...ebayTemplateIntegration,
      name: newName,
    }

    setTemplateIntegrationsRecord(newEbayTemplateIntegrations)
  }

  const handleSave = () => {
    if (product && templateIntegration?.id && productIntegrationsRecord) {
      const newProductIntegrations = Object.values(
        productIntegrationsRecord,
      ).map((p, i) => ({ ...p, index: i }))
      setEbayProductIntegrations(newProductIntegrations)
        .then((res) => {
          handleAlert(setAlert, res, 'Saved Ebay Product Integration')
          if (res.success && res.data) {
            handleSetState({
              productIntegrations: res.data,
            })
            log(res)
          }
        })
        .catch((e) => err(e))
    }

    if (!integration || !template) return
    const newTemplateIntegrations = Object.values(
      templateIntegrationsRecord,
    ).map((t, i) => ({ ...t, index: i }))
    setEbayTemplateIntegrations(newTemplateIntegrations)
      .then((res) => {
        handleAlert(setAlert, res, 'Saved Ebay Template Integration')
        if (res.success && res.data) {
          handleSetState({
            templateIntegrations: res.data,
          })
          log(res)
        }
      })
      .catch((e) => err(e))
  }

  /* Get Integration and Auth */
  useEffect(() => {
    if (integration) return
    getIntegration(IntegrationName.EBAY)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => log(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Get Template, Product Integrations */
  useEffect(() => {
    if (!integration?.integration?.id) return
    const tid = templateId || product?.template.id
    const productId = product?.product.id

    setLoading(true)
    getEbayIntegrations(integration.integration.id, tid, productId)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          handleSetState(res.data)
        }
      })
      .catch((e) => log(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, templateId, product])

  const handleSetState = ({
    templateIntegrations,
    productIntegrations,
  }: GetEbayIntegrationsResult) => {
    if (templateIntegrations) {
      const templateIntegration =
        templateIntegrations?.find((t) => t.index === 0) ||
        templateIntegrations?.[0]
      setTemplateIntegrations(templateIntegrations || [])
      updateTemplateIntegrationsRecord(templateIntegrations)
      setTemplateIntegration(templateIntegration)
    }

    if (productIntegrations) {
      const productIntegration =
        productIntegrations?.find((t) => t.selected) || productIntegrations?.[0]

      setProductIntegrations(productIntegrations || [])
      updateProductIntegrationsRecord(productIntegrations)
      setProductIntegration(productIntegration)
    }

    setDetailUpdate(detailUpdate + 1)
  }

  if (loading) {
    return <LoadingBox />
  }

  if (!integration?.integration || integration.authenticated === false) {
    return <MissingIntegration />
  }

  if (!loading && product && !templateIntegrations?.length) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.EBAY}
      />
    )
  }

  const count = Object.keys(templateIntegrationsRecord).length

  return (
    <>
      <Grid container justifyContent="center" spacing={3} pt={2} px={0}>
        {/* <Grid item xs={12}>
          <Typography level="h3">Channel Templates</Typography>
        </Grid> */}
        <Grid item xs={12}>
          <Tabs
            size="lg"
            variant="outlined"
            sx={{ backgroundColor: '#fff' }}
            value={selectedTab || '0'}
            onChange={(e, tab) => setSelectedTab(`${tab}`)}
          >
            <TabList
              sx={{
                overflow: 'auto',
                scrollSnapType: 'x mandatory',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {Object.values(templateIntegrationsRecord)
                .sort((a, b) => a.index - b.index)
                .map((templateIntegration) => {
                  if (!templateIntegration) return null
                  const isSelected =
                    !product && `${templateIntegration.index}` === selectedTab

                  return (
                    <Tab
                      sx={{
                        p: isSelected ? 0.5 : undefined,
                        flex: 'none',
                        scrollSnapAlign: 'start',
                      }}
                      disableIndicator={true}
                      value={`${templateIntegration.index}`}
                      key={templateIntegration.name}
                    >
                      {isSelected ? (
                        <Box
                          sx={{
                            overflow: 'hidden',
                          }}
                        >
                          <FloatLabelInput
                            defaultValue={templateIntegration.name}
                            fullWidth={true}
                            onBlur={(e) =>
                              handleUpdateTemplateIntegrationName(
                                templateIntegration,
                                e?.target.value || '',
                              )
                            }
                          ></FloatLabelInput>
                        </Box>
                      ) : (
                        <Typography level="title-md">
                          {templateIntegration.name}
                        </Typography>
                      )}
                    </Tab>
                  )
                })}
              {!product && (
                <Tab
                  variant="plain"
                  disableIndicator
                  value={'__ADD_LISTING_CONFIGURATION'}
                >
                  <Add fontSize="small" color="action" />
                </Tab>
              )}
            </TabList>

            {template &&
              !product &&
              Object.values(templateIntegrationsRecord).map(
                (templateIntegration) => {
                  if (!templateIntegration) return null
                  return (
                    <TabPanel
                      key={templateIntegration.name}
                      value={`${templateIntegration.index}`}
                    >
                      {!categories ||
                      !locations ||
                      !paymentPolicies ||
                      !returnPolicies ||
                      !fulfillmentPolicies ? (
                        <Box p={4}>
                          <CircularProgress />
                        </Box>
                      ) : null}
                      {categories &&
                        locations &&
                        paymentPolicies &&
                        returnPolicies &&
                        fulfillmentPolicies &&
                        template &&
                        !product && (
                          <EbayIntegrationConfigurationComponent
                            templateIntegration={templateIntegration}
                            count={count}
                            template={template}
                            product={product}
                            categories={categories}
                            locations={locations}
                            paymentPolicies={paymentPolicies}
                            returnPolicies={returnPolicies}
                            fulfillmentPolicies={fulfillmentPolicies}
                            onTemplateChange={handleTemplateIntegrationsChange}
                            onUpdate={onUpdate}
                          />
                        )}
                    </TabPanel>
                  )
                },
              )}

            {product &&
              template &&
              productIntegrationsRecord &&
              Object.values(templateIntegrationsRecord).map(
                (templateIntegration) => {
                  const productIntegration =
                    productIntegrationsRecord[templateIntegration.name]

                  return (
                    <TabPanel
                      key={templateIntegration.name}
                      value={`${templateIntegration.index}`}
                    >
                      {!categories ||
                      !locations ||
                      !paymentPolicies ||
                      !returnPolicies ||
                      !fulfillmentPolicies ? (
                        <Box p={4}>
                          <CircularProgress />
                        </Box>
                      ) : null}

                      {categories &&
                        locations &&
                        paymentPolicies &&
                        returnPolicies &&
                        fulfillmentPolicies && (
                          <EbayIntegrationConfigurationComponent
                            templateIntegration={templateIntegration}
                            productIntegration={productIntegration}
                            template={template}
                            product={product}
                            categories={categories}
                            locations={locations}
                            paymentPolicies={paymentPolicies}
                            returnPolicies={returnPolicies}
                            fulfillmentPolicies={fulfillmentPolicies}
                            onTemplateChange={handleTemplateIntegrationsChange}
                            onProductChange={handleProductIntegrationsChange}
                            onUpdate={onUpdate}
                          />
                        )}
                    </TabPanel>
                  )
                },
              )}

            <TabPanel value={'__ADD_LISTING_CONFIGURATION'}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography level="title-lg">New Configuration</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FloatLabelInput
                    label="Configuration Name"
                    placeholder="Configuration Name"
                    value={addListingConfigurationName}
                    onChange={setAddListingConfigurationName}
                    button={
                      <Button
                        sx={{ float: 'right' }}
                        variant="soft"
                        onClick={() => handleAddListingConfiguration()}
                        disabled={!addListingConfigurationName}
                      >
                        Add
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="solid" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Grid>
        <Alert
          alert={alert}
          onClose={() => setAlert({ ...alert, open: false })}
        ></Alert>
      </Grid>
    </>
  )
}
