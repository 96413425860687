import Box from '@mui/joy/Box'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import {
  CountryCodeEnum,
  InventoryLocation,
} from '../../../../types/Ebay.types'
import FloatLabelInput from '../../../common/FloatLabelInput'
import SingleSelect from '../../../common/SingleSelect'
import Button from '@mui/joy/Button'
import Add from '@mui/icons-material/Add'

export default function EbayLocationsModal({
  locations,
  open,
  onClose,
  onChange,
}: {
  locations: InventoryLocation[] | undefined
  open: boolean
  onClose: () => void
  onChange: (locations: InventoryLocation[]) => void
}): JSX.Element {
  const [newLocations, setNewLocations] =
    useState<Partial<InventoryLocation>[]>()

  return (
    <Modal open={open} onClose={() => onClose()}>
      <ModalDialog sx={{ overflow: 'auto' }} minWidth={'md'}>
        <ModalClose />
        <Typography level="h4">Inventory Locations</Typography>
        <Box pt={4} px={1}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={4}>
                {locations?.map((location) => {
                  return (
                    <Grid key={location.merchantLocationKey} item xs={12}>
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            level="title-md"
                            sx={{ float: 'left', mb: 1 }}
                          >
                            <strong>{location.merchantLocationKey}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FloatLabelInput label="Name" value={location.name} />
                        </Grid>
                        <Grid item xs={12}>
                          <FloatLabelInput
                            label="Address 1"
                            value={location.location.address.addressLine1}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FloatLabelInput
                            label="Address 2"
                            value={location.location.address.addressLine2}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FloatLabelInput
                            label="City"
                            value={location.location.address.city}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FloatLabelInput
                            label="Zip Code"
                            type="number"
                            value={location.location.address.postalCode}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FloatLabelInput
                            label="State / Province"
                            value={location.location.address.stateOrProvince}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SingleSelect
                            floatLabel="Country Code"
                            value={location.location.address.country}
                            options={Object.keys(CountryCodeEnum)}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FloatLabelInput
                            label="Phone"
                            placeholder="123-456-7890"
                            value={location.phone}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="soft"
                            color="danger"
                            sx={{ float: 'left' }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>

            {newLocations?.length ? (
              <Grid item xs={12} mt={4}>
                <Typography level="title-lg" pb={2}>
                  New Locations
                </Typography>
                <Grid container justifyContent="center" spacing={8}>
                  {newLocations?.map((location, i) => {
                    return (
                      <Grid
                        key={
                          (location.merchantLocationKey || 'new-location-') + i
                        }
                        item
                        xs={12}
                      >
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12}>
                            <FloatLabelInput
                              label="Location Key"
                              required={true}
                              value={location.merchantLocationKey}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FloatLabelInput
                              label="Name"
                              value={location.name}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FloatLabelInput
                              label="Address 1"
                              value={location.location?.address.addressLine1}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FloatLabelInput
                              label="Address 2"
                              value={location.location?.address.addressLine2}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FloatLabelInput
                              label="City"
                              value={location.location?.address.city}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FloatLabelInput
                              label="Zip Code"
                              type="number"
                              value={location.location?.address.postalCode}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FloatLabelInput
                              label="State / Province"
                              value={location.location?.address.stateOrProvince}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SingleSelect
                              floatLabel="Country Code"
                              value={''}
                              options={Object.keys(CountryCodeEnum)}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FloatLabelInput
                              label="Phone"
                              placeholder="123-456-7890"
                              value={location.phone}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="soft"
                              color="neutral"
                              sx={{ float: 'left' }}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Button
                color="neutral"
                variant="soft"
                endDecorator={<Add sx={{ fontSize: '20px' }} />}
                sx={{ float: 'right' }}
                onClick={() =>
                  setNewLocations((newLocations || [])?.concat({}))
                }
              >
                Add Location
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button sx={{ float: 'right' }}>Save</Button>
            </Grid>
          </Grid>
        </Box>
      </ModalDialog>
    </Modal>
  )
}
