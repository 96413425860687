import React from 'react'
import Button from '@mui/joy/Button'
import HighlightOff from '@mui/icons-material/HighlightOff'
import { err, wait } from '../../utils/functions'
import Tooltip from '@mui/joy/Tooltip'
import { Integration } from '../../api/types'
import { disconnectIntegration, IntegrationName } from '../../api/integration'
import { AlertInput } from '../common/Alert'

const ON_SUCCESS_RELOAD = 2000 //ms
export default function DisconnectIntegration({
  channel,
  integration,
  onSuccess,
  onFailure,
  setAlert,
}: {
  channel: IntegrationName
  integration: Integration
  onSuccess?: () => void
  onFailure?: () => void
  setAlert?: (alert: AlertInput) => void
}): JSX.Element {
  const handleDisconnectIntegration = () => {
    if (!integration?.id) return
    disconnectIntegration(channel, integration.id)
      .then((res) => {
        if (res) {
          onSuccess?.()
          setAlert?.({
            open: true,
            severity: 'success',
            message: 'Disconnected integration. Re-authorize to reconnect.',
          })
          wait(ON_SUCCESS_RELOAD)
            .then(() => {
              window.location.reload()
            })
            .catch((e) => err(e))
        } else {
          setAlert?.({
            open: true,
            severity: 'error',
            message: 'Failed to disconnect integration.',
          })
          onFailure?.()
        }
      })
      .catch((e) => {
        err(e)
        setAlert?.({
          open: true,
          severity: 'error',
          message: 'Encountered error to disconnecting integration.',
        })
        onFailure?.()
      })
  }
  return (
    <Tooltip
      title="Disconnect Integration. Will need to re-authorize to reconnect"
      size="sm"
    >
      <Button
        size="md"
        variant="soft"
        color="danger"
        sx={{ float: 'left' }}
        endDecorator={<HighlightOff />}
        onClick={() => handleDisconnectIntegration()}
      >
        Disconnect
      </Button>
    </Tooltip>
  )
}
