import React, { useEffect, useState } from 'react'
import { err, round } from '../../../../utils/functions'
import PaginatedTable from '../../../common/PaginatedTable'
import Button from '@mui/joy/Button'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import {
  getShopifyProducts,
  shopifyBulkImport,
} from '../../../../api/integrations/shopify'
import { ShopifyProduct } from '../../../../types/Shopify.types'
import SingleSelect from '../../../common/SingleSelect'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Checkbox from '@mui/joy/Checkbox'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import Box from '@mui/joy/Box'
import Tooltip from '@mui/joy/Tooltip'

interface ShopifyImportOffersProps {
  templates?: string[]
}

export default function ShopifyImportProducts({
  templates,
}: ShopifyImportOffersProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [count, setCount] = useState<number>(0)
  const [shopifyProducts, setShopifyProducts] = useState<ShopifyProduct[]>([])

  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>()

  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [sinceId, setSinceId] = useState<number | undefined>(undefined)
  const [pageSize, setPageSize] = useState<number>(10)

  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([])
  const [updateExistingProducts, setUpdateExistingProducts] =
    useState<boolean>(false)

  const handleImport = () => {
    const ids = selectedProductIds
    if (!ids.length) return
    setLoading(true)
    shopifyBulkImport({
      ids,
      template: selectedTemplate,
      updateExistingProducts,
    })
      .then((res) => {
        setLoading(false)
        handleAlert(setAlert, res, 'Import process started.')
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    handleFetchProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFetchProducts = () => {
    setLoading(true)
    const limit = 100
    getShopifyProducts({
      limit,
      sinceId,
    })
      .then((res) => {
        if (res.success && res.data) {
          setCount(res.data.products.length)
          setShopifyProducts(res.data.products)
          setSinceId(res.data.sinceId)
          setLoading(false)
        }
      })
      .catch((e) => err(e))
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }
  const handlePageSizeChange = (newPageSize: number) => {
    setPage(0)
    setPageSize(newPageSize)
  }

  const handleRowClick = (row: ShopifyProduct) => {
    if (!row.variants?.[0]?.sku) return

    const id = row.id
    const newSelectedIds = selectedProductIds.slice()
    const isSelected = selectedProductIds.find(
      (selectedId) => selectedId === id,
    )

    if (isSelected) {
      const newSelectedIds = selectedProductIds.filter(
        (selectedId) => selectedId !== id,
      )

      setSelectedProductIds(newSelectedIds)
    } else {
      newSelectedIds.push(id)
      setSelectedProductIds(newSelectedIds)
    }

    // Set all selected
  }

  const handleSelectAll = () => {
    // Add or remove all

    const allIds = shopifyProducts
      ?.filter((p) => p.variants?.[0]?.sku)
      .map((squareItem) => squareItem.id)
    if (!allIds) return

    const notSelected = allIds?.filter((id) => !selectedProductIds.includes(id))

    if (notSelected?.length) {
      // select all
      const newSelectedIds = selectedProductIds.concat(notSelected)
      setSelectedProductIds(newSelectedIds)
    } else {
      // remove all
      const newSelectedIds = selectedProductIds.filter(
        (selectedSku) => !allIds.includes(selectedSku),
      )
      setSelectedProductIds(newSelectedIds)
    }
  }

  const startIndex = page * pageSize
  const endIndex = Math.min(shopifyProducts.length, startIndex + pageSize)
  const displayProducts = shopifyProducts.slice(startIndex, endIndex)

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography level="title-lg">Shopify Item Import</Typography>
        <Typography level="body-xs">
          Items must have a SKU to be imported.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box p={3}>
          <PaginatedTable
            count={count}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={[5, 10, 25]}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            disabled={loading}
            head={[
              <Checkbox
                key="select"
                onClick={handleSelectAll}
                size="sm"
                sx={{ top: '3px' }}
                checked={
                  shopifyProducts.length
                    ? shopifyProducts?.filter((p) => p.variants?.[0]?.sku)
                        .length === selectedProductIds.length
                    : false
                }
              />,
              'Title',
              'SKU',
              // 'Offer',
              'Status',
              // 'Quantity',
              'Price',
              // 'Listing',
            ]}
            data={displayProducts}
            onRowClick={handleRowClick}
            row={(row, i) => {
              const id = row.id || ''
              const title = row.title
              const sku = row.variants?.[0]?.sku
              const status = row.status
              const variantPrices =
                row?.variants?.map((v) => round(parseFloat(v.price || '0'))) ||
                []
              const priceMin = Math.min(...variantPrices)
              const priceMax = Math.max(...variantPrices)
              const priceRange =
                priceMin === priceMax ? priceMax : `${priceMin} - ${priceMax}`
              // const status = row.
              // const sku = row.inventoryItem.sku
              // const title = row.inventoryItem.product.title

              const isSelected = selectedProductIds.find(
                (selectedId) => selectedId === id,
              )

              const rowArray = [
                !sku ? (
                  <Tooltip title="Item must have SKU">
                    <Checkbox
                      sx={{ p: 0, top: '3px' }}
                      key={id}
                      checked={false}
                      disabled={!sku}
                      size="sm"
                    ></Checkbox>
                  </Tooltip>
                ) : (
                  <Checkbox
                    sx={{ p: 0, top: '3px' }}
                    key={id}
                    checked={!!isSelected}
                    size="sm"
                  ></Checkbox>
                ),
                title,
                sku,
                status,
                priceRange,
              ]

              return rowArray
            }}
          ></PaginatedTable>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <SingleSelect
              label="Import to Template"
              freeSolo={true}
              value={selectedTemplate || ''}
              onChange={(template) => setSelectedTemplate(template)}
              options={templates || []}
              helperText={
                <Typography level="body-xs">
                  Merge items into selected template. Otherwise merge to new
                  Templates: &quot;Shopify Category&quot;
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <FormControl>
                <Checkbox
                  label={'Update Existing Products'}
                  checked={updateExistingProducts}
                  onClick={() =>
                    setUpdateExistingProducts(!updateExistingProducts)
                  }
                />
                <FormHelperText>
                  <Typography level="body-xs">
                    Existing data may be overwritten.
                  </Typography>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography level="title-sm">
              {selectedProductIds.length} Items will be imported into{' '}
              {selectedTemplate ? (
                <strong>{selectedTemplate}</strong>
              ) : (
                'new Template(s)'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Button
              sx={{ float: 'right' }}
              variant="solid"
              onClick={handleImport}
              disabled={!selectedProductIds.length}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
