export function toExcelHeaderString(rows: number): string {
  return toExcelHeaderArray(rows).join(',')
}

// toExcelHeaderString(60) == "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN,AO,AP,AQ,AR,AS,AT,AU,AV,AW,AX,AY,AZ,BA,BB,BC,BD,BE,BF,BG,BH"

export function toExcelHeaderArray(rows: number): string[] {
  const excelHeaderArr: string[] = []
  for (let index = 1; index <= rows; index++) {
    excelHeaderArr.push(toExcelHeader(index))
  }
  return excelHeaderArr
}

// toExcelHeaderArray(60) == ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH"]

export function toExcelHeader(num: number): string {
  if (num <= 0) {
    return ''
  }

  const str = num.toString(26)

  const arr: number[] = str.split('').map((char) => {
    let code = char.charCodeAt(0)
    if (code >= 48 && code <= 57) {
      code += 16 // convert 1-9 to A-I and 0 to @
    } else {
      code -= 23 // convert a-p to J-Z
    }
    return code
  })

  // convert 'A@' to 'Z', 'B@' to 'AZ', etc.
  // ascii code of '@' is 64
  let index = arr.indexOf(64)
  while (index >= 0) {
    if (index === 0) {
      arr.shift() // remove head '@'
    } else {
      arr[index] = (arr[index] || 0) + 26
      arr[index - 1] = (arr[index - 1] || 0) - 1
    }
    index = arr.indexOf(64)
  }

  const chars = arr.map((code) => String.fromCharCode(code))
  return chars.join('')
}
