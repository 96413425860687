import { IntegrationName } from '../api/integration'
import { Product } from '../api/types'

export type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>

export enum ROLES {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  MANAGER = 3,
  MEMBER = 4,
  GUEST = 5,
}

export const ROLE_MAP: Record<number, string | undefined> = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Manager',
  4: 'Member',
  5: 'Guest',
}

export const ROLE_ID_MAP: Record<string, number | undefined> = {
  'Super Admin': 1,
  Admin: 2,
  Manager: 3,
  Member: 4,
  Guest: 5,
}

export const PRODUCT_CONDITIONS: ProductConditions[] = [
  'New',
  'Like New',
  'Refurbished',
  'Excellent',
  'Good',
  'Acceptable',
  'Parts or Not Working',
]

export type ProductConditions =
  | 'New'
  | 'Like New'
  | 'Refurbished'
  | 'Excellent'
  | 'Good'
  | 'Acceptable'
  | 'Parts or Not Working'

export enum ItemConditionsEnum {
  NEW = 'New',
  LIKE_NEW = 'Like New',
  REFURBISHED = 'Refurbished',
  EXCELLENT = 'Excellent',
  GOOD = 'Good',
  ACCEPTABLE = 'Acceptable',
  PARTS_OR_NOT_WORKING = 'Parts or Not Working',
}

export const PRODUCT_FIELD_NAMES: { [k: string]: keyof Product } = {
  Title: 'title',
  Description: 'description',
  SKU: 'sku',
  Id: 'id',
  Tags: 'tags',
  Condition: 'condition',
  Cost: 'cost',
  Price: 'price',
  MSRP: 'msrp',
  Quantity: 'quantity',
  Sold: 'sold',
  Notes: 'notes',
  Weight: 'weight',
  'Weight Unit': 'weightUnit',
  Width: 'width',
  Length: 'length',
  Height: 'height',
  'Size Unit': 'sizeUnit',
  Active: 'active',
  Updated: 'updatedAt',
  Created: 'createdAt',
}

export const PRODUCT_DISPLAY_NAMES: Record<string, string | undefined> = {
  // id: 'id',
  // templateId: 'Template Id',
  // tags: 'Tags',
  // title: 'Title',
  // description: 'Description',
  sku: 'SKU',
  condition: 'Condition',
  category: 'Category',
  cost: 'Cost',
  price: 'Price',
  msrp: 'MSRP',
  quantity: 'Quantity',
  sold: 'Sold',
  notes: 'Notes',
  // weight: 'Weight',
  // weightUnit: 'Weight Unit',
  // width: 'Width',
  // length: 'Length',
  // height: 'Height',
  // sizeUnit: 'Size Unit',
  // bin: 'Bin',
  // vendor: 'Vendor',
  // wharehouse: 'Wharehouse',
  // location: 'Location',
  // active: 'Active',
  // updatedAt: 'Updated',
  // createdAt: 'Created',
}

export const DEFAULT_ATTRIBUTES: { [k: string]: keyof Product } = {
  Vendor: 'vendor',
  Wharehouse: 'wharehouse',
  Location: 'location',
}

export enum PRODUCT_FIELDS_ENUM {
  TITLE = 'Title',
  DESCRIPTION = 'Description',
  SKU = 'SKU',
  ID = 'Id',
  TAGS = 'Tags',
  CONDITION = 'Condition',
  COST = 'Cost',
  PRICE = 'Price',
  MSRP = 'MSRP',
  QUANTITY = 'Quantity',
  SOLD = 'Sold',
  NOTES = 'Notes',
  WEIGHT = 'Weight',
  WEIGHT_UNIT = 'Weight Unit',
  WIDTH = 'Width',
  LENGTH = 'Length',
  HEIGHT = 'Height',
  SIZE_UNIT = 'Size Unit',
  ACTIVE = 'Active',
  UPDATED = 'Updated',
  CREATED = 'Created',
}

export const INTEGRATION_NAMES: IntegrationName[] = [
  IntegrationName.EBAY,
  IntegrationName.ETSY,
  IntegrationName.SHOPIFY,
  IntegrationName.BIG_COMMERCE,
  IntegrationName.SQUARE,
  IntegrationName.CLOVER,
  IntegrationName.CSV,
]
