import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { SetProductTemplateAttribute } from '../../api/product'
import Autocomplete from '@mui/joy/Autocomplete'
import { newOption } from './ProductTemplate'
import { isDefined } from '../../utils/functions'
import { AttributeConstraintEnum } from '../../api/types'
import LabelInput from '../../components/common/LabelInput'
import LockIcon from '@mui/icons-material/Lock'
import SvgIcon from '@mui/joy/SvgIcon'
import Typography from '@mui/joy/Typography'
import { AutocompleteOption, Box } from '@mui/joy'
import VerticalIconMenu from '../../components/common/VerticalIconMenu'
import FloatLabelInput from '../../components/common/FloatLabelInput'

enum MenuItemOptionEnum {
  ADD_TO_TITLE = 'Add to Title',
  ADD_TO_DESCRIPTION = 'Add to Description',
  MERGE_ATTRIBUTES = 'Merge Attributes',
  ADD_DEFAULT_VALUE = 'Add Default Value',
  REMOVE_DEFAULT_VALUE = 'Remove Default',
  SET_OPTIONAL = 'Set Optional',
  SET_RECOMMENDED = 'Set Recommended',
  SET_REQUIRED = 'Set Required',
  DELETE = 'Delete',
}
export interface ProductTemplateAttributeInputFieldProps {
  attribute: SetProductTemplateAttribute
  onChange: (attribute: SetProductTemplateAttribute | undefined) => void
  allowDelete?: boolean
  nameEditable?: boolean
  displayLabel?: boolean
  placeholder?: string
  disabled?: boolean
  required?: boolean
  channelOptions?: Record<string, string[] | undefined>
  addToTitle?: (attribute: SetProductTemplateAttribute) => void
  addToDescription?: (attribute: SetProductTemplateAttribute) => void
  mergeAttributes?: (attribute: SetProductTemplateAttribute) => void
}

export function ProductTemplateAttributeInputField({
  attribute: templateAttribute,
  onChange,
  allowDelete,
  placeholder,
  nameEditable = true,
  displayLabel,
  disabled,
  required,
  channelOptions,
  addToTitle,
  addToDescription,
  mergeAttributes,
}: ProductTemplateAttributeInputFieldProps): JSX.Element {
  const { attribute, attributeOptions } = templateAttribute
  const [errorText, setErrorText] = useState<string>('')
  const [attributeName, setAttributeName] = useState<string>(
    attribute.name || '',
  )

  const menuOptions: MenuItemOptionEnum[] = [
    !attribute.value ? MenuItemOptionEnum.ADD_DEFAULT_VALUE : undefined,
    attribute.value ? MenuItemOptionEnum.REMOVE_DEFAULT_VALUE : undefined,
    MenuItemOptionEnum.SET_OPTIONAL,
    MenuItemOptionEnum.SET_RECOMMENDED,
    MenuItemOptionEnum.SET_REQUIRED,
    attribute.id ? MenuItemOptionEnum.MERGE_ATTRIBUTES : undefined,

    MenuItemOptionEnum.DELETE,
  ].filter(isDefined)

  const handleMenuOptionClick = (action: string) => {
    if (action === MenuItemOptionEnum.ADD_TO_TITLE) {
      // Add to Title
      addToTitle?.(templateAttribute)
    } else if (action === MenuItemOptionEnum.ADD_TO_DESCRIPTION) {
      // Add To Description
      addToDescription?.(templateAttribute)
    } else if (action === MenuItemOptionEnum.MERGE_ATTRIBUTES) {
      // Merge Attributes
    } else if (action === MenuItemOptionEnum.ADD_DEFAULT_VALUE) {
      templateAttribute.attribute.value = 'Default'
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.REMOVE_DEFAULT_VALUE) {
      templateAttribute.attribute.value = undefined
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.SET_OPTIONAL) {
      templateAttribute.attribute.constraint = AttributeConstraintEnum.OPTIONAL
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.SET_RECOMMENDED) {
      templateAttribute.attribute.constraint =
        AttributeConstraintEnum.RECOMMENDED
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.SET_REQUIRED) {
      templateAttribute.attribute.constraint = AttributeConstraintEnum.REQUIRED
      onChange(templateAttribute)
    } else if (action === MenuItemOptionEnum.DELETE) {
      // Delete
      onChange?.(undefined)
    }
  }

  const templateOptions = disabled
    ? []
    : attributeOptions
        .map((o) => o.value)
        .sort((a, b) => a.localeCompare(b)) // sort by value alphabetically
        .map((o) => ({ source: 'template', value: o }))

  const channelOptionKeys = channelOptions ? Object.keys(channelOptions) : []

  let allOptions = templateOptions
  channelOptionKeys.forEach((channelKey) => {
    allOptions = allOptions?.concat(
      channelOptions?.[channelKey]?.map((o) => ({
        source: channelKey,
        value: o,
      })) || [],
    )
  })

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={5} sm={4} md={3} lg={2}>
        {nameEditable ? (
          <LabelInput
            onBlur={(e) => {
              setErrorText(attributeName ? '' : 'Name is required.')
              const name = e?.target.value
              setErrorText(name ? '' : 'Name is required.')
              if (!name) return
              onChange?.({
                attribute: { ...attribute, name },
                attributeOptions,
              })
            }}
            disabled={disabled}
            required={required}
            errorText={errorText}
            fullWidth
            value={attributeName}
            placeholder={placeholder}
            onChange={(name) => {
              setErrorText(name ? '' : 'Name is required.')
              setAttributeName(name)
            }}
          />
        ) : null}

        {!nameEditable && !displayLabel ? (
          <LabelInput
            value={
              attribute.name +
              (attribute.constraint === 'required' ? '**' : '') +
              (attribute.constraint === 'recommended' ? '*' : '')
            }
            readOnly={true}
            disabled={true}
            endDecorator={
              <>
                <SvgIcon fontSize="md" color="neutral" sx={{ opacity: 0.5 }}>
                  <LockIcon></LockIcon>
                </SvgIcon>
              </>
            }
          ></LabelInput>
        ) : null}

        {!nameEditable && displayLabel ? (
          <Box sx={{ position: 'relative' }}>
            <Typography
              sx={{
                border: '1px solid #e8e9eb',
                lineHeight: '34px',
                px: '8px',
                borderRadius: '6px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'left',
                boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
                color: '#32383E',
                backgroundColor: '#FBFCFE',
              }}
            >
              <strong title={attribute.name}>{attribute.name}</strong>
            </Typography>
          </Box>
        ) : null}
      </Grid>

      <Grid
        item
        xs={attribute.value ? 4 : 7}
        sm={attribute.value ? 5 : 8}
        md={attribute.value ? 6 : 9}
        lg={attribute.value ? 7 : 10}
      >
        <Autocomplete
          value={templateOptions}
          options={allOptions}
          groupBy={(o) => o.source}
          freeSolo
          multiple
          limitTags={5}
          disabled={disabled}
          placeholder={'Options'}
          required={required}
          getOptionLabel={(option) => {
            if (typeof option !== 'string') {
              return option.value
            } else {
              return option
            }
          }}
          renderOption={(props, o) => (
            <AutocompleteOption {...props}>{o.value}</AutocompleteOption>
          )}
          onChange={(e, values) => {
            const newValues = values.map((v, index) => {
              if (typeof v === 'string') {
                return newOption({ value: v, index })
              } else {
                return newOption({ value: v.value, index })
              }
            })
            onChange?.({ attribute, attributeOptions: newValues })
          }}
          slots={{
            clearIndicator: () => <></>,
          }}
          slotProps={{
            root: {
              sx: {
                pr: templateOptions?.length ? 0.33 : undefined,
              },
            },
          }}
          endDecorator={
            allowDelete ? (
              <VerticalIconMenu
                size="small"
                onClick={(o) => handleMenuOptionClick(o)}
                options={menuOptions}
              />
            ) : null
          }
        ></Autocomplete>
      </Grid>

      {attribute.value && (
        <Grid item xs={3}>
          <FloatLabelInput
            label="Default"
            value={attribute.value}
            onChange={(v) => {
              templateAttribute.attribute.value = v
              onChange?.(templateAttribute)
            }}
            endDecorator={
              <VerticalIconMenu
                size="small"
                onClick={(o) => handleMenuOptionClick(o)}
                options={[MenuItemOptionEnum.REMOVE_DEFAULT_VALUE]}
              />
            }
          />
        </Grid>
      )}
    </Grid>
  )
}
