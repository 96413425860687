import React, { useEffect, useState } from 'react'
import ChannelIntegrationsComponent from '../common/ChannelIntegrationsComponent'

import {
  getChannelIntegration,
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'

import { err } from '../../../../utils/functions'
import { Box, CircularProgress } from '@mui/joy'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import LoadingBox from '../../../common/LoadingBox'
import BigCommerceIntegrationComponent from './BigCommerceIntegrationComponent'
import {
  BigCommerceCategory,
  BigCommerceLocation,
  BigCommerceProductIntegration,
  BigCommerceTemplateIntegration,
} from '../../../../types/BigCommerce.types'
import {
  getBigCommerceCategories,
  getBigCommerceLocations,
} from '../../../../api/integrations/bigcommerce'
import MissingIntegration from '../MissingIntegration'
import UnauthenticatedIntegration from '../UnauthenticatedIntegration'

export default function BigCommerceIntegrationsComponent({
  template,
  product,
  onUpdate,
}: {
  template: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<IntegrationName.BIG_COMMERCE> | undefined
  >(undefined)

  const [locations, setLocations] = useState<
    BigCommerceLocation[] | undefined
  >()
  const [allCategories, setAllCategories] = useState<
    BigCommerceCategory[] | undefined
  >()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categoryIds, setCategoryIds] = useState<number[]>([])

  useEffect(() => {
    getChannelIntegration(
      IntegrationName.BIG_COMMERCE,
      template.template.id,
      product?.product.id,
    )
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        setLoading(false)
        err(e)
      })

    getBigCommerceLocations()
      .then((res) => {
        if (res.success && res.data) {
          setLocations(res.data)
        } else {
          setLocations([])
        }
      })
      .catch((e) => {
        setLocations([])
        err(e)
      })

    getBigCommerceCategories()
      .then((res) => {
        if (res.success && res.data) {
          const newAllCategories: BigCommerceCategory[] = []
          const addAllCategories = (categories: BigCommerceCategory[]) => {
            categories.forEach((c) => {
              newAllCategories.push(c)
              if (c.children?.length) {
                addAllCategories(c.children)
              }
            })
          }
          addAllCategories(res.data)

          setAllCategories(newAllCategories)
          setCategoryIds(newAllCategories.map((c) => c.category_id))
        } else {
          setAllCategories([])
        }
      })
      .catch((e) => {
        setAllCategories([])
        err(e)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (!integration) {
    return (
      <Box p={4}>
        <MissingIntegration />
      </Box>
    )
  }

  if (integration && !integration.authenticated) {
    return (
      <Box p={4}>
        <UnauthenticatedIntegration
          channelName={IntegrationName.BIG_COMMERCE}
        />
      </Box>
    )
  }

  return (
    <>
      <ChannelIntegrationsComponent
        channel={IntegrationName.BIG_COMMERCE}
        template={template}
        product={product}
        integration={integration}
        onUpdate={onUpdate}
        // onTemplateDelete={async (id) => {
        //   return await deleteBigCommerceTemplateIntegration(id)
        // }}
        Component={({
          templateIntegration,
          productIntegration,
          template,
          product,
          count,
          onTemplateChange,
          onProductChange,
          onUpdate,
        }) =>
          !locations || !allCategories ? (
            <LoadingBox />
          ) : (
            <BigCommerceIntegrationComponent
              integration={integration}
              templateIntegration={
                templateIntegration as BigCommerceTemplateIntegration
              }
              productIntegration={
                productIntegration as BigCommerceProductIntegration
              }
              template={template}
              product={product}
              locations={locations}
              categories={allCategories}
              onTemplateChange={(name, templateIntegration) =>
                onTemplateChange?.(
                  name,
                  templateIntegration as BigCommerceTemplateIntegration,
                )
              }
              onProductChange={(name, productIntegration) =>
                onProductChange?.(
                  name,
                  productIntegration as BigCommerceProductIntegration,
                )
              }
              onUpdate={onUpdate}
            />
          )
        }
      />
    </>
  )
}
