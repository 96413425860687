import instance from '.'
import { GenericValueMapping } from '../components/product/ProductTitleDescription'
import {
  BigCommerceProductIntegration,
  BigCommerceTemplateIntegration,
} from '../types/BigCommerce.types'
import {
  CloverProductIntegration,
  CloverTemplateIntegration,
} from '../types/Clover.types'
import {
  CSVProductIntegration,
  CSVTemplateIntegration,
} from '../types/CSV.types'
import {
  EbayProductIntegration,
  EbayTemplateIntegration,
  OfferResponse,
} from '../types/Ebay.types'
import {
  EtsyProductIntegration,
  EtsyTemplateIntegration,
} from '../types/Etsy.types'
import {
  ShopifyProductIntegration,
  ShopifyTemplateIntegration,
} from '../types/Shopify.types'
import {
  SquareTemplateIntegration,
  SquareProductIntegration,
} from '../types/Square.types'
import { getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import {
  DataFields,
  Integration,
  LoginAuthentication,
  OAuthAuthentication,
} from './types'

// Enum of current Integration names
export enum IntegrationName {
  EBAY = 'ebay',
  ETSY = 'etsy',
  SHOPIFY = 'shopify',
  BIG_COMMERCE = 'bigcommerce',
  SQUARE = 'square',
  CLOVER = 'clover',
  CSV = 'csv',
}

export type IntegrationNames =
  | 'ebay'
  | 'etsy'
  | 'shopify'
  | 'bigcommerce'
  | 'square'

export const IntegrationDisplayName: { [k: string]: string } = {
  ebay: 'Ebay',
  etsy: 'Etsy',
  shopify: 'Shopify',
  bigcommerce: 'Big Commerce',
  square: 'Square',
  clover: 'Clover',
  csv: 'CSV',
}

export const IntegrationDisplayNameToName: { [k: string]: string } = {
  Ebay: IntegrationName.EBAY,
  Etsy: IntegrationName.ETSY,
  Shopify: IntegrationName.SHOPIFY,
  'Big Commerce': IntegrationName.BIG_COMMERCE,
  Square: IntegrationName.SQUARE,
  Clover: IntegrationName.CLOVER,
  CSV: IntegrationName.CSV,
}

export type TemplateIntegrationType<T> = (T extends IntegrationName.EBAY
  ? EbayTemplateIntegration
  : T extends IntegrationName.ETSY
  ? EtsyTemplateIntegration
  : T extends IntegrationName.BIG_COMMERCE
  ? BigCommerceTemplateIntegration
  : T extends IntegrationName.SHOPIFY
  ? ShopifyTemplateIntegration
  : T extends IntegrationName.SQUARE
  ? SquareTemplateIntegration
  : T extends IntegrationName.CLOVER
  ? CloverTemplateIntegration
  : T extends IntegrationName.CSV
  ? CSVTemplateIntegration
  : never) & {
  title?: string
  description?: string
  valueMappings?: GenericValueMapping[] | undefined
}

export type GenericTemplateIntegration = {
  title?: string
  description?: string
  valueMappings?: GenericValueMapping[] | undefined
}

export type GenericProductIntegration = {
  title?: string
  description?: string
  name?: string
  selected?: boolean
  index?: number
}

export type ProductIntegrationType<T> = T extends IntegrationName.EBAY
  ? EbayProductIntegration
  : T extends IntegrationName.ETSY
  ? EtsyProductIntegration
  : T extends IntegrationName.BIG_COMMERCE
  ? BigCommerceProductIntegration
  : T extends IntegrationName.SHOPIFY
  ? ShopifyProductIntegration
  : T extends IntegrationName.SQUARE
  ? SquareProductIntegration
  : T extends IntegrationName.CLOVER
  ? CloverProductIntegration
  : T extends IntegrationName.CSV
  ? CSVProductIntegration
  : never

export async function getAvailableIntegrations(): Promise<APIResult<string[]>> {
  const availableIntegrations = await instance
    .post('/api/integration/getAvailableIntegrations')
    .then((res) => getResult<string[]>(res))
  return availableIntegrations
}

export async function getIntegrations(params?: {
  templateId?: number
  productId?: number
}): Promise<APIResult<GetIntegrationsResult>> {
  const integrations = await instance
    .get('/api/integration/getIntegrations', {
      params: {
        templateId: params?.templateId,
        productId: params?.productId,
      },
    })
    .then((res) => getResult<GetIntegrationsResult>(res))
  return integrations
}

export async function getChannelIntegration<T>(
  channel: T,
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetIntegrationResult<T>>> {
  const channelIntegrations = await instance
    .get(`/api/integration/${channel}/integrations`, {
      params: {
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetIntegrationResult<T>>(res))
  return channelIntegrations
}

export async function getChannelTemplateIntegrations<T>(
  channel: IntegrationName,
  integrationId: number,
  templateId: number,
): Promise<APIResult<TemplateIntegrationType<T>[]>> {
  const channelTemplateIntegration = await instance
    .get(`/api/integration/templateIntegrations`, {
      params: {
        channel,
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<TemplateIntegrationType<T>[]>(res))
  return channelTemplateIntegration
}

export async function getChannelProductIntegrations<T>(
  channel: IntegrationName,
  integrationId: number,
  templateId: number,
  productId: number,
): Promise<APIResult<TemplateIntegrationType<T>[]>> {
  const channelProductIntegration = await instance
    .get(`/api/integration/templateIntegrations`, {
      params: {
        channel,
        integrationId,
        templateId,
        productId,
      },
    })
    .then((res) => getResult<TemplateIntegrationType<T>[]>(res))
  return channelProductIntegration
}

export async function setChannelTemplateIntegrations<T>(
  channel: T,
  templateIntegrations: DataFields<TemplateIntegrationType<T>>[],
): Promise<APIResult<TemplateIntegrationType<T>[]>> {
  const setChannelTemplateIntegrations = await instance
    .post(`/api/integration/templateIntegrations`, {
      channel,
      templateIntegrations,
    })
    .then((res) => getResult<TemplateIntegrationType<T>[]>(res))
  return setChannelTemplateIntegrations
}

export async function setChannelProductIntegrations<T>(
  channel: T,
  productIntegrations: Omit<
    DataFields<ProductIntegrationType<T>>,
    'clientId'
  >[],
): Promise<APIResult<ProductIntegrationType<T>[]>> {
  const setChannelProductIntegrations = await instance
    .post(`/api/integration/productIntegrations`, {
      channel,
      productIntegrations,
    })
    .then((res) => getResult<ProductIntegrationType<T>[]>(res))
  return setChannelProductIntegrations
}

// type SetProductIntegration = {
//   templateIntegrationId: number
//   productId: number
//   externalId?: string
//   sku?: string
//   status?: string
//   list?: boolean
//   sync?: boolean
//   syncQuantity?: boolean
// }
// export type SetProductIntegrations = {
//   ebay?: SetProductIntegration
//   etsy?: SetProductIntegration
//   bigcommerce?: SetProductIntegration
//   shopify?: SetProductIntegration
//   square?: SetProductIntegration
// }

export async function setChannelsProductIntegrations(
  productIntegrations: ChannelsProductIntegrations,
  productId: number,
): Promise<APIResult<void>> {
  return await instance
    .post('/api/integration/channelsProductIntegrations', {
      productIntegrations,
      productId,
    })
    .then((res) => getResult<void>(res))
}

export interface ChannelsProductIntegrations {
  ebay: ProductIntegrationType<IntegrationName.EBAY>[]
  etsy: ProductIntegrationType<IntegrationName.ETSY>[]
  square: ProductIntegrationType<IntegrationName.SQUARE>[]
  shopify: ProductIntegrationType<IntegrationName.SHOPIFY>[]
  bigcommerce: ProductIntegrationType<IntegrationName.BIG_COMMERCE>[]
  clover: ProductIntegrationType<IntegrationName.CLOVER>[]
  csv: ProductIntegrationType<IntegrationName.CSV>[]
}
export async function getChannelsProductIntegrations(
  productId: number,
): Promise<APIResult<ChannelsProductIntegrations>> {
  return await instance
    .get('/api/integration/channelsProductIntegrations', {
      params: { productId },
    })
    .then((res) => getResult<ChannelsProductIntegrations>(res))
}

export interface ChannelsTemplateIntegration {
  ebay: TemplateIntegrationType<IntegrationName.EBAY>
  etsy: TemplateIntegrationType<IntegrationName.ETSY>
  square: TemplateIntegrationType<IntegrationName.SQUARE>
  shopify: TemplateIntegrationType<IntegrationName.SHOPIFY>
  bigcommerce: TemplateIntegrationType<IntegrationName.BIG_COMMERCE>
  clover: TemplateIntegrationType<IntegrationName.CLOVER>
  csv: TemplateIntegrationType<IntegrationName.CSV>
}
export async function getChannelsTemplateIntegration(
  templateId: number,
  productId?: number,
): Promise<APIResult<ChannelsTemplateIntegration>> {
  return await instance
    .get('/api/integration/channelsTemplateIntegration', {
      params: { templateId, productId },
    })
    .then((res) => getResult<ChannelsTemplateIntegration>(res))
}

export async function selectChannelProductIntegration(
  channel: string,
  productIntegrationId: number,
): Promise<APIResult<unknown>> {
  return await instance
    .post('/api/integration/selectChannelProductIntegration', {
      channel,
      productIntegrationId,
    })
    .then((res) => getResult<unknown>(res))
}

export async function updateChannelProductIntegration(
  channel: string,
  productIntegration: unknown,
): Promise<APIResult<unknown>> {
  return await instance
    .post('/api/integration/updateChannelProductIntegration', {
      channel,
      productIntegration,
    })
    .then((res) => getResult<unknown>(res))
}

export async function deleteIntegration<T>(
  channel: string,
): Promise<APIResult<GetIntegrationResult<T>>> {
  const integrations = await instance
    .post(`/api/integration/${channel}/delete`)
    .then((res) => getResult<GetIntegrationResult<T>>(res))
  return integrations
}

export async function removeProductTemplateIntegration(
  templateId: number,
  integrationId: number,
  listed?: boolean | undefined,
): Promise<APIResult<void>> {
  const integrations = await instance
    .post('/api/integration/removeProductTemplateIntegration', {
      templateId,
      integrationId,
      listed,
    })
    .then((res) => getResult<void>(res))
  return integrations
}

export async function removeProductIntegration(
  integrationId: number,
): Promise<APIResult<void>> {
  const integrations = await instance
    .post('/api/integration/removeProductIntegration', {
      integrationId,
    })
    .then((res) => getResult<void>(res))
  return integrations
}

export type Authentication = OAuthAuthentication | LoginAuthentication
export type GetIntegrationResult<T> = {
  integration?: Integration
  productIntegrations?: ProductIntegrationType<T>[] | undefined
  templateIntegrations?: TemplateIntegrationType<T>[] | undefined
  authenticationType?: string
  authenticated: boolean
  oAuthUrl?: string
}
export type GetIntegrationsResult = {
  ebay?: GetIntegrationResult<IntegrationName.EBAY>
  etsy?: GetIntegrationResult<IntegrationName.ETSY>
  shopify?: GetIntegrationResult<IntegrationName.SHOPIFY>
  bigcommerce?: GetIntegrationResult<IntegrationName.BIG_COMMERCE>
  square?: GetIntegrationResult<IntegrationName.SQUARE>
  clover?: GetIntegrationResult<IntegrationName.CLOVER>
  csv?: GetIntegrationResult<IntegrationName.CSV>
}
export async function getIntegration<T>(
  name: IntegrationName,
): Promise<APIResult<GetIntegrationResult<T>>> {
  const integration = await instance
    .post('/api/integration/getIntegration', {
      name,
    })
    .then((res) => getResult<GetIntegrationResult<T>>(res))
  return integration
}

export async function updateIntegration(
  name: string,
  description?: string,
): Promise<APIResult<Integration>> {
  const integration = await instance
    .post('/api/integration/updateIntegration', {
      name,
      description,
    })
    .then((res) => getResult<Integration>(res))
  return integration
}

export async function createIntegration(
  name: string,
): Promise<APIResult<Integration>> {
  const integration = await instance
    .post(`/api/integration/${name}/integration`, {
      name,
    })
    .then((res) => getResult<Integration>(res))
  return integration
}

export async function authenticateOAuth(
  name: string,
  params: { [k: string]: unknown },
): Promise<APIResult<void>> {
  return await instance
    .post(`/api/integration/${name}/oauth`, params)
    .then((res) => getResult<void>(res))
}

export async function getOAuthUrl(
  name: string,
  params?: { [k: string]: unknown },
): Promise<APIResult<string>> {
  return await instance
    .get(`/api/integration/${name}/oauthUrl`, {
      params,
    })
    .then((res) => getResult<string>(res))
}

export async function getTemplateIntegration<T>(
  name: string,
  integrationId: number,
  templateId: number,
): Promise<APIResult<T>> {
  return await instance
    .get(`/api/integration/${name}/templateIntegration`, {
      params: {
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<T>(res))
}

export async function getProductIntegration<T>(
  name: string,
  integrationId: number,
  productId: number,
): Promise<APIResult<T>> {
  return await instance
    .get(`/api/integration/${name}/templateIntegration`, {
      params: {
        integrationId,
        productId,
      },
    })
    .then((res) => getResult<T>(res))
}

export async function setTemplateIntegration<T>(
  name: string,
  templateIntegration: T,
): Promise<APIResult<T>> {
  return await instance
    .post(`/api/integration/${name}/templateIntegration`, {
      templateIntegration,
    })
    .then((res) => getResult<T>(res))
}

export async function listProduct(
  channel: string,
  productId: number,
): Promise<APIResult<OfferResponse>> {
  const response = await instance
    .post(`/api/integration/${channel}/listProduct`, {
      productId,
    })
    .then((res) => getResult<OfferResponse>(res))
  return response
}

export async function unlistProduct(
  channel: string,
  productId: number,
  integrationId?: number,
): Promise<APIResult<string>> {
  const response = await instance
    .post(`/api/integration/${channel}/unlistProduct`, {
      productId,
      integrationId,
    })
    .then((res) => getResult<string>(res))
  return response
}

export interface GetProductIntegrationsResult {
  ebay?: EbayProductIntegration[]
  // etsy?: EtsyProductIntegration[]
  bigcommerce?: BigCommerceProductIntegration[]
  shopify?: ShopifyProductIntegration[]
}
export async function getAllProductIntegrations(): Promise<
  APIResult<GetProductIntegrationsResult>
> {
  const listings = await instance
    .get('/api/integration/allProductIntegrations')
    .then((res) => getResult<GetProductIntegrationsResult>(res))
  return listings
}

export type ListToChannelsResponse = {
  [k: string]: {
    status: 'fulfilled' | 'rejected'
    value?: unknown
    reason?: string
  }
}

export async function listToChannels(
  productId: number,
): Promise<APIResult<void>> {
  const listings = await instance
    .post('/api/integration/listToChannels', { productId })
    .then((res) => getResult<void>(res))
  return listings
}

export async function disconnectIntegration(
  channel: IntegrationName,
  integrationId: number,
): Promise<APIResult<void>> {
  const listings = await instance
    .post(`/api/integration/disconnect`, { channel, integrationId })
    .then((res) => getResult<void>(res))
  return listings
}
