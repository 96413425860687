import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import MultipleSelect from '../../../components/common/MultipleSelect'

interface ProductTagsProps {
  tags: string[]
  onChange: (tags: string[]) => void
}

export default function ProductTags({
  tags,
  onChange,
}: ProductTagsProps): JSX.Element {
  return (
    <Grid container spacing={1}>
      <Grid item xs={5} sm={4} md={3} lg={2} sx={{ position: 'relative' }}>
        <Typography
          sx={{
            border: '1px solid #e8e9eb',
            lineHeight: '34px',
            px: '8px',
            borderRadius: '6px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textAlign: 'left',
            boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
            color: '#32383E',
            backgroundColor: '#FBFCFE',
          }}
        >
          <strong title={'Tags'}>Tags</strong>
        </Typography>
      </Grid>
      <Grid item xs={7} sm={8} md={9} lg={10}>
        <MultipleSelect
          value={tags}
          options={tags}
          freeSolo
          placeholder="Tags"
          onChange={(values) => onChange(values)}
        />
      </Grid>
    </Grid>
  )
}
