import React, { useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import { ProductDescriptionEditor } from '../../pages/products/ProductDescriptionEditor'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { parseDescription, parseTitle } from '../../classes/Parser'
import { GetProduct, GetProductTemplate } from '../../api/product'
import Button from '@mui/joy/Button'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import Typography from '@mui/joy/Typography'

export interface GenericValueMapping {
  index: number
  integrationId: number
  templateIntegrationId?: number
  attributeId?: number
  applyTo?: string
  condition?: string
  function?: string
  value: string
  values?: { value: string }[]
}

interface ProductTitleDescriptionProps {
  template?: GetProductTemplate
  product?: GetProduct
  title?: string
  description?: string
  required?: boolean
  disableUnparsedEdit?: boolean
  defaultParseState?: boolean
  valueMapping?: GenericValueMapping[]
  onTitleChange?: (title: string) => void
  onDescriptionChange?: (description: string) => void
  onAttributeClick?: (attributeName: string, element?: string) => void
}
export function ProductTitleDescription({
  template,
  product,
  title: titleValue,
  description: descriptionValue,
  required,
  disableUnparsedEdit,
  defaultParseState,
  valueMapping,
  onTitleChange,
  onDescriptionChange,
  onAttributeClick,
}: ProductTitleDescriptionProps): JSX.Element {
  const [title, setTitle] = useState<string | undefined>(titleValue)
  const [description, setDescription] = useState<string | undefined>(
    descriptionValue,
  )

  const [parseDetails, setParseDetails] = useState<boolean>(
    defaultParseState ?? !!product,
  )

  const [parsedTitle, parsedDescription] = useMemo(() => {
    if (!product) return ['', '']

    const titleValueMapping = valueMapping?.filter((v) => v.applyTo === 'title')
    const descriptionValueMapping = valueMapping?.filter(
      (v) => v.applyTo === 'description',
    )
    const parsedTitle = parseTitle(title || '', product, titleValueMapping)
    const parsedDescription = parseDescription(
      description || '',
      product,
      descriptionValueMapping,
    )

    return [parsedTitle, parsedDescription]
  }, [description, product, title, valueMapping])

  let attributeNames: string[] = []
  if (product) {
    attributeNames = product?.attributes.map((a) => a.templateAttribute.name)
  } else if (template) {
    attributeNames = template?.attributes.map((a) => a.attribute.name)
  }

  const handleTitleChange = (title: string) => {
    setTitle(title)
  }
  const handleTitleUpdate = (title: string) => {
    setTitle(title)
    onTitleChange?.(title)
  }
  const handleDescriptionUpdate = (description: string) => {
    setDescription(description)
    onDescriptionChange?.(description)
  }
  const handleDescriptionChange = (description: string) => {
    setDescription(description)
  }

  const handleToggleParseDetails = () => {
    setParseDetails(!parseDetails)
  }

  const unparsedDisabled = parseDetails && disableUnparsedEdit === true

  return (
    <Grid container spacing={2}>
      {product && (
        <Grid item xs={12} sx={{ mb: '-12px' }}>
          {/* <ParseProductDetailsToggle
                toggled={parseDetails}
                onChange={handleToggleParseDetails}
                placement="start"
              /> */}
          <Button
            sx={{ float: 'right' }}
            size="sm"
            variant="plain"
            color="neutral"
            onClick={handleToggleParseDetails}
            endDecorator={
              parseDetails ? (
                <EditIcon fontSize="small" />
              ) : (
                <VisibilityIcon fontSize="small" />
              )
            }
          >
            <Typography level="body-sm">
              {parseDetails ? 'Edit' : 'Preview'}
            </Typography>
          </Button>
        </Grid>
      )}

      {title !== undefined && (
        <Grid item xs={12}>
          <Tooltip title={unparsedDisabled ? 'Un-parse to edit.' : undefined}>
            <Box>
              <ProductDescriptionEditor
                key={`product-title-editor-${parseDetails}`}
                title="Title"
                oneLine
                required={required}
                disabled={unparsedDisabled}
                richText={true}
                value={parseDetails ? parsedTitle : title}
                onChange={handleTitleChange}
                onUpdate={(updatedTitle) => handleTitleUpdate?.(updatedTitle)}
                onBlur={() => onTitleChange?.(title)}
                attributeNames={attributeNames}
                helperText={
                  !parsedTitle && title && product
                    ? 'Your title is referencing attributes that are undefined. Click "Edit" to view.'
                    : ''
                }
                onMentionClick={(mention) =>
                  onAttributeClick?.(mention, 'title')
                }
              />
            </Box>
          </Tooltip>
        </Grid>
      )}
      {description !== undefined && (
        <Grid item xs={12}>
          <Tooltip title={unparsedDisabled ? 'Un-parse to edit.' : undefined}>
            <Box>
              <ProductDescriptionEditor
                key={`product-description-editor-${parseDetails}`}
                title="Description"
                required={required}
                disabled={unparsedDisabled}
                richText={true}
                value={parseDetails ? parsedDescription : description}
                onChange={handleDescriptionChange}
                onUpdate={(updatedDescription) =>
                  handleDescriptionUpdate?.(updatedDescription)
                }
                onBlur={() => onDescriptionChange?.(description)}
                attributeNames={attributeNames}
                warningText={
                  parseDetails && !parsedDescription && description
                    ? 'Your description is referencing attributes that are undefined. Click "Edit" to view.'
                    : ''
                }
                onMentionClick={(mention) =>
                  onAttributeClick?.(mention, 'description')
                }
              />
            </Box>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}
