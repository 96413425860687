import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import { GetProduct } from '../../../../api/product'
import Typography from '@mui/joy/Typography'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import { Box, Tooltip } from '@mui/joy'
import { GenericProductIntegration } from '../../../../api/integration'
interface ListUnlistButtonsProps {
  title?: boolean
  list?: boolean
  productIntegration?: GenericProductIntegration
  product?: GetProduct
  onList?: () => void
  onUnlist?: () => void
}

export default function ListUnlistButtons({
  title,
  list,
  productIntegration,
  product,
  onList,
  onUnlist,
}: ListUnlistButtonsProps): JSX.Element {
  if (!product) return <></>

  const selected =
    productIntegration &&
    (productIntegration.selected || productIntegration.index === 0)

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      {title && (
        <Grid item xs={12}>
          <Typography level="h4">Listing Actions</Typography>
        </Grid>
      )}
      <Grid item>
        <Button
          sx={{ p: 1, width: '200px' }}
          variant="soft"
          color="danger"
          size="sm"
          onClick={onUnlist}
          disabled={!selected}
          endDecorator={<HighlightOffIcon />}
        >
          Unlist
        </Button>
      </Grid>

      <Grid item>
        <Tooltip
          title={
            !selected
              ? 'Product is not configured to list with this Channel Template.'
              : ''
          }
        >
          <Box sx={{ cursor: 'pointer' }}>
            <Button
              variant="soft"
              size="sm"
              onClick={onList}
              disabled={!selected || !list}
              sx={{ p: 1, width: '200px' }}
              endDecorator={<PublishedWithChangesIcon />}
            >
              List / Sync
            </Button>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
