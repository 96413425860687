import React, { useEffect, useMemo, useState } from 'react'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import Grid from '@mui/material/Grid'
import {
  CategorySuggestion,
  CategoryTree,
  CategoryTreeNode,
} from '../../../../types/Ebay.types'
import { err, log } from '../../../../utils/functions'
import {
  getEbayCategories,
  getEbayCategorySuggestions,
} from '../../../../api/product'
import Component from '../../../common/Component'
import Button from '@mui/joy/Button'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import Typography from '@mui/joy/Typography'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/joy/CircularProgress'
import NamedInput from '../../../common/NamedInput'
import FloatLabelInput from '../../../common/FloatLabelInput'

export type EbayCategory = {
  id: number
  name: string
}
interface EbayCategoriesProps {
  categories?: CategoryTree
  category: EbayCategory | undefined
  categoryVersion: number
  label?: string | undefined
  onChange: (
    categoryVersion: number,
    category: EbayCategory | undefined,
    path?: string[],
  ) => void
}

export default function EbayCategories({
  categories: defaultCategories,
  category,
  label,
  onChange,
}: EbayCategoriesProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const [queryLoading, setQueryLoading] = useState<boolean>(false)
  const [categories, setCategories] = useState<CategoryTree | undefined>(
    defaultCategories,
  )
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [categorySuggestions, setCategorySuggestions] = useState<
    CategorySuggestion[]
  >([])
  const [categorySuggestionQuery, setCategorySuggestionQuery] = useState('')
  const [viewMenu, setViewMenu] = useState<boolean>(false)

  const handleViewMenu = () => {
    setViewMenu(!viewMenu)
  }

  const handleQuery = () => {
    if (!categorySuggestionQuery) return

    setQueryLoading(true)
    setViewMenu(false) // do not show menu
    categorySuggestionQuery &&
      getEbayCategorySuggestions(categorySuggestionQuery)
        .then((res) => {
          setQueryLoading(false)
          if (res.success && res.data) {
            setCategorySuggestions(res.data.categorySuggestions || [])
          } else {
            handleAlert(setAlert, res, 'Added service to Dashboard.')
          }
        })
        .catch((e) => log(e))
  }

  useEffect(() => {
    // Do not load if categories is set
    if (category || categories) {
      setLoading(false)
      return
    }
    setLoading(true)
    getEbayCategories()
      .then((res) => {
        setLoading(false)
        if (res.success) {
          setCategories(res.data)
        } else {
          handleAlert(setAlert, res, 'Added service to Dashboard.')
        }
      })
      .catch((e) => err(e))
  }, [category, categories])

  const categoryPaths = useMemo(() => {
    if (!categories) return {}
    const record: Record<string, string> = {}

    // Helper function to recursively process each node
    function traverse(node: CategoryTreeNode, path?: string | undefined) {
      const currentPath =
        !path || path === 'Root'
          ? node.category.categoryName
          : `${path} > ${node.category.categoryName}`

      const categoryId = node.category.categoryId

      // Store the current path in the record
      record[categoryId] = currentPath

      // Recursively process child nodes if present
      if (node.childCategoryTreeNodes) {
        for (const childNode of node.childCategoryTreeNodes) {
          traverse(childNode, currentPath)
        }
      }
    }

    // Start traversal from the root node with "All" as the base path
    traverse(categories.rootCategoryNode, '')

    return record
  }, [categories])

  if (!categories && !category && !loading) {
    return <Typography>Must Integrate with Ebay for this feature.</Typography>
  }

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography>
          <Typography component="span" level="title-lg">
            Ebay Category
          </Typography>
          <Typography sx={{ ml: 0.4 }} component="span" level="body-xs">
            {categories?.categoryTreeVersion
              ? `(v.${categories.categoryTreeVersion})`
              : null}
          </Typography>
        </Typography>
      </Grid>

      {category?.id && category.name ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={0}>
            <Grid item xs={12}>
              {label ? (
                <NamedInput
                  name={label}
                  renderName={false}
                  label={'Category'}
                  value={categoryPaths[category.id] || category.name}
                  disabled={true}
                  fullWidth
                />
              ) : (
                <FloatLabelInput
                  label={'Category'}
                  value={categoryPaths[category.id] || category.name}
                  disabled={true}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ float: 'right', mt: '4px' }}
                onClick={() => onChange(0, undefined)}
                size="sm"
                variant="plain"
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {(!category?.id || !category?.name) && categories && (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12} md={10}>
              <NamedInput
                renderName={false}
                placeholder="Search Categories"
                value={categorySuggestionQuery}
                onChange={(v) => setCategorySuggestionQuery(v)}
                onKeyDown={(key) => {
                  if (key.code === 'Enter') {
                    handleQuery()
                  }
                }}
                disabled={loading}
                fullWidth
                button={
                  <Button
                    variant="solid"
                    onClick={handleQuery}
                    disabled={
                      loading || queryLoading || !categorySuggestionQuery
                    }
                  >
                    <SearchIcon />
                  </Button>
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="plain"
                onClick={handleViewMenu}
                disabled={loading}
              >
                {viewMenu ? 'Close Menu' : 'Open Menu'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {queryLoading ? (
        <Grid item xs={12}>
          <Component>
            <CircularProgress />
          </Component>
        </Grid>
      ) : null}

      {!queryLoading &&
        (!category?.id || !category?.name) &&
        ((!viewMenu && categorySuggestions.length) ||
          (viewMenu && categories)) && (
          <Grid item xs={12}>
            <Component p={1}>
              {viewMenu ? (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ height: '300px', flexGrow: 1, overflowY: 'auto' }}
                >
                  {categories?.rootCategoryNode ? (
                    <DisplayCategories
                      node={categories.rootCategoryNode}
                      onChange={(category, path) => {
                        onChange(
                          parseInt(categories.categoryTreeVersion),
                          category,
                          path,
                        )
                      }}
                    />
                  ) : null}
                </TreeView>
              ) : (
                <TreeView>
                  {categorySuggestions?.map((suggestion) => {
                    const suggestionArray: string[] = [
                      suggestion.category.categoryName,
                    ]
                    suggestion.categoryTreeNodeAncestors.forEach((ancestor) => {
                      suggestionArray.unshift(ancestor.categoryName)
                    })
                    return (
                      <TreeItem
                        key={suggestion.category.categoryId}
                        nodeId={suggestion.category.categoryId}
                        label={
                          <div>
                            {suggestionArray.slice(0, -1).join(' > ')}
                            {' > '}
                            <strong>{suggestionArray.slice(-1)[0]}</strong>
                          </div>
                        }
                        onClick={() =>
                          categories?.categoryTreeVersion &&
                          onChange(
                            parseInt(categories.categoryTreeVersion),
                            {
                              id: parseInt(suggestion.category.categoryId),
                              name: suggestion.category.categoryName,
                            },
                            suggestionArray,
                          )
                        }
                        sx={{ py: '0.33em' }}
                      />
                    )
                  }) || 'Search Categories...'}
                </TreeView>
              )}
            </Component>
          </Grid>
        )}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}

function DisplayCategories({
  node,
  onChange,
}: {
  node: CategoryTreeNode
  onChange: (category: EbayCategory | undefined, path?: string[]) => void
}): JSX.Element {
  return (
    <div>
      {node.childCategoryTreeNodes?.map((node) => (
        <TreeNode
          node={node}
          path={[]}
          key={node.category.categoryId}
          onChange={onChange}
        />
      ))}
    </div>
  )
}

function TreeNode({
  node,
  path,
  onChange,
}: {
  node: CategoryTreeNode
  path?: string[]
  onChange: (category: EbayCategory | undefined, path?: string[]) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.category.categoryId}
      nodeId={node.category.categoryId}
      label={node.category.categoryName}
      onClick={() =>
        node.leafCategoryTreeNode &&
        onChange(
          {
            id: parseInt(node.category.categoryId),
            name: node.category.categoryName,
          },
          path,
        )
      }
    >
      {node.childCategoryTreeNodes?.map((node) => (
        <TreeNode
          node={node}
          path={(path || [])?.concat([node.category.categoryName])}
          key={node.category.categoryId}
          onChange={onChange}
        />
      ))}
    </TreeItem>
  )
}

export function search(
  array: CategoryTreeNode[],
  is: (obj: CategoryTreeNode) => boolean,
): CategoryTreeNode | undefined {
  let found: CategoryTreeNode | undefined = undefined
  array.forEach((node) => {
    log(node.category.categoryName)
    if (found) return
    if (is(node)) {
      found = node
      return
    }
    if (!found && node.childCategoryTreeNodes?.length) {
      found = search(node.childCategoryTreeNodes, is)
    }
  })
  return found
}
