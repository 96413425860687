import React, { useState } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/material/Grid'
import {
  GetProduct,
  SetProductAttribute,
  SetProductVariation,
} from '../../../api/product'
import ProductAttributeInputField from './ProductAttributeInputField'
import { ProductAttributeInputText } from './ProductAttributeInputText'
import ImageUpload from '../../../components/common/ImageUpload'
import { ProductTemplateAttribute } from '../../../api/types'
import { RequiredIntegrationTemplateAttributes } from '../../../api/integrations/ebay'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Accordion from '@mui/joy/Accordion'
import AccordionSummary from '@mui/joy/AccordionSummary'
import ListItemContent from '@mui/joy/ListItemContent'
import AccordionDetails from '@mui/joy/AccordionDetails'
import ListItem from '@mui/joy/ListItem'
import Box from '@mui/joy/Box'

const MENU_ITEM_HEIGHT = 48

enum MenuItemOptionEnum {
  MOVE_UP = 'Move Up',
  MOVE_DOWN = 'Move Down',
  DELETE = 'Delete',
}

interface ProductVariationCollapseProps {
  productId?: number
  product: GetProduct
  sku?: string
  price?: string
  quantity?: string
  variation: SetProductVariation
  templateVariationAttributes: ProductTemplateAttribute[]
  attributes: SetProductAttribute[]
  requiredAttributes: RequiredIntegrationTemplateAttributes[]
  onChange: (variation: SetProductVariation) => void
  onDelete: (name: string) => void
}
export default function ProductVariationCollapse({
  productId,
  product,
  price: defaultPrice,
  quantity: defaultQuantity,
  variation,
  attributes,
  templateVariationAttributes,
  requiredAttributes,
  onChange,
  onDelete,
}: ProductVariationCollapseProps): JSX.Element {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const [name, setName] = useState<string>(variation.variation.name || '')
  const [cost, setCost] = useState<string>(`${variation.variation.cost || ''}`)
  const [price, setPrice] = useState<string>(
    `${variation.variation.price || defaultPrice || ''}`,
  )
  const [quantity, setQuantity] = useState<string>(
    `${variation.variation.quantity || defaultQuantity || ''}`,
  )
  const [sku, setSku] = useState<string>(variation.variation.sku || '')
  const [images, setImages] = useState<string[]>(
    variation.images.map((img) => img.url),
  )
  const [selectedImages, setSelectedImages] = useState<File[]>([])

  const menuOptions: MenuItemOptionEnum[] = [
    MenuItemOptionEnum.MOVE_UP,
    MenuItemOptionEnum.MOVE_DOWN,
    MenuItemOptionEnum.DELETE,
  ]

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }
  const handleMenuClose = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const handleMenuOptionClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action = (e.target as any).innerText
    const newVariation = variation
    const index = newVariation.variation.index
    if (
      action === MenuItemOptionEnum.MOVE_UP &&
      index !== undefined &&
      index > 0
    ) {
      newVariation.variation.index = index - 1
    } else if (action === MenuItemOptionEnum.MOVE_DOWN && index !== undefined) {
      newVariation.variation.index = index + 1
    } else if (action === MenuItemOptionEnum.DELETE) {
      onDelete(variation.variation.name)
      return
    }

    onChange(newVariation)
  }

  const handleVariationChange = (
    field: 'name' | 'sku' | 'quantity' | 'price' | 'cost',
    value: string,
  ) => {
    switch (field) {
      case 'price':
        setPrice(value)
        break
      case 'cost':
        setCost(value)
        break
      case 'quantity':
        setQuantity(value)
        break
      case 'sku':
        setSku(value)
        break
      case 'name':
        setName(value)
        break
      default:
      // no-op
    }
  }

  const handleSelectedImages = (files: File[]) => {
    setSelectedImages(files)
    const newVariation = variation
    newVariation.selectedImages = files
    onChange(newVariation)
  }

  const handleImages = (images: string[]) => {
    setImages(images)
    const newVariation = variation
    newVariation.images = images.map((img, i) => ({
      url: img,
      index: i,
    }))
    onChange(newVariation)
  }

  const handleBlur = () => {
    handleChange()
  }

  const handleChange = () => {
    const newVariation = variation
    newVariation.variation.price = price ? parseFloat(price) : undefined
    newVariation.variation.quantity = quantity ? parseInt(quantity) : undefined
    newVariation.variation.cost = cost ? parseInt(cost) : undefined
    newVariation.variation.sku = sku
    newVariation.variation.name = name
    newVariation.images = images.map((img, i) => ({
      url: img,
      index: i,
    }))
    newVariation.selectedImages = selectedImages
    onChange(newVariation)
  }

  const handleAttributeChange = (name: string, value: string) => {
    // find attribute
    const attribute = attributes.find(
      (attribute) => attribute.templateAttribute.name === name,
    )
    // update attribute value
    if (!attribute?.templateAttribute) return
    const newVariation = variation
    const index = newVariation.attributes.findIndex(
      (variationAttribute) =>
        variationAttribute.name === attribute.templateAttribute.name,
    )
    // add
    if (index === undefined || index < 0) {
      newVariation.attributes.push({
        name: attribute.templateAttribute.name,
        value,
      })
    } else if (index > -1) {
      // replace
      const newAttribute = newVariation.attributes[index]
      if (newVariation.attributes[index] && newAttribute) {
        newVariation.attributes[index] = { ...newAttribute, value }
      }
    }

    onChange(newVariation)
  }
  return (
    <Accordion>
      <AccordionSummary>
        <ListItemContent sx={{ p: 1.66 }}>
          <Typography level="title-md">
            <strong>{variation.variation.name}</strong>
          </Typography>
        </ListItemContent>
        <ListItem
          endAction={
            <div>
              <Box onClick={handleMenuClick}>
                <MoreVertIcon sx={{ opacity: 0.5, mt: 0.5, pt: 0.3 }} />
              </Box>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: MENU_ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  },
                }}
              >
                {menuOptions.map((option) => (
                  <MenuItem
                    key={option}
                    data-option={option}
                    onClick={(e) => handleMenuOptionClick(e)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          }
        ></ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography level="body-sm">
              Empty fields will default to product values.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImageUpload
              id={`variant-${variation.variation.name}-image-upload`}
              productId={productId}
              variationId={variation.variation.id}
              images={images}
              onChange={handleImages}
              selectedImages={selectedImages}
              onSelect={handleSelectedImages}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="Name"
              required
              value={name}
              onChange={(name) => handleVariationChange('name', name)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="SKU"
              required
              value={sku}
              onChange={(sku) => handleVariationChange('sku', sku)}
              onBlur={handleBlur}
              placeholder={`${product.product.quantity}`}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Quantity"
              value={quantity}
              onChange={(quantity) =>
                handleVariationChange('quantity', quantity)
              }
              onBlur={handleBlur}
              placeholder={`${product.product.quantity}`}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Price"
              value={price}
              onChange={(price) => handleVariationChange('price', price)}
              onBlur={handleBlur}
              placeholder={`${product.product.price}`}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Cost"
              value={cost}
              onChange={(cost) => handleVariationChange('cost', cost)}
              onBlur={handleBlur}
              placeholder={`${product.product.cost}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography level="title-lg">Attributes</Typography>
          </Grid>

          {templateVariationAttributes.map((templateVariationAttribute) => {
            const variationAttribute = variation.attributes.find(
              (variationAttribute) =>
                variationAttribute.name === templateVariationAttribute.name,
            )

            return (
              <Grid item xs={12} key={templateVariationAttribute.name}>
                <ProductAttributeInputField
                  name={templateVariationAttribute.name}
                  attributes={attributes}
                  requiredAttributes={requiredAttributes}
                  onChange={(name, value) => handleAttributeChange(name, value)}
                  value={variationAttribute?.value}
                  displayPlaceholder={true}
                />
              </Grid>
            )
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
