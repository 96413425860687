import React from 'react'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/joy/Typography'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  )
}

export default function BasicTabs({
  panels,
  tabs,
  selected,
  onSelect,
}: {
  panels: JSX.Element[]
  tabs: string[]
  selected?: string
  onSelect?: (tab: string) => void
}): JSX.Element {
  const selectedIndex = tabs.findIndex((e) => e === selected)
  const [value, setValue] = React.useState<number>(
    selectedIndex > -1 ? selectedIndex : 0,
  )

  const handleChange = (event: React.SyntheticEvent, newIndex: number) => {
    setValue(newIndex)
    const newTab = tabs.length > newIndex ? tabs[newIndex] : undefined
    if (!newTab) return
    onSelect?.(newTab)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((label) => (
            <Tab
              label={<Typography level="title-sm">{label}</Typography>}
              key={label}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        {panels.map((panel, i) => (
          <TabPanel value={value} key={i} index={i}>
            {panel}
          </TabPanel>
        ))}
      </Box>
    </Box>
  )
}
