import React from 'react'
import Grid from '@mui/material/Grid'
import { ProductAttributeInputText } from '../../pages/products/product/ProductAttributeInputText'
import { ProductAttributeInputSelect } from '../../pages/products/product/ProductAttributeInputSelect'

export enum SizeWeightChangeEnum {
  WEIGHT_UNIT = 'Weight Unit',
  SIZE_UNIT = 'Size Unit',
  WEIGHT = 'Weight',
  L = 'L',
  W = 'W',
  H = 'H',
}

interface ProductSizeWeightInputProps {
  id?: string
  weight?: string
  width?: string
  length?: string
  height?: string
  weightUnit: string
  sizeUnit: string
  onChange: (name: SizeWeightChangeEnum, value: string) => void
  template?: boolean
  onSelection?: (next?: boolean) => void
}
export default function ProductSizeWeightInput({
  id,
  weight,
  width,
  length,
  height,
  weightUnit,
  sizeUnit,
  onChange,
  template,
  onSelection,
}: ProductSizeWeightInputProps): JSX.Element {
  const handleSelectInput = (i: number, next?: boolean) => {
    if (i === 5) {
      onSelection?.(next)
      return
    }
    // get next element
    if (i !== undefined) {
      const nextIndex = next === false ? i - 1 : i + 1
      const el = document.getElementById(`weight-size-value-input-${nextIndex}`)
      el?.focus()
    }
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <ProductAttributeInputText
              id={id}
              type="number"
              label="Weight"
              value={weight}
              placeholder="0"
              onChange={(v) => onChange(SizeWeightChangeEnum.WEIGHT, v)}
              onSelection={(next) => handleSelectInput(0, next)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputSelect
              label="Weight Unit"
              value={weightUnit}
              options={['kg', 'lb', 'g', 'oz']}
              onChange={(v) => onChange(SizeWeightChangeEnum.WEIGHT_UNIT, v)}
              id={`weight-size-value-input-1`}
              onSelection={(next) => handleSelectInput(1, next)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Length"
              value={length}
              placeholder="0"
              onChange={(v) => onChange(SizeWeightChangeEnum.L, v)}
              id={`weight-size-value-input-2`}
              onSelection={(next) => handleSelectInput(2, next)}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Width"
              value={width}
              placeholder="0"
              onChange={(v) => onChange(SizeWeightChangeEnum.W, v)}
              id={`weight-size-value-input-3`}
              onSelection={(next) => handleSelectInput(3, next)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Height"
              value={height}
              placeholder="0"
              onChange={(v) => onChange(SizeWeightChangeEnum.H, v)}
              id={`weight-size-value-input-4`}
              onSelection={(next) => handleSelectInput(4, next)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputSelect
              label="Size Unit"
              value={sizeUnit}
              options={['feet', 'in', 'm', 'cm', 'mm']}
              onChange={(v) => onChange(SizeWeightChangeEnum.SIZE_UNIT, v)}
              id={`weight-size-value-input-5`}
              onSelection={(next) => handleSelectInput(5, next)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
