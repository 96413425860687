import React, { useState } from 'react'
import NamedSingleSelect from '../../../components/common/NamedSingleSelect'
import Grid from '@mui/material/Grid'
import ListSyncComponent from '../../../components/product/integrations/common/ListSyncComponent'
import SingleSelect from '../../../components/common/SingleSelect'
import FloatLabelInput from '../../../components/common/FloatLabelInput'
import NamedInput from '../../../components/common/NamedInput'
import { isDefined } from '../../../utils/functions'
import {
  IntegrationName,
  ProductIntegrationType,
} from '../../../api/integration'

export default function ProductIntegrationSetting<T extends IntegrationName>({
  channel,
  name,
  productIntegrations: defaultProductIntegrations,
  disableCategorySelect,
  categoryValues,
  CategorySelect,
  onCategorySelect,
  getCategoryName,
  onChange,
}: {
  channel: T
  name: string
  productIntegrations?: ProductIntegrationType<T>[]
  disableCategorySelect?: boolean
  CategorySelect?: (props: {
    values?: string[]
    onSelect?: (categories: string[]) => void
  }) => JSX.Element
  getCategoryName?: (productIntegration?: ProductIntegrationType<T>) => string
  categoryValues?: string[]
  onChange?: (productIntegrations?: ProductIntegrationType<T>[]) => void
  onCategorySelect?: (categories: string[]) => void
}): JSX.Element | null {
  const [productIntegrations] = useState<
    ProductIntegrationType<T>[] | undefined
  >(defaultProductIntegrations)
  const [productIntegration, setProductIntegration] = useState<
    ProductIntegrationType<T> | undefined
  >(
    defaultProductIntegrations?.find((p) => p.selected === true) ||
      defaultProductIntegrations?.find((p) => p.index === 0),
  )
  const options =
    defaultProductIntegrations?.map((p) => p.name).filter(isDefined) || []

  const list = productIntegration?.list ?? false
  const sync = productIntegration?.sync ?? false
  const syncQuantity = productIntegration?.syncQuantity ?? false

  const handleChange = (config: {
    list: boolean
    sync: boolean
    syncQuantity: boolean
  }) => {
    if (!productIntegration) return
    const newProductIntegration = productIntegration
    newProductIntegration.list = config.list
    newProductIntegration.sync = config.sync
    newProductIntegration.syncQuantity = config.syncQuantity

    if (productIntegrations?.length) {
      const updateIndex = productIntegrations.findIndex(
        (p) => p.name === productIntegration.name,
      )
      if (updateIndex > 0) {
        productIntegrations[updateIndex] = newProductIntegration
      }
    }

    onChange?.(productIntegrations)
  }

  const handleSelect = (name: string) => {
    if (!productIntegrations) return
    const selected = productIntegrations.find((p) => p.name === name)
    if (selected) {
      selected.selected = true
      setProductIntegration(selected)
      const selectedProductIntegrations = productIntegrations.map((p) => {
        if (p.name !== name) {
          return { ...p, selected: false }
        } else {
          return { ...p, selected: true }
        }
      })

      console.log(selectedProductIntegrations)
      onChange?.(selectedProductIntegrations)
    }
  }

  if (!productIntegrations?.length) {
    return null
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} md={6}>
        {productIntegrations?.length ? (
          <NamedSingleSelect
            label="Channel Template"
            freeSolo={false}
            defaultOption={false}
            name={name}
            placeholder="Channel Template"
            value={productIntegration?.name || ''}
            lg={[4, 8]}
            md={[5, 7]}
            disabled={!productIntegrations?.length}
            options={options}
            onChange={handleSelect}
          ></NamedSingleSelect>
        ) : (
          <NamedInput
            lg={[4, 8]}
            md={[5, 7]}
            name={name}
            label="Channel Template"
            value={productIntegration?.name || 'Default'}
            disableInput={true}
          />
        )}
      </Grid>
      {!CategorySelect ? (
        <Grid item xs={12} md={3}>
          {disableCategorySelect ? (
            <FloatLabelInput
              label="Category"
              placeholder="Category"
              value={getCategoryName?.(productIntegration) || ''}
              disabled={disableCategorySelect}
            ></FloatLabelInput>
          ) : (
            <SingleSelect
              defaultOption={false}
              floatLabel="Category"
              placeholder="Category"
              value={getCategoryName?.(productIntegration) || ''}
              disabled={true}
              options={[]}
            ></SingleSelect>
          )}
        </Grid>
      ) : (
        <Grid item xs={12} md={3}>
          <CategorySelect onSelect={onCategorySelect} values={categoryValues} />
        </Grid>
      )}
      <Grid item xs={12} md={3}>
        <ListSyncComponent
          size="md"
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}
