import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import SingleSelect from '../../../components/common/SingleSelect'

interface ProductAttributeInputSelectProps {
  id?: string
  label?: string
  value: string | undefined
  options: string[]
  required?: boolean
  disabled?: boolean
  freeSolo?: boolean
  onChange: (typeParam: string) => void
  onSelection?: (next: boolean) => void
}

export function ProductAttributeInputSelect({
  id,
  label,
  value,
  options,
  required,
  disabled,
  freeSolo,
  onChange,
  onSelection,
}: ProductAttributeInputSelectProps): JSX.Element {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      onSelection?.(true)
    } else if (e.shiftKey && e.key === 'Enter') {
      onSelection?.(false)
    }
  }
  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      sx={{ position: 'relative' }}
    >
      {label && (
        <Grid item xs={5} sm={4} md={3} lg={2}>
          <Typography
            sx={{
              border: '1px solid #e8e9eb',
              lineHeight: '34px',
              px: '8px',
              borderRadius: '6px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'left',
              boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
              color: '#32383E',
              backgroundColor: '#FBFCFE',
            }}
          >
            <strong title={label}>{label}</strong>
          </Typography>
        </Grid>
      )}
      <Grid item xs={7} sm={8} md={label ? 9 : 12} lg={label ? 10 : 12}>
        <SingleSelect
          id={id}
          freeSolo={freeSolo}
          value={value || ''}
          required={required}
          onChange={(v) => onChange(v || '')}
          disabled={disabled}
          options={options}
          onKeyDown={(e) => handleKeyPress(e)}
        ></SingleSelect>
      </Grid>
    </Grid>
  )
}
