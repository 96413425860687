import React, { useEffect, useState } from 'react'
import NamedMultiSelect from '../../../common/NamedMultiSelect'
import { BigCommerceCategory } from '../../../../types/BigCommerce.types'
import { getBigCommerceCategories } from '../../../../api/integrations/bigcommerce'
import { err, isDefined } from '../../../../utils/functions'
import AutocompleteListbox from '@mui/joy/AutocompleteListbox'
import Box from '@mui/joy/Box'
import Checkbox from '@mui/joy/Checkbox'
import CircularProgress from '@mui/joy/CircularProgress'
import Typography from '@mui/joy/Typography'
import { TreeView } from '@mui/x-tree-view/TreeView'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { TreeItem } from '@mui/x-tree-view/TreeItem'

export default function BigCommerceCategorySelect({
  value,
  onSelect,
  onBlur,
  categories: defaultCategories,
  label,
}: {
  value: number[]
  categories?: BigCommerceCategory[]
  label?: string
  onSelect?: (values: number[]) => void
  onBlur?: (values: number[]) => void
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const [categories, setCategories] = useState<
    BigCommerceCategory[] | undefined
  >(defaultCategories)

  const getAllCategories = (categories: BigCommerceCategory[] | undefined) => {
    if (!categories) return []
    const newAllCategories: BigCommerceCategory[] = []
    const addAllCategories = (categories: BigCommerceCategory[]) => {
      categories.forEach((c) => {
        newAllCategories.push(c)
        if (c.children?.length) {
          addAllCategories(c.children)
        }
      })
    }
    addAllCategories(categories)
    return newAllCategories
  }

  // const allCats =
  const [allCategories] = useState<BigCommerceCategory[]>(
    getAllCategories(defaultCategories),
  )
  const [categoryIds] = useState<number[]>(
    allCategories.map((c) => c.category_id),
  )

  const [localSelectedCategories, setLocalSelectedCategories] = useState<
    BigCommerceCategory[]
  >(allCategories?.filter((c) => value?.includes(c.category_id)) || [])

  const [localSelectedCategoryIds, setLocalSelectedCategoryIds] = useState<
    number[]
  >(localSelectedCategories.map((c) => c.category_id))

  const handleSelectIds = (values?: number[]) => {
    const newSelectedCategories = allCategories.filter((c) =>
      values?.includes(c.category_id),
    )
    setLocalSelectedCategories(newSelectedCategories)
    setLocalSelectedCategoryIds(newSelectedCategories.map((c) => c.category_id))

    // Call onBlur for clear events
    if (!values?.length) {
      onBlur?.([])
    }
    onSelect?.(values || [])
  }

  useEffect(() => {
    if (categories) return
    setLoading(true)
    getBigCommerceCategories()
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setCategories(res.data)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!categories || loading) {
    return (
      <Box>
        <CircularProgress size="sm" />
      </Box>
    )
  }

  return (
    <NamedMultiSelect
      limitTags={1}
      name={label}
      placeholder={'Category'}
      disableInput={true}
      value={localSelectedCategories.map((c) => c.name)}
      onBlur={() => {
        onBlur?.(localSelectedCategoryIds)
      }}
      freeSolo={false}
      onChange={(values) => {
        // Handle clear events
        if (!values.length) handleSelectIds([])
        const valueIds = values
          .map((v) => allCategories?.find((c) => c.name === v)?.category_id)
          .filter(isDefined)
        handleSelectIds(valueIds)
      }}
      options={[]}
      slots={{
        listbox: React.forwardRef(function DisplayListBox(
          props: {
            open: boolean
          },
          ref: React.ForwardedRef<HTMLUListElement | null>,
        ) {
          return (
            <AutocompleteListbox
              ref={ref}
              {...props}
              sx={{
                position: 'absolute',
                display: props.open ? 'inherit' : 'none',
              }}
            >
              <DisplayCategories
                categories={categories || []}
                categoryIds={categoryIds || []}
                selectedCategoryIds={localSelectedCategoryIds}
                onChange={(category) => {
                  if (!category) return
                  if (
                    !localSelectedCategoryIds?.includes(category.category_id)
                  ) {
                    handleSelectIds(
                      localSelectedCategoryIds?.concat([category.category_id]),
                    )
                  } else {
                    const newSelectedCategoryIds =
                      localSelectedCategoryIds?.slice()
                    const index = newSelectedCategoryIds.findIndex(
                      (c) => c === category.category_id,
                    )
                    if (index < 0) return
                    newSelectedCategoryIds.splice(index, 1)
                    handleSelectIds(newSelectedCategoryIds)
                  }
                }}
              />
            </AutocompleteListbox>
          )
        }),
      }}
    />
  )
}

function DisplayCategories({
  categories,
  categoryIds,
  selectedCategoryIds,
  onChange,
}: {
  categories: BigCommerceCategory[]
  categoryIds: number[]
  selectedCategoryIds: number[]
  onChange: (category: BigCommerceCategory | undefined) => void
}): JSX.Element {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      sx={{
        width: '100%',
      }}
      defaultExpanded={categoryIds.map((c) => `${c}`)} // selected or has child selected
    >
      {categories?.map((node) => (
        <TreeNode
          node={node}
          key={node.category_id}
          onChange={onChange}
          selectedCategoryIds={selectedCategoryIds}
        />
      ))}
    </TreeView>
  )
}

function TreeNode({
  node,
  selectedCategoryIds,
  onChange,
}: {
  node: BigCommerceCategory
  selectedCategoryIds: number[]
  onChange: (category: BigCommerceCategory) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.category_id}
      nodeId={`${node.category_id}`}
      sx={{ borderRadius: '6px' }}
      label={
        <Typography
          p={0.5}
          sx={{ textAlign: 'left', borderRadius: '6px' }}
          startDecorator={
            <Checkbox
              onClick={(e) => {
                e.stopPropagation()
                onChange(node)
              }}
              sx={{ mr: 0.5 }}
              checked={selectedCategoryIds.includes(node.category_id)}
            />
          }
        >
          {node.name}
        </Typography>
      }
      onClick={() => !node.children?.length && onChange(node)}
    >
      {node?.children?.map((node) => (
        <TreeNode
          node={node}
          key={node.category_id}
          onChange={onChange}
          selectedCategoryIds={selectedCategoryIds}
        />
      ))}
    </TreeItem>
  )
}
