import React from 'react'
import Box from '@mui/joy/Box'

export default function InputContainer({
  children,
}: {
  children?: React.ReactNode
}): JSX.Element {
  return (
    <Box
      p={2}
      sx={{
        border: '1px solid #e8e9eb',
        borderRadius: '6px',
        boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
        color: '#32383E',
        backgroundColor: '#FBFCFE',
      }}
    >
      {children}
    </Box>
  )
}
