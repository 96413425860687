import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { Link, useHistory } from 'react-router-dom'
import { sendResetPasswordEmail } from '../../api/account'
import { err } from '../../utils/functions'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import LabelInput from '../../components/common/LabelInput'
import Component from '../../components/common/Component'

export default function ForgotPassword(): JSX.Element {
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleReturn = () => {
    history.push('/login')
  }
  const isValidEmail = (email: string): boolean => {
    const validEmail = email
      ? email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null
      : true
    if (validEmail) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Email is not valid.',
      })
    }
    return validEmail
  }

  const handleSendResetLink = () => {
    if (!email) return

    const validEmail = isValidEmail(email)
    if (!validEmail) return

    sendResetPasswordEmail(email)
      .then((res) => {
        handleAlert(setAlert, res, 'Sent Reset Password Email')
      })
      .catch((e) => {
        err(e)
      })
  }

  return (
    <div
      style={{
        height: '100vh',
        background:
          'linear-gradient(180deg, rgba(2,173,151,1) 0%, rgba(2,173,151,1) 51%, #00796b 100%)',
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            marginTop: '6em',
          }}
        >
          <Component p={3}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <Typography level="h1" component="h1" fontSize={48}>
                Sellmotto
              </Typography>
            </Link>
            <Typography level="h3" color="neutral">
              Forgot Password
            </Typography>

            <form
              onSubmit={async (e) => {
                e.preventDefault()
                handleSendResetLink()
              }}
            >
              <Box style={{ paddingTop: '1em', paddingBottom: '2em' }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <LabelInput
                      label="Email"
                      fullWidth
                      required
                      value={email}
                      onChange={setEmail}
                      autoComplete="email"
                      autoCapitalize={false}
                      autoCorrect={false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography level="body-xs">
                      Please contact us at info@sellmotto.com to resolve any
                      other account issues.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button
                    variant="plain"
                    color="neutral"
                    onClick={handleReturn}
                    sx={{ cursor: 'pointer' }}
                    size="lg"
                  >
                    Return
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="solid"
                    color="primary"
                    type="submit"
                    sx={{ cursor: 'pointer' }}
                    size="lg"
                  >
                    Send Reset Link
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Component>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
