import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import Alert, { AlertInput } from '../common/Alert'
import ListItem from '@mui/joy/ListItem'
import List from '@mui/joy/List'
import ListItemContent from '@mui/joy/ListItemContent'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import { ClientUser, getClientUsers } from '../../api/admin'
import { err } from '../../utils/functions'
import Typography from '@mui/joy/Typography'
import { ROLE_MAP } from '../../types'
import Avatar from '@mui/joy/Avatar'

interface AddUserRoleProps {
  clientId: number
}

export default function ClientUsers({
  clientId,
}: AddUserRoleProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [clientUsers, setClientUsers] = useState<ClientUser[]>([])
  useEffect(() => {
    //
    getClientUsers()
      .then((res) => {
        if (res.success && res.data) {
          setClientUsers(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])
  return (
    <>
      <Component title="Organization Users">
        <Grid container spacing={2} justifyContent="center" direction="column">
          <Grid item xs={12}>
            <List sx={{ '--ListItemDecorator-size': '56px' }}>
              {clientUsers.map((user) => {
                return (
                  <ListItem key={user.id}>
                    <ListItemDecorator>
                      <Avatar src={user.image || undefined} />
                    </ListItemDecorator>
                    <ListItemContent>
                      <Typography
                        level="title-md"
                        endDecorator={
                          <Typography level="body-sm">
                            {ROLE_MAP[user.roleId]}
                            {user.status === 'pending' ? ' (Pending)' : ''}
                          </Typography>
                        }
                      >
                        {user.email}
                      </Typography>
                    </ListItemContent>
                  </ListItem>
                )
              })}
            </List>
          </Grid>
        </Grid>
      </Component>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </>
  )
}
