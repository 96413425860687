import React, { useState } from 'react'
import { split } from '../../utils/functions'
import Autocomplete from '@mui/joy/Autocomplete'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Typography from '@mui/joy/Typography'
import FormHelperText from '@mui/joy/FormHelperText'
import { InnerInput } from './FloatLabelInput'

interface MultipleSelectProps {
  label?: string
  floatLabel?: string
  placeholder?: string
  value: string[]
  size?: 'sm' | 'md' | 'lg'
  options?: string[]
  freeSolo?: boolean
  disabled?: boolean
  noSpacedInputs?: boolean
  errorText?: boolean
  helperText?: boolean
  endDecorator?: React.ReactNode
  autoHighlight?: boolean
  limitTags?: number
  onChange?: (values: string[]) => void
}
export default function MultipleSelect({
  label,
  floatLabel,
  placeholder,
  value,
  size,
  options,
  freeSolo,
  disabled,
  noSpacedInputs,
  errorText,
  helperText,
  endDecorator,
  autoHighlight,
  limitTags,
  onChange,
}: MultipleSelectProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')
  const handleInputValueChange = (inputValue: string) => {
    setInputValue(inputValue)

    const commaRegex = new RegExp(/\s?.*?,+/gim)
    const spaceRegex = new RegExp(/\s?.*?\s+/gim)

    let newValues: string[] = []
    if (commaRegex.exec(inputValue)) {
      newValues = split(inputValue, ',').filter((v) => v)
      // remove unmatched
      if (!freeSolo) {
        newValues = newValues.filter((v) => options?.includes(v))
      }
      onChange?.(value.concat(newValues))
      setInputValue('')
    } else if (noSpacedInputs && spaceRegex.exec(inputValue)) {
      newValues = split(inputValue, ' ').filter((v) => v)
      // remove unmatched
      if (!freeSolo) {
        newValues = newValues.filter((v) => options?.includes(v))
      }
      onChange?.(value.concat(newValues))
      setInputValue('')
    }
  }

  const uniqueValues = value?.reduce((acc, item) => {
    if (!acc.some((e) => e === item)) {
      acc.push(item)
    }
    return acc
  }, [] as string[])

  return (
    <FormControl>
      {label ? (
        <FormLabel>
          <Typography level="title-sm">
            <strong>{label}</strong>
          </Typography>
        </FormLabel>
      ) : null}
      <Autocomplete
        value={uniqueValues}
        options={options || []}
        freeSolo={freeSolo}
        multiple
        size={size}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          handleInputValueChange(value)
        }}
        slots={{
          ...(floatLabel
            ? {
                input: InnerInput,
              }
            : {}),
          // clearIndicator: disableClearIndicator ? () => <></> : undefined,
          popupIndicator: () => <></>,
        }}
        slotProps={{
          ...(floatLabel
            ? {
                root: {
                  sx: {
                    pl: '7.5px',
                  },
                },
              }
            : {}),
          input: {
            label: floatLabel,
            placeholder: placeholder,
          },
        }}
        limitTags={limitTags}
        disabled={disabled}
        autoHighlight={autoHighlight}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={(e, values) => {
          onChange?.(values)
        }}
        placeholder={placeholder || (!value.length ? label : '')}
        defaultValue={value}
        endDecorator={endDecorator}
      />
      {errorText || helperText ? (
        <FormHelperText>{errorText || helperText}</FormHelperText>
      ) : null}
    </FormControl>
  )
}
