import * as React from 'react'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { styled } from '@mui/joy/styles'
import Textarea from '@mui/joy/Textarea'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'

const StyledTextarea = styled(TextareaAutosize)({
  resize: 'none',
  border: 'none', // remove the native textarea border
  minWidth: 0, // remove the native textarea width
  outline: 0, // remove the native textarea outline
  padding: 0, // remove the native textarea padding
  flex: 'auto',
  alignSelf: 'stretch',
  color: 'inherit',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  // specific to TextareaAutosize, cannot use '&:focus ~ label'
  '&:focus + textarea + label, &:not(:placeholder-shown) + textarea + label': {
    top: '-0.5rem',
    fontSize: '0.75rem',
    background:
      'linear-gradient(#FBFCFE, #FBFCFE) 0 100% / 100% 0.45rem no-repeat',
    borderRadius: '3px',
    padding: 1,
    paddingLeft: 4,
    paddingRight: 4,
    zIndex: 1,
  },
  '&:focus + textarea + label': {
    color: 'var(--Textarea-focusedHighlight)',
  },
})

const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Textarea-minHeight) - 1em) / 2)',
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}))

const InnerTextarea = React.forwardRef<
  HTMLTextAreaElement,
  JSX.IntrinsicElements['textarea'] & {
    label?: string | JSX.Element
    minRows?: number
  }
>(function InnerTextarea(props, ref) {
  const id = React.useId()
  return (
    <React.Fragment>
      <StyledTextarea {...props} minRows={props.minRows} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>{props.label}</StyledLabel>
    </React.Fragment>
  )
})

export default function FloatLabelTextarea({
  value,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onKeyDown,
  label,
  placeholder,
  size,
  minRows,
  maxRows,
  required,
  disabled,
  errorText,
  helperText,
  autoComplete,
}: {
  value?: string | number
  onChange?: (value: string) => void
  onBlur?: (e?: React.FocusEvent<HTMLTextAreaElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  label?: string
  placeholder?: string
  minRows?: number
  maxRows?: number
  size?: 'sm' | 'md' | 'lg'
  required?: boolean
  disabled?: boolean
  errorText?: string
  helperText?: string
  autoComplete?: boolean
}): JSX.Element {
  return (
    <FormControl>
      <Textarea
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onClick={onClick}
        minRows={minRows}
        maxRows={maxRows}
        autoComplete={autoComplete ? undefined : 'new-password'}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        size={size}
        error={!!errorText}
        slots={{ textarea: InnerTextarea }}
        slotProps={{
          textarea: { label, minRows },
        }}
        sx={{ borderRadius: '6px' }}
      />
      <FormHelperText>{errorText || helperText}</FormHelperText>
    </FormControl>
  )
}
