import React, { useEffect, useState } from 'react'
import { err } from '../../../../utils/functions'
import {
  GetInventoryItemOffer,
  GetInventoryItemOffers,
  ebayBulkImport,
  getEbayInventoryItemOffers,
  getEbayOffer,
} from '../../../../api/integrations/ebay'
import PaginatedTable from '../../../common/PaginatedTable'
import Button from '@mui/joy/Button'
import ExpandMore from '../../../common/ExpandMore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { EbayOffer } from '../../../../types/Ebay.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import SingleSelect from '../../../common/SingleSelect'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Checkbox from '@mui/joy/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'

interface EbayImportOffersProps {
  templates?: string[]
}

export default function EbayImportOffers({
  templates,
}: EbayImportOffersProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [ebayInventoryItemOffers, setEbayInventoryItemOffers] = useState<
    GetInventoryItemOffers | undefined
  >()

  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>()

  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  const [updateExistingProducts, setUpdateExistingProducts] =
    useState<boolean>(false)

  const [
    selectedEbayInventoryItemOfferSkus,
    setSelectedEbayInventoryItemOfferSkus,
  ] = useState<string[]>([])

  const handleImport = () => {
    const skus = selectedEbayInventoryItemOfferSkus
    if (!skus.length) return
    ebayBulkImport({ skus, template: selectedTemplate, updateExistingProducts })
      .then((res) => {
        handleAlert(setAlert, res, 'Import process started.')
      })
      .catch((e) => err(e))
  }

  const handleBulkImport = () => {
    ebayBulkImport({ template: selectedTemplate, updateExistingProducts })
      .then((res) => {
        handleAlert(setAlert, res, 'Bulk import process started.')
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    setLoading(true)
    const offset = page * pageSize
    const limit = pageSize
    getEbayInventoryItemOffers({
      offset,
      limit,
    })
      .then((res) => {
        if (res.success && res.data) {
          setEbayInventoryItemOffers(res.data)
          setLoading(false)
        }
      })
      .catch((e) => err(e))
  }, [page, pageSize])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }
  const handlePageSizeChange = (newPageSize: number) => {
    setPage(0)
    setPageSize(newPageSize)
  }

  const handleRowClick = (row: GetInventoryItemOffer) => {
    const sku = row.inventoryItem.sku
    const newSelectedEbayInventoryItemOffers =
      selectedEbayInventoryItemOfferSkus.slice()

    const isSelected = selectedEbayInventoryItemOfferSkus.find(
      (selectedSku) => selectedSku === sku,
    )

    if (isSelected) {
      const newSelectedEbayInventoryItemOffers =
        selectedEbayInventoryItemOfferSkus.filter(
          (selectedSku) => selectedSku !== sku,
        )

      setSelectedEbayInventoryItemOfferSkus(newSelectedEbayInventoryItemOffers)
    } else {
      newSelectedEbayInventoryItemOffers.push(sku)
      setSelectedEbayInventoryItemOfferSkus(newSelectedEbayInventoryItemOffers)
    }

    // Set all selected
  }

  const handleSelectAll = () => {
    // Add or remove all

    const allSkus = ebayInventoryItemOffers?.inventoryItemOffers.map(
      (inventoryItemOffer) => inventoryItemOffer.inventoryItem.sku,
    )
    if (!allSkus) return

    const notSelected = allSkus?.filter(
      (sku) => !selectedEbayInventoryItemOfferSkus.includes(sku),
    )

    if (notSelected?.length) {
      // select all
      const newSelectedEbayInventoryItemOfferSkus =
        selectedEbayInventoryItemOfferSkus.concat(notSelected)
      setSelectedEbayInventoryItemOfferSkus(
        newSelectedEbayInventoryItemOfferSkus,
      )
    } else {
      // remove all
      const newSelectedEbayInventoryItemOfferSkus =
        selectedEbayInventoryItemOfferSkus.filter(
          (selectedSku) => !allSkus.includes(selectedSku),
        )
      setSelectedEbayInventoryItemOfferSkus(
        newSelectedEbayInventoryItemOfferSkus,
      )
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography level="title-lg">Ebay Item Import</Typography>
        <Typography level="body-xs">
          All Inventory Items and Variants are displayed. Only items with active
          offers will be imported. Select the main item of a variant to import.
        </Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Box p={3}>
          <PaginatedTable
            count={ebayInventoryItemOffers?.count || 0}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={[5, 10, 25]}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            disabled={loading}
            head={[
              <Checkbox
                key={'ebay-checkbox'}
                size="sm"
                onClick={handleSelectAll}
                sx={{ top: '3px' }}
                checked={
                  ebayInventoryItemOffers?.count
                    ? ebayInventoryItemOffers.count ===
                      selectedEbayInventoryItemOfferSkus.length
                    : false
                }
              />,
              'Title',
              'SKU',
              'Offer',
              // 'Status',
              // 'Quantity',
              // 'Price',
              // 'Listing',
            ]}
            data={ebayInventoryItemOffers?.inventoryItemOffers || []}
            onRowClick={handleRowClick}
            row={(row, i) => {
              const offer = row.offer
              const sku = row.inventoryItem.sku
              const title = row.inventoryItem.product.title

              const isSelected = selectedEbayInventoryItemOfferSkus.find(
                (selectedSku) => selectedSku === sku,
              )

              const rowArray = [
                <Checkbox
                  sx={{ p: 0 }}
                  key={sku}
                  checked={!!isSelected}
                  size="sm"
                ></Checkbox>,
                title,
                sku,
                // offer ? (
                // <Typography
                //   key={sku}
                //   variant="body2"
                //   onClick={() => (expanded = true)}
                // >
                //   Offer Details
                //   <ExpandMore key={sku} expand={expanded}>
                //     <ExpandMoreIcon fontSize="small" />
                //   </ExpandMore>
                // </Typography>
                // ) : (
                //   '-'
                // ),
                // offer?.status || '-',
                // quantity ?? '-',
                // price ?? '-',
                // listingId ? (
                //   <a
                //     key={listingId}
                //     href={`https://www.ebay.com/itm/${listingId}`}
                //     target="__blank"
                //   >
                //     {listingId}
                //   </a>
                // ) : (
                //   '-'
                // ),
              ]

              if (!offer) {
                return (
                  <ExpandableTableRow
                    key={row.inventoryItem.sku}
                    row={row}
                    data={rowArray}
                    onClick={() => handleRowClick(row)}
                  />
                )
              }

              return rowArray
            }}
          ></PaginatedTable>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography level="title-lg">Import Template</Typography>
            <Typography level="body-xs">
              Items will be merged into selected template. If none is selected,
              each item Category will be mapped to a new Template Example:
              &quot;Ebay Category&quot;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SingleSelect
              label="Select Template"
              freeSolo={true}
              value={selectedTemplate || ''}
              onChange={(template) => setSelectedTemplate(template)}
              options={templates || []}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={updateExistingProducts}
                        onClick={() =>
                          setUpdateExistingProducts(!updateExistingProducts)
                        }
                      />
                    }
                    label={
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography level="body-md">
                            <strong>Update Existing Products</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography level="body-xs">
                            Existing data may be overwritten
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {selectedEbayInventoryItemOfferSkus.length} Items will be imported
              into{' '}
              {selectedTemplate ? (
                <strong>{selectedTemplate}</strong>
              ) : (
                'new Template(s)'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button variant="solid" onClick={handleBulkImport}>
              Bulk Import All Inventory
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="solid"
              onClick={handleImport}
              disabled={!selectedEbayInventoryItemOfferSkus.length}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}

function ExpandableTableRow({
  data,
  row,
  onClick,
}: {
  row: GetInventoryItemOffer
  data: (string | JSX.Element)[]
  onClick: () => void
}): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [offer, setOffer] = useState<EbayOffer | undefined>(undefined)

  const handleGetOffer = () => {
    getEbayOffer(row.inventoryItem.sku)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setOffer(res.data)
        }
      })
      .catch((e) => err(e))
  }

  const colSpan = data.length + 1

  return (
    <>
      <TableRow onClick={onClick}>
        {data.map((value, i) => (
          <TableCell key={`${row.inventoryItem.sku}-${value}-${i}`}>
            {value}
          </TableCell>
        ))}
        <TableCell>
          <Typography
            level="body-md"
            onClick={(e) => {
              e.stopPropagation()
              setLoading(true)
              handleGetOffer()
              setExpanded(!expanded)
            }}
            sx={{ cursor: 'pointer' }}
          >
            Offer Details
            <ExpandMore expand={expanded}>
              <ExpandMoreIcon fontSize="small" />
            </ExpandMore>
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={colSpan}>
          <Collapse in={expanded} unmountOnExit timeout="auto">
            <Box>
              <Table size="small">
                <TableBody>
                  {offer && !loading ? (
                    <TableRow>
                      <TableCell>
                        <strong>Status: </strong>
                        {offer?.status || '-'}
                      </TableCell>
                      <TableCell>
                        <strong>Price: </strong>
                        {offer?.pricingSummary?.price.value || 0}
                      </TableCell>
                      <TableCell>
                        <strong>Quantity: </strong>
                        {row.inventoryItem.availability
                          ?.shipToLocationAvailability.quantity || 0}
                      </TableCell>
                      <TableCell>
                        {offer?.listing?.listingId ? (
                          <a
                            href={`https://www.ebay.com/itm/${offer?.listing?.listingId}`}
                            target="__blank"
                          >
                            {offer?.listing?.listingId}
                          </a>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {loading ? 'Loading...' : <strong>No Offer</strong>}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
