import React, { useEffect, useState } from 'react'
import ChannelIntegrationsComponent from '../common/ChannelIntegrationsComponent'
import {
  getSquareCategories,
  getSquareLocations,
} from '../../../../api/integrations/square'
import {
  getChannelIntegration,
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'
import {
  SquareCatalogObject,
  SquareLocation,
  SquareProductIntegration,
  SquareTemplateIntegration,
} from '../../../../types/Square.types'
import { err } from '../../../../utils/functions'
import { Box, CircularProgress } from '@mui/joy'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import LoadingBox from '../../../common/LoadingBox'
import SquareIntegrationComponent from './SquareIntegrationComponent'
import MissingIntegration from '../MissingIntegration'
import UnauthenticatedIntegration from '../UnauthenticatedIntegration'

export type SquareCatalogObjectChildren = SquareCatalogObject & {
  children?: SquareCatalogObject[]
}

export default function SquareIntegrationsComponent({
  template,
  product,
  onUpdate,
}: {
  template: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<IntegrationName.SQUARE> | undefined
  >(undefined)

  const [locations, setLocations] = useState<SquareLocation[] | undefined>()
  const [categories, setCategories] = useState<
    SquareCatalogObjectChildren[] | undefined
  >()

  useEffect(() => {
    getChannelIntegration(
      IntegrationName.SQUARE,
      template.template.id,
      product?.product.id,
    )
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setIntegration(res.data)
        }
      })
      .catch((e) => {
        setLoading(false)
        err(e)
      })
    getSquareLocations()
      .then((res) => {
        if (res.data && res.success && Array.isArray(res.data)) {
          setLocations(res.data)
        }
      })
      .catch((e) => err(e))

    getSquareCategories()
      .then((res) => {
        if (res.data && res.success) {
          setCategories(res.data)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (!integration) {
    return (
      <Box p={4}>
        <MissingIntegration />
      </Box>
    )
  }

  if (integration && !integration.authenticated) {
    return (
      <Box p={4}>
        <UnauthenticatedIntegration channelName={IntegrationName.SQUARE} />
      </Box>
    )
  }

  return (
    <>
      <ChannelIntegrationsComponent
        channel={IntegrationName.SQUARE}
        template={template}
        product={product}
        integration={integration}
        onUpdate={onUpdate}
        // onTemplateDelete={async (id) => {
        //   return await deleteSquareTemplateIntegration(id)
        // }}
        Component={({
          templateIntegration,
          productIntegration,
          template,
          product,
          count,
          onTemplateChange,
          onProductChange,
          onUpdate,
        }) =>
          !locations || !categories ? (
            <LoadingBox />
          ) : (
            <SquareIntegrationComponent
              integration={integration}
              templateIntegration={
                templateIntegration as SquareTemplateIntegration
              }
              productIntegration={
                productIntegration as SquareProductIntegration
              }
              template={template}
              product={product}
              locations={locations}
              categories={categories}
              onTemplateChange={(name, templateIntegration) =>
                onTemplateChange?.(
                  name,
                  templateIntegration as SquareTemplateIntegration,
                )
              }
              onProductChange={(name, productIntegration) =>
                onProductChange?.(
                  name,
                  productIntegration as SquareProductIntegration,
                )
              }
              onUpdate={onUpdate}
            />
          )
        }
      />
    </>
  )
}
