import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/joy/Typography'
import LabelInput from '../../../components/common/LabelInput'

interface ProductAttributeInputTextProps {
  id?: string
  label?: string
  hideLabel?: boolean
  tag?: string
  placeholder?: string
  value?: string | undefined
  required?: boolean
  onChange?: (typeParam: string) => void
  onBlur?: () => void
  onFocus?: () => void
  disabled?: boolean
  multiline?: boolean
  type?: 'text' | 'number'
  richText?: boolean
  format?: 'USD'
  endDecorator?: React.ReactNode
  onSelection?: (next: boolean) => void
}

export function ProductAttributeInputText({
  id,
  label,
  hideLabel,
  tag,
  placeholder,
  value,
  required,
  onChange,
  onBlur,
  onFocus,
  disabled,
  multiline,
  type,
  richText,
  endDecorator,
  format,
  onSelection,
}: ProductAttributeInputTextProps): JSX.Element {
  const [errorText, setErrorText] = useState('')

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!e.shiftKey && e.key === 'Enter') {
      onSelection?.(true)
    } else if (e.shiftKey && e.key === 'Enter') {
      onSelection?.(false)
    }
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      {!hideLabel && (
        <Grid item xs={5} sm={4} md={3} lg={2} sx={{ position: 'relative' }}>
          <Typography
            sx={{
              border: '1px solid #e8e9eb',
              lineHeight: '34px',
              px: '8px',
              borderRadius: '6px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'left',
              boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
              color: '#32383E',
              backgroundColor: '#FBFCFE',
            }}
          >
            <strong title={label}>{label}</strong>
          </Typography>
        </Grid>
      )}
      <Grid item xs={7} sm={8} md={hideLabel ? 12 : 9} lg={hideLabel ? 12 : 10}>
        {multiline ? (
          <TextField
            id={id}
            onBlur={() => {
              onBlur?.()
              setErrorText(!value && required ? label + ' is required.' : '')
            }}
            onFocus={() => onFocus?.()}
            error={!!errorText}
            helperText={errorText}
            type={type}
            disabled={disabled}
            label={tag}
            size="small"
            multiline
            minRows={15}
            maxRows={30}
            fullWidth
            value={value !== undefined ? value : ''}
            autoComplete={'off'}
            required={required}
            onChange={(e) => {
              onChange?.(e.target.value)
              setErrorText(
                !e.target.value && required ? label + ' is required.' : '',
              )
            }}
            placeholder={placeholder}
            sx={{ resize: 'vertical' }}
          />
        ) : (
          <LabelInput
            id={id}
            onBlur={() => {
              onBlur?.()
              setErrorText(!value && required ? label + ' is required.' : '')
            }}
            onFocus={() => onFocus?.()}
            format={format}
            helperText={errorText}
            type={type}
            label={tag}
            size="md"
            fullWidth
            value={value !== undefined ? value : ''}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            onChange={(v) => {
              onChange?.(v)
              setErrorText(!v && required ? label + ' is required.' : '')
            }}
            onKeyDown={(e) => handleKeyPress(e)}
            endDecorator={endDecorator}
          />
        )}
      </Grid>
    </Grid>
  )
}
