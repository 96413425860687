import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import EbayAuthorization from '../../components/product/integrations/ebay/EbayAuthorization'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import EbayPolicies from '../../components/product/integrations/ebay/EbayPolicies'
import Alert, { AlertInput } from '../../components/common/Alert'
import DisconnectIntegration from '../../components/integrations/DisconnectIntegration'
import InputContainer from '../../components/common/InputContainer'

export default function EbayIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.EBAY>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  return (
    <Grid container spacing={8} key={integration.integration?.id}>
      <Grid item xs={12}>
        <EbayPolicies integration={integration.integration} />
      </Grid>

      <Grid item xs={12}>
        <InputContainer>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography level="title-lg">Authentication</Typography>

              {integration.authenticated && integration.oAuthUrl ? (
                <>
                  <Typography level="body-xs">
                    Third-party-app access.{' '}
                    <a
                      href="https://accounts.ebay.com/acctsec/security-center/third-party-app-access"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Edit on Ebay
                    </a>
                  </Typography>
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <EbayAuthorization
                integration={integration.integration}
                authorized={integration.authenticated}
                oAuthUrl={integration.oAuthUrl}
              />
            </Grid>
          </Grid>
        </InputContainer>
      </Grid>
      {integration?.integration && integration?.authenticated ? (
        <Grid item xs={12}>
          <DisconnectIntegration
            integration={integration.integration}
            channel={IntegrationName.EBAY}
            setAlert={setAlert}
          />
        </Grid>
      ) : undefined}
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
